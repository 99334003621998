import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ReactComponent as AppStoreIcon } from '@assets/icons/app-store.svg';
import { ReactComponent as GoogleIcon } from '@assets/icons/google-play.svg';
import TextField from '@mui/material/TextField';
import { LoginResult } from '@store/generated-models';
import { AuthContext } from '@coreProviders/AuthProvider';
import { useSnackbar } from 'notistack';
import { Box, Button, Grid } from '@mui/material';
import { useMutation } from '@apollo/client';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { ENABLE_2F } from '@store/mutations/users';

const GA_APP_STORE_URL = 'https://apps.apple.com/us/app/google-authenticator/id388497605';
const GA_GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en';

export interface ComponentProps {
  qrCode: string;
  qrString: string;
  onCancel: () => void;
}

export const Step2faEnable: React.FC<ComponentProps> = ({ qrCode, qrString, onCancel }) => {
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  const qrDimension = isMobile ? 162 : 212;
  const qrFontSize = isMobile ? 6 : 10;
  const QrText = styled.div`  
    width: ${qrDimension}px;  
    height: ${qrDimension}px;
    display: flex;
    flex-direction: column;  
  `;

  const QrTextLine = styled.div`
    font-family: monospace, Courier, Monaco;    
    letter-spacing: 0;
    white-space: pre;
    font-size: ${qrFontSize}px;
    line-height: ${qrFontSize}px;
  `;


  const authContext = useContext(AuthContext);
  const [enable2fa] = useMutation<{ enable2fa: LoginResult }>(ENABLE_2F);
  const [authCode, setAuthCode] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const setEnable2fa = async () => {
    let errMessage: string = '';
    try {
      const res = await enable2fa({
        variables: {
          code: authCode,
          password: 'ignored'
        }
      });

      if (res.data.enable2fa.user.is2faEnabled) {
        authContext.updateUser({ is2faEnabled: true });
        enqueueSnackbar('2FA has been enabled', { variant: 'success' });
        return;
      } else {
        errMessage = 'Wrong Code, please try again';
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === 'auth.access_denied') errMessage = 'Access denied';
        if (!errMessage && error.graphQLErrors[0].message) errMessage = error.graphQLErrors[0].message;
      } catch {
      }
    }

    if (!errMessage) errMessage = 'Unknown error';
    enqueueSnackbar(errMessage, { variant: 'error' });
  };

  const Circle: FC = ({ children }) => {
    return (
      <Box
        sx={{
          minWidth: '24px',
          height: '24px',
          borderRadius: '50%',
          backgroundColor: '#4A4DF0',
          color: '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '12px',
          marginRight: '16px'
        }}>
        {children}
      </Box>
    );
  };

  return (
    <>
      <Grid container spacing={{ md: 4, xs: 2 }}>
        <Grid item sm={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box sx={{ display: 'flex' }}>
                <Circle>1</Circle>
                <Typography component="span" sx={{ fontSize: '16px', fontWeight: 500, color: '#000000' }}>Download an
                  authenticator app</Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Typography component="span" sx={{ fontSize: '14px', color: '#000000' }}>Download and install the Google
                Authenticator app on your phone.</Typography>
            </Grid>
            <Grid item md={12}>
              <Link href={GA_GOOGLE_PLAY_URL} target="_blank" rel="noopener noreferrer" color="inherit">
                <GoogleIcon style={{ height: '52px' }}/>
              </Link>
            </Grid>
            <Grid item md={12}>
              <Link href={GA_APP_STORE_URL} target="_blank" rel="noopener noreferrer" color="inherit">
                <AppStoreIcon style={{ height: '52px' }}/>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box sx={{ display: 'flex' }}>
                <Circle>2</Circle>
                <Typography component="span" sx={{ fontSize: '16px', fontWeight: 500, color: '#000000' }}>Scan the QR
                  code</Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Typography component="span" sx={{ fontSize: '14px', color: '#000000' }}>Scan the QR code in the Google
                Authenticator app.</Typography>
            </Grid>
            <Grid item md={12}>
              <Box sx={{ marginBottom: '32px' }}>
                <QrText>
                  {qrString.split('\n').map((el, i) => <QrTextLine key={i}>{el}</QrTextLine>)}
                </QrText>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Typography component="span" sx={{ fontSize: '14px', color: '#000000' }}>If you are unable to scan the QR
                code, please enter this code manually into the app.</Typography>
            </Grid>
            <Grid item md={12}>
              <Box>
                <Typography component="span" color="primary"
                            sx={{ fontSize: '16px', fontWeight: 600, wordBreak: 'break-word' }}>
                  {qrCode}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12} sx={{ marginBottom: '32px' }}>
              <Typography component="span" sx={{ fontSize: '14px', color: '#000000' }}>Please keep this Key in a safe
                place.
                This Key will allow you to recover your Google Authenticator in case the phone loss.</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box sx={{ display: 'flex' }}>
                <Circle>3</Circle>
                <Typography component="span" sx={{ fontSize: '16px', fontWeight: 500, color: '#000000' }}>Verify you
                  code</Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Typography component="span" sx={{ fontSize: '14px', color: '#000000' }}>Enter the generated code in the
                input bellow.</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="2FA Code"
                value={authCode}
                onChange={e => setAuthCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} sm={12}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                size={'large'}
                onClick={() => onCancel()}
              >
                cancel
              </Button>
            </Grid>
            <Grid item xs={6} sm={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size={'large'}
                disabled={!authCode}
                onClick={setEnable2fa}
              >
                Enable 2FA
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
