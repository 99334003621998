import React, { FC, useContext, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FundsCards } from './FundCards';
import { FundsTable } from './FundsTable';
import { styled } from '@mui/material/styles';
import { getIcon } from '@coreComponents/base/CustomIcon/CustomIcon';
import { AuthContext } from '@coreProviders/AuthProvider';
import { KycRestrictionPanel } from '@businessComponents/KYCRestriction/KycRestrictionPanel';
import { ACCENT_PRIMARY_DARK, BACKGROUND_MAIN, TEXT_LIGHT } from '@coreConstants/colors';

export const Funds: FC<any> = () => {
  const authContext = useContext(AuthContext);

  const [mode, setMode] = useState('table');

  const handleModeSwitch = (event: React.MouseEvent<HTMLElement>, newMode: string) => {
    if (newMode !== null) {
      setMode(newMode);
    }
  };

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      marginLeft: '8px',
      padding: '8px',
      border: 0,
      backgroundColor: 'white',
      '&.Mui-selected': {
        backgroundColor: BACKGROUND_MAIN,
        color: TEXT_LIGHT,
        '&:hover': {
          backgroundColor: ACCENT_PRIMARY_DARK,
          color: TEXT_LIGHT
        }
      }
    }
  }));

  const modeSwitcher = <StyledToggleButtonGroup
    value={mode} exclusive
    onChange={handleModeSwitch}
  >
    <ToggleButton value="table" color="primary">
      {getIcon('list', mode === 'table' ? 'light' : 'background-dark')}
    </ToggleButton>
    <ToggleButton value="cards" color="primary">
      {getIcon('cards', mode === 'cards' ? 'light' : 'background-dark')}
    </ToggleButton>
  </StyledToggleButtonGroup>;

  const isFullyVerified = authContext.isAdmin || authContext.isVerified
    && (authContext.user?.kybStatus === 'approved' || !authContext.isFundOwner);

  return (
    <>
      {!isFullyVerified && <KycRestrictionPanel/>}
      {isFullyVerified && mode === 'table' && <FundsTable toolbarItem={modeSwitcher}/>}
      {isFullyVerified && mode === 'cards' && <FundsCards toolbarItem={modeSwitcher}/>}
    </>
  );
};
