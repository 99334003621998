import { createUploadLink } from 'apollo-upload-client';
import { tokenStorage } from '@coreComponents/system/TokenStorage';
import appConfig from '@src/config';

import { ApolloClient, InMemoryCache, from, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';

let requestRefreshToken: Promise<string> = null; // keep track of the current refresh request, don't call it if it's in progress

const isUnauthenticated = (data: any) => {
  return data &&
    data.errors &&
    data.errors[0] &&
    data.errors[0].extensions &&
    data.errors[0].extensions.code === 'UNAUTHENTICATED';
};

const setAuthorizationHeader = (options: any) => {
  const token = tokenStorage.getAccessToken();

  if (token && options.headers) {
    options.headers['Authorization'] = `Bearer ${token}`;
  }

  return options;
};

const refreshTokenFetchParams = {
  operationName: 'RefreshToken',
  query: `mutation RefreshToken {refreshToken}`
};
const refreshTokenFetchConfig: RequestInit = {
  method: 'POST',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  },
  body: JSON.stringify(refreshTokenFetchParams)
};

const fetchWithToken = (uri: string, options: RequestInit): Promise<Response> => {
  let originalResponse: Response = null;

  options = setAuthorizationHeader(options);

  const processRefreshToken = (refreshResponseJson: any) => {
    if (refreshResponseJson.data && refreshResponseJson.data.refreshToken) {
      const newToken = refreshResponseJson.data.refreshToken as string;

      tokenStorage.setAccessToken(newToken);
      return newToken;
    } else {
      tokenStorage.clearToken();
    }
  };
  const fetchOriginalRequest = (options: any) => {
    requestRefreshToken = null;
    options = setAuthorizationHeader(options);

    if (tokenStorage.getAccessToken()) {
      return fetch(uri, options);
    } else {
      return Promise.reject('No access token');
    }
  };

  return fetch(uri, options)
    .then((response) => {
      originalResponse = response;
      return response.clone().json();
    })
    .then((json) => {
      if (isUnauthenticated(json)) {
        if (!requestRefreshToken) {
          requestRefreshToken = fetch(uri, refreshTokenFetchConfig)
            .then(response => response.json())
            .then(processRefreshToken)
            .catch((error) => {
              return Promise.reject(error);
            });
        }

        return requestRefreshToken.then(() => fetchOriginalRequest(options));
      }

      return originalResponse;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const httpLink = createUploadLink({
  uri: appConfig.apiUrl,
  credentials: 'include',
  fetch: fetchWithToken
});


const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: appConfig.apiUrlWS,
    connectionParams: {
      authToken: tokenStorage.getAccessToken()
    }
  })
);

const link =
  typeof window !== 'undefined' && wsLink != null
    ? split(
      ({ query }) => {
        const def = getMainDefinition(query);
        return (
          def.kind === 'OperationDefinition' &&
          def.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    )
    : httpLink;

/*TODO: use localResolvers to keep and retrieve from data in Apollo Cache*/
export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, link])
});
