import { useState } from 'react';

export const useFilters = () => {
  const [queryVariables, setQueryVariables] = useState({
    queryFilters: [],
    sorters: []
  });
  const [filters, setFilters] = useState([]);
  const combineFilters = (newFilters?: any, newSearch?: any) => {
    const filtersToApply = newFilters || filters;
    const searchToApply = newSearch || search;
    // @ts-ignore
    const searchValueExists = Object.keys(searchToApply).length > 0
      && searchToApply.fieldFilters?.value
      && searchToApply.fieldFilters?.value[0] !== '';

    return searchValueExists ? [searchToApply, ...filtersToApply] : filtersToApply;
  };

  const onFilterChange = (changedFilters: any, changedSorters: any) => {
    setFilters(changedFilters);
    setQueryVariables({
      ...queryVariables,
      queryFilters: combineFilters(changedFilters),
      sorters: changedSorters
    });
  };
  const [search, setSearch] = useState({});
  const onSearchChange = (searchFilter: any) => {
    setSearch(searchFilter);
    setQueryVariables({
      ...queryVariables,
      queryFilters: combineFilters(null, searchFilter)
    });
  };

  return {
    queryVariables,
    onFilterChange,
    onSearchChange
  }
};
