import { gql } from '@apollo/client';

export const CREATE_PARTICIPATION_REQUEST = gql`
  mutation AddProjectPRequest(
    $participationRequest: ProjectPRequestInput!,
    $communitySocials: SocialDataMutationListInput,
    $userSocials: SocialDataMutationListInput
  ) {
    addProjectPRequest(participationRequest: $participationRequest, communitySocials: $communitySocials, userSocials: $userSocials) {
      projectPRequestId,
      fundName,
      leadSource,
      contribution,
      investmentCurrency,
      investmentAmount,
      project {
        projectId
        rounds {
          title
          price
          tokenCount
        }
      }
    }
  }
`;

export const CREATE_FUND_PARTICIPATION_REQUEST = gql`
  mutation AddRollingFundPRequest($participationRequest: RollingFundPRequestInput!) {
    addRollingFundPRequest(participationRequest: $participationRequest) {
      rollingFundPRequestId
      isLegalPerson
      rollingFund {
        title
      },
    }
  }
`;

export const UPDATE_FUND_PARTICIPATION_REQUEST = gql`
  mutation UpdateRollingFundPRequest($participationRequest: RollingFundPRequestMutationInput) {
    updateRollingFundPRequest(participationRequest: $participationRequest) {
      rollingFundPRequestId
    }
  }
`;

export const UPDATE_PARTICIPATION_REQUEST = gql`
  mutation UpdateProjectPRequest(
    $participationRequest: ProjectPRequestMutationInput,
    $userSocials: SocialDataMutationListInput,
    $communitySocials: SocialDataMutationListInput
  ) {
    updateProjectPRequest(participationRequest: $participationRequest, userSocials: $userSocials, communitySocials: $communitySocials) {
      projectPRequestId
    }
  }
`;

export const WITHDRAW_PARTICIPATION_REQUEST = gql`
  mutation WithdrawProjectPRequest($participationRequestId: ID!, $comment: String!) {
    withdrawProjectPRequest(participationRequestId: $participationRequestId, comment: $comment) {
      projectPRequestId
    }
  }
`;

export const WITHDRAW_ROLLING_FUND_PARTICIPATION_REQUEST = gql`
  mutation WithdrawRollingFundPRequest($participationRequestId: ID!, $comment: String!) {
    withdrawRollingFundPRequest(participationRequestId: $participationRequestId, comment: $comment) {
      rollingFundPRequestId
    }
  }
`;

export const APPROVE_PARTICIPATION_REQUEST = gql`
  mutation ApproveProjectPRequest($participationRequestId: ID!, $tokenAllocation: [TokenAllocationForRoundInput!], $document: Upload, $externalTemplateId: String) {
    approveProjectPRequest(participationRequestId: $participationRequestId, tokenAllocation: $tokenAllocation, externalTemplateId: $externalTemplateId, document: $document) {
      projectPRequestId
    }
  }
`;

export const APPROVE_ROLLING_FUND_PARTICIPATION_REQUEST = gql`
  mutation ApproveRollingFundPRequest($participationRequestId: ID!, $startDate: Date, $externalTemplateId: String) {
    approveRollingFundPRequest(participationRequestId: $participationRequestId, startDate: $startDate, externalTemplateId: $externalTemplateId) {
      rollingFundPRequestId
    }
  }
`;

export const DECLINE_PARTICIPATION_REQUEST = gql`
  mutation DeclineProjectPRequest($participationRequestId: ID!, $comment: String!) {
    declineProjectPRequest(participationRequestId: $participationRequestId, comment: $comment) {
      projectPRequestId
    }
  }
`;

export const DECLINE_ROLLING_FUND_PARTICIPATION_REQUEST = gql`
  mutation DeclineRollingFundPRequest($participationRequestId: ID!, $comment: String!) {
    declineRollingFundPRequest(participationRequestId: $participationRequestId, comment: $comment) {
      rollingFundPRequestId
    }
  }
`;

export const WITHDRAW_APPROVAL_PARTICIPATION_REQUEST = gql`
  mutation WithdrawProjectPRequestApproval($participationRequestId: ID!, $comment: String!) {
    withdrawProjectPRequestApproval(participationRequestId: $participationRequestId, comment: $comment) {
      projectPRequestId
    }
  }
`;

export const WITHDRAW_ROLLING_FUND_APPROVAL_PARTICIPATION_REQUEST = gql`
  mutation WithdrawRollingFundPRequestApproval($participationRequestId: ID!, $comment: String!) {
    withdrawRollingFundPRequestApproval(participationRequestId: $participationRequestId, comment: $comment) {
      rollingFundPRequestId
    }
  }
`;

export const REQUEST_NOVATION = gql`
  mutation RequestNovation($participationRequestId: ID!) {
    requestNovation(participationRequestId: $participationRequestId) {
      projectPRequestId
    }
  }
`;

export const CANCEL_NOVATION = gql`
  mutation CancelNovation($participationRequestId: ID!) {
    cancelNovation(participationRequestId: $participationRequestId) {
      projectPRequestId
    }
  }
`;

export const SET_FUNDS_RECEIVED = gql`
  mutation SetFundReceived($contractId: ID!, $value: Boolean!) {
    setFundsReceived(contractId: $contractId, value: $value) {
      contractId
    }
  }
`;
