import React, { useContext } from 'react';
import { AuthContext } from '@coreProviders/AuthProvider';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { AccountInfo } from '@businessComponents/AccountInfo/AccountInfo';
import { SidebarMenuItem } from '@coreComponents/base/Sidebar/SidebarMenuItem';
import { BORDER_LIGHT } from '@coreConstants/colors';
import './SidebarMenu.scss';

export const SidebarMenu = (props: any) => {
  const authContext = useContext(AuthContext);
  const screenContext = useContext(ScreenContext);

  const { handleDrawerClose, menuItems } = props;
  const { isPhone, isTablet } = screenContext;
  const menuItemsToDraw = menuItems || authContext.menuItems;

  const menuStyles = {
    ...(isTablet && { borderTop: `1px solid ${BORDER_LIGHT}` })
  };

  const commonItems = menuItemsToDraw.filter((item: any) => !item.isBottom);
  const bottomItems = menuItemsToDraw.filter((item: any) => item.isBottom);

  return (
    <div className="menu" style={menuStyles}>
      <ul className="menu__items">
        {commonItems && commonItems.map((item: any, index: number) => <SidebarMenuItem
          key={index} data={item} handleDrawerClose={handleDrawerClose} isPhone={isPhone}/>
        )}
      </ul>
      <ul className="menu__items menu__items--bottom">
        {bottomItems && bottomItems.map((item: any, index: number) => <SidebarMenuItem
          key={index} data={item} handleDrawerClose={handleDrawerClose} isPhone={isPhone}/>
        )}
      </ul>
      {
        <ul className="menu__items menu__items--profile">
          <li>
            <AccountInfo/>
          </li>
        </ul>
      }
    </div>
  );
};
