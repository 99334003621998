import React, { useContext, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { Password } from '@coreComponents/base/Password';
import { tokenStorage } from '@coreComponents/system/TokenStorage';
import { AuthContext } from '@coreProviders/AuthProvider';
import { useMutation } from '@apollo/client';
import { LoginResult, MutationLoginArgs } from '@store/generated-models';
import { SIGN_IN } from '@store/queries/users';
import { AuthContentHeader } from '@businessComponents/Authentication/AuthContentHeader';
import { TextLink } from '@coreComponents/base/TextLink/TextLink';
import { useForm } from '@coreHooks/useForm';

export const SignInForm: React.FC<any> = (props) => {
  const { setToken, setIsConfirm2faStep, setAuthInfoBlock } = props.methods;

  const [requestIsProcessing, setRequestIsProcessing] = useState(false);

  const authContext = useContext(AuthContext);

  const [login] = useMutation<{ login: LoginResult }, MutationLoginArgs>(SIGN_IN);

  const handleError = (error: any) => {
    let errorCode = '';
    try {
      errorCode = error.graphQLErrors[0].extensions.code;
    } catch (ignored) {
    }

    if (errorCode === 'auth.access_denied' || errorCode === 'auth.login_invalid') {
      authContext.showMessage('Invalid credentials');
    } else if (errorCode === 'auth.unconfirmed_email') {
      setAuthInfoBlock(
        <AuthContentHeader title="Please confirm your email"
                           subtitle="Follow the instructions in the message that we have sent to your email"
                           buttonConfig={{ title: 'Ok' }}
                           isWithoutMargin
        />
      );
    } else if (errorCode === 'auth.unconfirmed_device') {
      setAuthInfoBlock(
        <AuthContentHeader title="Verify new device"
                           subtitle="You are trying to log in from an unknown device. For your security, please confirm the device using the link sent to your email."
                           buttonConfig={{ title: 'Ok' }}
                           isWithoutMargin
        />
      );
    } else if (errorCode === 'auth.password_has_to_be_changed') {
      setAuthInfoBlock(
        <AuthContentHeader title="Password Update Required"
                           subtitle="Your password needs to be changed. Please follow the instructions in the message that we have sent to your email."
                           buttonConfig={{ title: 'Ok' }}
                           isWithoutMargin
        />
      );
    } else {
      authContext.showMessage('Unknown error occurred');
    }
  };

  const handleSetToken = (res: any) => {
    const loginData = res.data.login;

    if (loginData.authToken && loginData.authTokenAction === 'TwoFactorAuth') {
      setToken(loginData.authToken);
      tokenStorage.setAccessToken(loginData.authToken);
      setIsConfirm2faStep(true);
    } else {
      authContext.login(loginData);
    }
  };
  const handleSignIn = () => {
    login({
      variables: {
        ...data
      }
    })
      .then(handleSetToken)
      .catch(handleError)
      .finally(() => {
        setRequestIsProcessing(false);
      });
  };

  const onSubmit = () => {
    setRequestIsProcessing(true);
    handleSignIn();
  };

  type SignInForm = {
    email: string;
    password: string;
  }
  const initialValues: SignInForm = {
    email: '',
    password: ''
  };
  const validationConfig = {
    email: {
      type: 'required',
      message: 'Email cannot be empty'
    },
    password: {
      type: 'required',
      message: 'The password cannot be empty'
    }
  };
  const {
    data,
    handleTextChange,
    handleSubmit
  } = useForm<SignInForm>({ validationConfig, initialValues, onSubmit });

  return <>
    <AuthContentHeader title="Welcome Back!" subtitle="Please enter your details"/>
    <form autoComplete="off">
      <TextField
        name="email"
        variant="outlined"
        fullWidth
        label="Email"
        autoComplete="email"
        value={data.email}
        disabled={requestIsProcessing}
        onChange={handleTextChange}
      />
      <Password
        name="password"
        style={{ margin: '24px 0 8px 0' }}
        fullWidth
        label="Password"
        autoComplete="password"
        value={data.password}
        disabled={requestIsProcessing}
        onChange={handleTextChange}
      />
      <div style={{ marginBottom: '28px' }}> {/*TODO: create separate component? FormFieldInfo?*/}
        <TextLink text="Forgot password?" to="/forgot-password" classNames="text--small-medium"/>
      </div>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        size={'large'}
        onClick={handleSubmit}
        disabled={requestIsProcessing || !data.email || !data.password}
      >
        {!requestIsProcessing ? `Sign in` : 'Signing in...'}
      </Button>
    </form>
  </>;
};
