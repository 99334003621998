import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import DataTable, { COLUMN_TYPE, DataTableColumn } from '@coreComponents/base/DataTable/DataTable';
import { Button } from '@mui/material';
import { Text, TEXT_COLOR } from '@coreComponents/base/Text/Text';

export const AllTransactionsTable = () => {
  const { enqueueSnackbar } = useSnackbar();
  const screenContext = useContext(ScreenContext);
  const { isPhone, isTablet, isMobile } = screenContext;

  const rows: any[] = [
    {
      description: 'Wallet Withdrawal',
      transaction_id: '#13455498',
      type: 'Withdrawal',
      card: '123450576068',
      date: new Date(Date.now()),
      amount: -2500,
      receipt: 'http://test.com'
    },
    {
      description: 'Top-up wallet',
      transaction_id: '#13455498',
      type: 'Deposit',
      card: '123450576068',
      date: new Date(Date.now()),
      amount: 750,
      receipt: 'http://test.com'
    },
    {
      description: 'Investment',
      transaction_id: '#13455498',
      type: 'Subscription',
      card: '123450576068',
      date: new Date(Date.now()),
      amount: -150,
      receipt: 'http://test.com'
    },
    {
      description: 'Q1 subscription',
      transaction_id: '#13455498',
      type: 'Subscription',
      card: '123450576068',
      date: new Date(Date.now()),
      amount: -1050,
      receipt: 'http://test.com'
    },
    {
      description: 'Top-up wallet',
      transaction_id: '#13455498',
      type: 'Deposit',
      card: '123450576068',
      date: new Date(Date.now()),
      amount: 840,
      receipt: 'http://test.com'
    }
  ];

  const columns: DataTableColumn[] = [
    {
      code: 'description',
      label: 'Description',
      render: (row: any, column: any) => {
        return <>Wallet Withdrawal</>;
      }
    },
    {
      code: 'transaction_id',
      label: 'Transaction ID',
      render: (row: any, column: any) => {
        return row[column.code];
      }
    },
    {
      code: 'type',
      label: 'Type',
      align: 'center',
      render: (row: any, column: any) => {
        const value = row[column.code];
        return value;
      }
    },
    {
      code: 'card',
      label: 'Card',
      render: (row: any, column: any) => {
        const value = row[column.code];
        return value;
      }
    },
    {
      code: 'date',
      label: 'Date',
      type: COLUMN_TYPE.DATE
    },
    {
      code: 'amount',
      label: 'Amount',
      render: (row: any, column: any) => {
        const value = row[column.code];
        const color = value >= 0 ? TEXT_COLOR.successDark : TEXT_COLOR.errorDark;
        const sign = value > 0 ? '+' : '-';

        return <Text color={color}>{sign}${Math.abs(value)}</Text>;
      }
    },
    {
      code: 'receipt',
      label: 'Receipt',
      render: (row: any, column: any) => {
        return <Button variant="outlined">Download</Button>;
      }
    }
  ];

  return <DataTable rows={rows} columns={columns}/>;
};
