import React, { useContext, useEffect, useState } from 'react';
import { Panel } from '@coreComponents/base/Panel/Panel';
import { Modal } from '@coreComponents/base/Modal/Modal';
import { Box, Button, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ME, GET_USER_BY_ID } from '@store/queries/users';
import { useForm } from '@coreHooks/useForm';
import { VALIDATION_PATTERNS } from '@coreConstants/common';
import {
  MutationCreateUserArgs,
  MutationUpdateMeArgs,
  MutationUpdateUserArgs,
  PasswordInput,
  UserAccountStatus
} from '@store/generated-models';
import { CREATE_USER, DELETE_USER, UPDATE_ME, UPDATE_USER } from '@store/mutations/users';
import { AuthContext } from '@coreProviders/AuthProvider';
import { useSnackbar } from 'notistack';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { Password } from '@coreComponents/base/Password';

interface InvestorDetailerProps {
  isCurrentUser?: boolean;
}

export const UserDetailed = (props: InvestorDetailerProps) => {
  const { isCurrentUser = false } = props;
  const { id } = useParams();
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const { data: myData, refetch: refetchMyData } = useQuery(GET_ME, {
    fetchPolicy: 'network-only',
    skip: !isCurrentUser
  });

  const { data: userData, loading } = useQuery(GET_USER_BY_ID, {
    fetchPolicy: 'network-only',
    variables: {
      userId: id
    },
    skip: !id
  });

  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const validatePassword = (value: string) => {
    const message = 'Password must contain at least 6 characters, uppercase and lowercase letters, a number and a special character';
    const isValid = Boolean(value.match(VALIDATION_PATTERNS.PASSWORD));

    if (isValid === false) {
      enqueueSnackbar(message, { ...{ variant: 'error' } });
    }
    return isValid;
  };

  const checkEmptyValue = (value: string) => {
    return value.length > 0;
  };
  const validationConfig = {
    firstName: {
      custom: {
        isValid: checkEmptyValue,
        message: 'The first name cannot be empty'
      }
    },
    lastName: {
      custom: {
        isValid: checkEmptyValue,
        message: 'The last name cannot be empty'
      }
    },
    email: {
      pattern: {
        value: VALIDATION_PATTERNS.EMAIL,
        message: 'The last name cannot be empty'
      }
    }
  };

  const commonPasswordValidation = {
    newPassword: {
      pattern: {
        value: VALIDATION_PATTERNS.PASSWORD,
        message: 'Incorrect password'
      }
    },
    confirmPassword: {
      custom: {
        isValid: (value: string, data: Record<string, unknown>) => {
          const message = 'Password and password confirmation do not match';
          const isValid = Boolean(data.newPassword && data.newPassword === value);

          if (isValid === false) {
            enqueueSnackbar(message, { ...{ variant: 'error' } });
          }
          return isValid;
        },
        message: 'Passwords do not match'
      }
    }
  };
  const oldPasswordValidation = {
    oldPassword: {
      custom: {
        isValid: validatePassword,
        message: 'Incorrect password'
      }
    }
  };
  const code2faValidation = {
    code2fa: {
      custom: {
        isValid: checkEmptyValue,
        message: 'The 2FA Code cannot be empty'
      }
    }
  };

  const handleCancel = () => {
    navigate('/private/investors');
  };

  type ProjectForm = {
    firstName: string;
    lastName: string;
    email: string;
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    code2fa: string;
    status: boolean;
    wallets: any[];
  }

  const initialValues: ProjectForm = {
    firstName: '',
    lastName: '',
    email: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    code2fa: '',
    status: !id,
    wallets: []
  };

  const [createUser, {
    data: userDataCreate,
    loading: loadingUserCreate,
    error: errorUserCreate
  }] = useMutation(CREATE_USER, { fetchPolicy: 'no-cache' });
  if (loadingUserCreate) {
  }
  if (errorUserCreate) {
  }

  const [updateUser, {
    loading: loadingUserUpdate
  }] = useMutation(UPDATE_USER, { fetchPolicy: 'no-cache' });

  const [updateMe] = useMutation(UPDATE_ME, { fetchPolicy: 'no-cache' });

  const [deleteUser, {
    loading: loadingUserDelete
  }] = useMutation(DELETE_USER, { fetchPolicy: 'no-cache' });

  const onSubmit = (data: any) => {
    const { newPassword, confirmPassword, oldPassword, code2fa, ...changedUserData } = data;
    changedUserData.status = data.status ? UserAccountStatus.Active : UserAccountStatus.Inactive;
    if (id) {
      let variables: MutationUpdateUserArgs = {
        userId: user.userId,
        user: changedUserData
      };

      if (newPassword) {
        variables.password = newPassword;
      }

      updateUser({
        variables
      }).then(() => {
        navigate('/private/investors');
      }).catch((error: any) => {
        enqueueSnackbar(error.message, { ...{ variant: 'error' } });
      });
    } else if (isCurrentUser) {
      let variables: MutationUpdateMeArgs = {
        user: changedUserData
      };
      if (newPassword && oldPassword) {
        const passwordInput: PasswordInput = { newPassword, oldPassword };
        if (code2fa && code2fa.length > 0) {
          passwordInput.code2fa = code2fa;
        }
        variables.password = passwordInput;
      }

      updateMe({
        variables
      }).then(() => {
        refetchMyData();
        enqueueSnackbar('Saved', { ...{ variant: 'success' } });
      }).catch((error: any) => {
        enqueueSnackbar(error.message, { ...{ variant: 'error' } });
      });
    } else {
      let variables: MutationCreateUserArgs = {
        user: changedUserData
      };

      if (newPassword) {
        variables.password = newPassword;
      }

      createUser({
        variables
      }).then(() => {
        navigate('/private/investors');
      }).catch((error: any) => {
        enqueueSnackbar(error.message, { ...{ variant: 'error' } });
      });
    }
  };

  const {
    handleTextChange, handleSubmit, errors, data, setData, handleCheckboxChange
  } = useForm<ProjectForm>({ validationConfig, initialValues, onSubmit: () => onSubmit(data) });

  const isNewInvestorCreation = !id && !isCurrentUser;
  const isInvestorEditingByAdmin = id && (authContext.isAdmin || authContext.isFundOwner);

  if (isNewInvestorCreation) {
    Object.assign(validationConfig, commonPasswordValidation);
  } else if (isInvestorEditingByAdmin) {
    if ((data.newPassword && data.newPassword.length > 0) || (data.confirmPassword && data.confirmPassword.length > 0)) {
      Object.assign(validationConfig, commonPasswordValidation);
    }
  } else if (isCurrentUser) {
    if ((data.newPassword && data.newPassword.length > 0) || (data.oldPassword && data.oldPassword.length > 0)
      || (data.confirmPassword && data.confirmPassword.length > 0)) {
      Object.assign(validationConfig, commonPasswordValidation);
      Object.assign(validationConfig, oldPasswordValidation);
      if (authContext.user.is2faEnabled) {
        Object.assign(validationConfig, code2faValidation);
      }
    }
  }

  useEffect(() => {
    let savedUserData: typeof data = { ...data };

    if (userData || myData) {
      let user = isCurrentUser ? myData.me : userData.userById;

      setUser(user);

      for (const formField in savedUserData) {
        // @ts-ignore
        savedUserData[formField] = user[formField];
      }
      savedUserData.newPassword = '';
      savedUserData.confirmPassword = '';
      savedUserData.status = user.status == UserAccountStatus.Active;
      setData(savedUserData);
    }
  }, [userData, myData]);

  const handleDelete = () => {
    deleteUser({
      variables: {
        userId: user.userId
      }
    }).then(() => {
      navigate('/private/investors');
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { ...{ variant: 'error' } });
    });
  };


  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  function confirmDelete() {
    setIsOpenDeleteDialog(true);
  }

  function makeInputPassword(event: any) {
    event.currentTarget.type = 'password';
  }

  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  const isShowDelete = id && !isPhone;
  const isShowMobileDelete = id && isPhone;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Modal
        open={isOpenDeleteDialog}
        title="Delete Account?"
        onDecline={() => setIsOpenDeleteDialog(false)}
        onAccept={() => handleDelete()}
      >
        <>
          <Typography sx={{ marginBottom: '16px' }}>
            Are you sure you want to delete the account of the investor {data.firstName} {data.lastName}?
          </Typography>
        </>
      </Modal>
      <Panel>
        <div className="form">
          <div className="form__body">
            <Grid container spacing={{ md: 7, xs: 1 }}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={{ md: 4, xs: 2 }}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{isInvestorEditingByAdmin ? 'Password' : 'Change Password'}</Typography>
                  </Grid>
                  {isCurrentUser && <Grid item xs={12}>
                    <Password label="Current Password" name="oldPassword"
                              fullWidth onChange={handleTextChange} value={data.oldPassword || ''}
                      // onFocus={makeInputPassword}
                              error={errors.oldPassword && errors.oldPassword.length > 0}
                    />
                  </Grid>
                  }
                  <Grid item xs={12}>
                    <Password label="New Password" name="newPassword"
                              fullWidth onChange={handleTextChange} value={data.newPassword || ''}
                      // onFocus={makeInputPassword}
                              error={errors.newPassword && errors.newPassword.length > 0}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Password label="Confirm Password" name="confirmPassword"
                              fullWidth onChange={handleTextChange} value={data.confirmPassword || ''}
                      // onFocus={makeInputPassword}
                              error={errors.confirmPassword && errors.confirmPassword.length > 0}
                    />
                  </Grid>

                  {isCurrentUser && authContext.user.is2faEnabled && <Grid item xs={6}>
                    <TextField label="2FA Code" name="code2fa" variant="outlined"
                               fullWidth onChange={handleTextChange} value={data.code2fa}
                               error={errors.code2fa && errors.code2fa.length > 0}
                    />
                  </Grid>
                  }
                  {isInvestorEditingByAdmin &&
                    <Grid item xs={12}>
                      <FormControlLabel
                        label="Active"
                        control={
                          <Switch
                            name="status" onChange={handleCheckboxChange}
                            checked={!!data.status}
                          />
                        }
                      />
                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={{ md: 4, xs: 2 }}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Personal Information</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="First Name" name="firstName" variant="outlined"
                               fullWidth onChange={handleTextChange} value={data.firstName}
                               error={errors.firstName && errors.firstName.length > 0}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="Last Name" name="lastName" variant="outlined"
                               fullWidth onChange={handleTextChange} value={data.lastName}
                               error={errors.lastName && errors.lastName.length > 0}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField label="Email" name="email" variant="outlined"
                               fullWidth onChange={handleTextChange} value={data.email}
                               error={errors.email && errors.email.length > 0}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className="form__buttons">
            {
              !isCurrentUser && <div className="button-group">
                {
                  !isCurrentUser &&
                  <Button sx={{ width: { xs: isNewInvestorCreation ? '140px' : '96px', md: 'auto' } }} variant="outlined"
                          onClick={handleCancel}
                          disabled={loadingUserUpdate || loadingUserDelete}
                  >Cancel</Button>
                }
                {
                  isShowDelete && <Button variant="outlined" onClick={confirmDelete} color="error"
                                          disabled={loadingUserUpdate || loadingUserDelete}
                  >Delete</Button>
                }
              </div>
            }
            {
              isShowMobileDelete && <div className="button-group">
                <Button variant="outlined" onClick={confirmDelete} color="error"
                        sx={{ width: { xs: '96px', md: 'auto' } }}
                        disabled={loadingUserUpdate || loadingUserDelete}
                >Delete</Button>
              </div>
            }
            <div className="button-group">
              <Button variant="contained" onClick={handleSubmit}
                      sx={{
                        width: {
                          xs: isCurrentUser ? '280px' : isNewInvestorCreation ? '140px' : '96px',
                          md: 'auto'
                        }
                      }}
                      disabled={loadingUserUpdate || loadingUserDelete}
              >Save</Button>
            </div>
          </div>
        </div>
      </Panel>
    </Box>
  );
};
