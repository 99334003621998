import React, { useContext, useEffect, useState } from 'react';
import { MutationConfirmDeviceArgs } from '@store/generated-models';
import { AuthContext } from '@coreProviders/AuthProvider';
import { useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { CONFIRM_DEVICE } from '@store/mutations/users';

export const ConfirmDevice: React.FC<any> = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { token } = useParams();

  const [state, setState] = useState('validating');

  const [confirmEmail] = useMutation<{ confirmDevice: boolean }, MutationConfirmDeviceArgs>(CONFIRM_DEVICE);

  useEffect(() => {
    if (!!token) {
      confirmEmail({
        variables: {
          token: token
        }
      })
        .then((res) => {
          if (res.data.confirmDevice) {
            setState('confirmed');
          } else {
            setState('error');
          }
        })
        .catch(() => {
          setState('error');
        });
    }
  }, []);


  let dialogContent: JSX.Element = null;

  const DeviceValidationInProgress = <div>Validating confirmation...</div>;
  const DeviceValidationFailure = <>
    <Typography variant="authSecondaryTitle">Couldn't confirm the new device</Typography>
    <div>
      <div style={{ textAlign: 'center', fontSize: '14px' }}>
        <p>Make sure you're using the latest received confirmation email.</p>
      </div>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size={'large'}
        onClick={() => {
          navigate('/login');
        }}
      >Back to the homepage</Button>
    </div>
  </>;
  const DeviceValidationSuccess = <>
    <Typography variant="authSecondaryTitle">Device was confirmed successfully</Typography>
    <div>
      <div style={{ textAlign: 'center', fontSize: '14px' }}>
        <p>You can log in now.</p>
      </div>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size={'large'}
        onClick={() => {
          navigate('/login');
        }}
      >Log in</Button>
    </div>
  </>;

  if (token) {
    if (state === 'validating') {
      dialogContent = DeviceValidationInProgress;
    } else if (state === 'error') {
      dialogContent = DeviceValidationFailure;
    } else if (state === 'confirmed') {
      dialogContent = DeviceValidationSuccess;
    }
  }

  return dialogContent;
};
