import { SESSION_INACTIVITY_TIMEOUT, SESSION_TIMEOUT_COUNTDOWN } from '@coreConstants/common';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

export const TimeoutDialog = (props: any) => {
  const { onTimeoutEnd } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState<number>(SESSION_TIMEOUT_COUNTDOWN);
  const watchdogTimeout = useRef<any>(null);

  const resetWatchdog = () => {
    if (watchdogTimeout.current) {
      clearTimeout(watchdogTimeout.current);
    }

    watchdogTimeout.current = setTimeout(() => {
      openDialog();
    }, SESSION_INACTIVITY_TIMEOUT * 1000);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const openDialog = () => {
    setTimeoutCountdown(SESSION_TIMEOUT_COUNTDOWN);
    setIsOpen(true);
  };

  useEffect(() => {
    resetWatchdog();

    /*TODO: it's not enough, some clicks do not bubbling*/
    document.body.addEventListener('click', resetWatchdog);

    return () => {
      if (watchdogTimeout.current) {
        document.body.removeEventListener('click', resetWatchdog);
        clearTimeout(watchdogTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    let timeout: any = null;

    if (isOpen) {
      if (timeoutCountdown <= 0) {
        closeDialog();
        onTimeoutEnd();
      } else {
        timeout = setTimeout(() => {
          setTimeoutCountdown(timeoutCountdown => timeoutCountdown - 1);
        }, 1000);
      }
    } else {
      if (!isOpen) {
        clearTimeout(timeout);
      }
    }
    return () => clearInterval(timeout);
  }, [isOpen, timeoutCountdown]);

  return <Dialog
    open={isOpen}
    onClose={closeDialog}
    aria-labelledby="session-timeout-dialog-title"
    aria-describedby="session-timeout-description"
    PaperProps={{
      sx: {
        maxWidth: '368px'
      }
    }}
  >
    <DialogTitle id="session-timeout-dialog-title">You will be logged out
      in {timeoutCountdown} second{timeoutCountdown > 1 ? 's' : ''}</DialogTitle>
    <DialogContent>
      <DialogContentText id="session-timeout-dialog-description">
        You seem to be inactive for a while. For your security, you will be logged out from the current session.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeDialog} variant="contained" color="primary" autoFocus>
        I'm still here
      </Button>
    </DialogActions>
  </Dialog>;
};
