import { ChangeEvent, MouseEvent, useState } from 'react';
import { crudStatus } from '../constants/common';
import { isValidEmail, isValidPassword, removeFromArrayByIndex } from '../helpers/utils';
import { useSnackbar } from 'notistack';

type Validation = {
  pattern?: {
    value: RegExp;
    message?: string;
  };
  custom?: {
    isValid: (value: string, data?: Record<string, unknown>) => boolean;
    message?: string;
  };
  type?: string;
}
type Options<T> = {
  validationConfig?: any;
  initialValues?: Partial<T> | null;
  onSubmit?: (data: Record<string, unknown>) => void;
}
export type FieldError = Record<string, string | any>

export const useForm = <T extends Record<keyof T, any> = Record<string, unknown>>(options: Options<T>) => {
  const [data, setData] = useState((options?.initialValues || {}) as Record<string, any>);
  const [errors, setErrors] = useState<FieldError>({});
  const { enqueueSnackbar } = useSnackbar();

  const handleValueChange = (event: any, value: any) => {
    const name = event.target.name;

    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [name]: value };
    });
  };
  const handleTextChange = (event: any) => {
    const value = event.target.value;
    const name = event.target.name;

    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [name]: value };
    });
  };
  const handleRichEditorChange = (name: string, value: string) => {
    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [name]: value };
    });
  };

  const handleCheckboxChange = (event: any) => {
    const value = event.target.checked;
    const name = event.target.name;
    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [name]: value };
    });
  };

  const handleDateChange = (key: string) => (value: any) => {
    setData((prevData: Record<keyof T, unknown>) => {
      return { ...prevData, [key]: value };
    });
  };

  const handleAddWallet = (key: string) => (_e: MouseEvent<HTMLButtonElement>) => {
    const wallet = {
      currency: '',
      link: '',
      status: crudStatus.CREATED
    };

    const wallets = [...data[key], wallet];
    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [key]: wallets };
    });
  };

  const handleWalletCurrencyChange = (key: string) => (currency: string, index: number) => {
    const newWalletsState = [...data[key]];
    newWalletsState[index].currency = currency;

    if (newWalletsState[index].walletId) {
      newWalletsState[index].status = crudStatus.UPDATED;
    }

    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [key]: newWalletsState };
    });
  };

  const handleWalletLinkChange = (key: string) => (value: string, index: number) => {
    const newWalletsState = [...data[key]];
    newWalletsState[index].link = value.trim();

    if (newWalletsState[index].walletId) {
      newWalletsState[index].status = crudStatus.UPDATED;
    }

    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [key]: newWalletsState };
    });
  };

  const handleDeleteWallet = (key: string) => (index: number) => {
    const newWalletsState = [...data[key]];

    if (newWalletsState[index].walletId) {
      newWalletsState[index].status = crudStatus.DELETED;
    } else {
      removeFromArrayByIndex(newWalletsState, index);
    }

    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [key]: newWalletsState };
    });

    if (errors[key]) {
      const walletErrors: Record<number, unknown> = errors[key];
      if (walletErrors[index]) {
        delete walletErrors[index];
      }
      setErrors((prevData: FieldError) => {
        return { ...prevData, [key]: walletErrors };
      });
    }
  };

  const handleAddListItem = (key: string, newItem: any) => (_e: MouseEvent<HTMLButtonElement>) => {
    const list = [...data[key] || [], { ...newItem, status: crudStatus.CREATED }];
    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [key]: list };
    });
  };

  const handleChangeListItem = (key: string, fieldName: string, idField: string) => (value: string, index: number) => {
    const newListState = [...data[key]];
    newListState[index][fieldName] = value;

    const isCreated = newListState[index].status === crudStatus.CREATED;

    if (!isCreated && newListState[index][idField]) {
      newListState[index].status = crudStatus.UPDATED;
    }


    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [key]: newListState };
    });
  };

  const handleDeleteListItem = (key: string, idField: string) => (index: number) => {
    const newListState = [...data[key]];

    if (newListState[index][idField]) {
      newListState[index].status = crudStatus.DELETED;
    } else {
      removeFromArrayByIndex(newListState, index);
    }

    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [key]: newListState };
    });

    if (errors[key]) {
      const listErrors: Record<number, unknown> = errors[key];
      if (listErrors[index]) {
        delete listErrors[index];
      }
      setErrors((prevData: FieldError) => {
        return { ...prevData, [key]: listErrors };
      });
    }
  };

  const handleAddSocial = (key: string) => (_e: MouseEvent<HTMLButtonElement>) => {
    const social = {
      profileUrl: '',
      profileName: '',
      social: {
        socialCode: ''
      },
      status: crudStatus.CREATED
    };

    const socials = [...data[key], social];
    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [key]: socials };
    });
  };

  const handleDeleteSocial = (key: string) => (index: number) => {
    const newSocialsState = [...data[key]];

    if (newSocialsState[index].socialDataId) {
      newSocialsState[index].status = crudStatus.DELETED;
    } else {
      removeFromArrayByIndex(newSocialsState, index);
    }

    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [key]: newSocialsState };
    });

    if (errors[key]) {
      const socialErrors: Record<number, unknown> = errors[key];
      if (socialErrors[index]) {
        delete socialErrors[index];
      }
      setErrors((prevData: FieldError) => {
        return { ...prevData, [key]: socialErrors };
      });
    }
  };

  const handleSocialChange = (key: string) => (value: string, index: number) => {
    const newSocialsState = [...data[key]];
    newSocialsState[index].profileUrl = value.trim();

    if (newSocialsState[index].socialDataId) {
      newSocialsState[index].status = crudStatus.UPDATED;
    }

    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [key]: newSocialsState };
    });
  };

  const handleSocialTitleChange = (key: string) => (socialCode: string, index: number) => {
    const newSocialsState = [...data[key]];
    newSocialsState[index].social.socialCode = socialCode;

    if (newSocialsState[index].socialDataId) {
      newSocialsState[index].status = crudStatus.UPDATED;
    }

    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [key]: newSocialsState };
    });
  };

  const handleAddRound = (key: string) => () => {
    const newRound = {
      price: 0,
      title: '',
      tokenCount: 0,
      status: crudStatus.CREATED
    };

    const rounds = [...data[key], newRound];
    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [key]: rounds };
    });
  };

  const handleDeleteRound = (key: string) => (index: number) => {
    const newRoundDataState = [...data[key]];

    if (newRoundDataState[index].projectRoundId) {
      newRoundDataState[index].status = crudStatus.DELETED;
    } else {
      removeFromArrayByIndex(newRoundDataState, index);
    }

    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [key]: newRoundDataState };
    });

    if (errors[key]) {
      const roundErrors: Record<number, unknown> = errors[key];
      if (roundErrors[index]) {
        delete roundErrors[index];
      }
      setErrors((prevData: FieldError) => {
        return { ...prevData, [key]: roundErrors };
      });
    }
  };

  const handleRoundChange = (key: string) => (event: ChangeEvent<HTMLInputElement>, index: number, newValue?: number) => {
    let value: string | number = newValue || event.currentTarget.value;
    const { name } = event.currentTarget;
    const newRoundDataState = [...data[key]];
    const round: Record<string, unknown> = newRoundDataState[index];

    if (typeof round[name] !== 'undefined') {
      round[name] = value;
    }
    if (newRoundDataState[index].projectRoundId) {
      newRoundDataState[index].status = crudStatus.UPDATED;
    }

    setData((prevData: Record<keyof T, string>) => {
      return { ...prevData, [key]: newRoundDataState };
    });
  };

  const handleSelectChange = (event: any) => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = () => {
    const validationConfig = options?.validationConfig;
    let isValid = true;

    if (validationConfig) {
      const fieldErrors: FieldError = {};
      for (const key in validationConfig) {
        const value = typeof data[key] === 'undefined' ? '' : data[key];
        const validation = validationConfig[key];
        let fieldIsValid = false;
        let errorMessage = '';

        if (Array.isArray((value)) && validation.hasOwnProperty('type')) {
          switch (validation.type) {
            case 'social':
              if (!fieldErrors.hasOwnProperty(key)) {
                fieldErrors[key] = {};
              }
              const socialErrors: any = fieldErrors[key];
              value.filter(item => item.status !== crudStatus.DELETED).forEach((item: any, index: number) => {
                if (item.profileUrl.length === 0) {
                  isValid = false;
                  socialErrors[index] = { ...socialErrors[index], profileUrl: 'The link cannot be empty' };
                }
                if (item.social.socialCode.length === 0) {
                  isValid = false;
                  socialErrors[index] = { ...socialErrors[index], socialCode: 'The name cannot be empty' };
                }
              });
              break;
            case 'rounds':
              if (!fieldErrors.hasOwnProperty(key)) {
                fieldErrors[key] = {};
              }
              const roundErrors: any = fieldErrors[key];
              value.filter(item => item.status !== crudStatus.DELETED).forEach((item: any, index: number) => {
                if (item.title.length === 0) {
                  isValid = false;
                  roundErrors[index] = { ...roundErrors[index], title: 'The round name cannot be empty' };
                }
                if (typeof item.price === 'undefined' || item.price.length === 0 || parseFloat(item.price) <= 0.0) {
                  isValid = false;
                  roundErrors[index] = { ...roundErrors[index], price: 'The price cannot be empty' };
                }
                if (typeof item.tokenCount === 'undefined' || item.tokenCount.length === 0 || parseFloat(item.tokenCount) <= 0.0) {
                  isValid = false;
                  roundErrors[index] = { ...roundErrors[index], tokenCount: 'The tokens cannot be empty' };
                }
              });
              break;
            case 'wallet':
              if (!fieldErrors.hasOwnProperty(key)) {
                fieldErrors[key] = {};
              }
              const walletErrors: any = fieldErrors[key];
              value.filter(item => item.status !== crudStatus.DELETED).forEach((item: any, index: number) => {
                if (item.link.length === 0) {
                  isValid = false;
                  walletErrors[index] = { ...walletErrors[index], link: 'The link cannot be empty' };
                }
                if (item.currency && item.currency.length === 0) {
                  isValid = false;
                  walletErrors[index] = { ...walletErrors[index], currency: 'The currency cannot be empty' };
                }
              });
              break;
          }
        }

        switch (validation.type) {
          case 'email':
            let email = String(value);
            errorMessage = validation.message || 'Invalid email address';
            fieldIsValid = isValidEmail(email);

            break;
          case 'password':
            let password = String(value);
            errorMessage = validation.message || 'New password must contain at least 6 characters, uppercase and lowercase letters, a number and a special character';
            fieldIsValid = isValidPassword(password);
            break;
          case 'passwordConfirmation':
            errorMessage = validation.message || 'Password and password confirmation do not match';
            fieldIsValid = data.password === value;
            break;
          case 'required':
            errorMessage = validation.message || '';
            fieldIsValid = String(value).trim().length > 0;
            break;
          case 'wallet':
            if (!fieldErrors.hasOwnProperty(key)) {
              fieldErrors[key] = {};
            }
            const walletErrors: any = fieldErrors[key];
            value.filter((item: any) => item.status !== crudStatus.DELETED).forEach((item: any, index: number) => {
              fieldIsValid = item.link.length > 0;
              if (!fieldIsValid) {
                walletErrors[index] = { ...walletErrors[index], link: 'The link cannot be empty' };
              } else {
                fieldIsValid = !item.currency || item.currency.length > 0;
                if (!fieldIsValid) {
                  walletErrors[index] = { ...walletErrors[index], currency: 'The currency cannot be empty' };
                }
              }
            });
            break;
        }

        const pattern = validation?.pattern;
        if (pattern?.value && typeof value === 'string') {
          fieldIsValid = value.match(pattern.value) !== null;
          errorMessage = pattern.message;
        }

        const custom = validation?.custom;
        if (custom?.isValid) {
          fieldIsValid = custom.isValid(value, data);
          errorMessage = custom.message;
        }

        fieldErrors[key] = fieldIsValid ? '' : errorMessage;
        if (isValid && validation.showToast && !fieldIsValid) {
          enqueueSnackbar(errorMessage, { variant: 'error' });
        }

        if (!fieldIsValid) {
          isValid = false;
        }
      }

      setErrors(fieldErrors);
    }

    if (isValid && options?.onSubmit) {
      return options.onSubmit(data);
    } else {
      return false;
    }
  };

  const clearErrors = () => {
    setErrors({});
  };

  return {
    data,
    setData,
    handleValueChange,
    handleTextChange,
    handleRichEditorChange,
    handleCheckboxChange,
    handleSubmit,
    handleDateChange,
    handleSelectChange,

    handleAddListItem,
    handleChangeListItem,
    handleDeleteListItem,

    handleAddSocial,
    handleDeleteSocial,
    handleSocialChange,
    handleSocialTitleChange,

    handleAddRound,
    handleDeleteRound,
    handleRoundChange,

    handleAddWallet,
    handleWalletCurrencyChange,
    handleWalletLinkChange,
    handleDeleteWallet,

    errors,
    clearErrors
  };
};
