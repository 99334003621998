import React, { Context, useCallback, useEffect, useRef, useState } from 'react';
import { LoginResult, UserRole, User, UserMode } from '@store/generated-models';
import { tokenStorage } from '@coreComponents/system/TokenStorage';
import appConfig from '@src/config';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { getIcon } from '@coreComponents/base/CustomIcon/CustomIcon';
import { GET_ME, GET_MENU_ITEMS } from '@store/queries/users';
import { numberLimitFormat } from '../helpers/utils';
import { useSnackbar } from 'notistack';
import { TimeoutDialog } from '@coreComponents/base/TimeoutDialog/TimeoutDialog';
import { LOGOUT } from '@store/mutations/users';

/*TODO: use user from backend*/
const clearUser: any = {
  userId: '',
  email: '',
  roles: [],
  is2faEnabled: false
};

type IconsMapping = {
  countries: any
  users: any
  my_account: any
}

const iconsMapping: IconsMapping = {
  countries: getIcon('planet'),
  users: getIcon('users', 'light'),
  my_account: getIcon('user', 'light')
};

export interface AuthContextProps {
  isInitialized: boolean;
  user: User | null;
  login: (loginResult: LoginResult) => void
  logout: () => void,
  clearSession: () => void,
  showMessage: (message: string) => void;
  isNewNotification: boolean;
  setIsNewNotification: (flag: boolean) => void;
  notificationCounter: number;
  incNotificationCounter: () => void;
  updateUser: (param: any) => void;
  menuItems: any[];
  isUser: boolean,
  isAdmin: boolean,
  isFundOwner: boolean,
  isContractSigner: boolean,
  isVerified: boolean,
  blockpassWidget: any,
  setBlockpassWidget: any
}

export const AuthContext: Context<AuthContextProps> = React.createContext(null);

export const AuthProvider: React.FC = ({ children }) => {

  const { enqueueSnackbar } = useSnackbar();
  const gqlClient = useApolloClient();

  const {
    error: userError,
    data: userData,
    refetch: userRefetch
  } = useQuery<{ me: User }>(GET_ME);

  const [logoutMutation, {
    error: logoutMutationError,
    data: logoutMutationData
  }] = useMutation<{ logout: boolean }>(LOGOUT);

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isNewNotification, setIsNewNotification] = useState<boolean>(false);
  const [notificationCounter, setNotificationCounter] = useState(0);

  const [user, setUser] = useState<User>(clearUser);

  const userRef = useRef<User>(null); // need that for using in click event listeners and timeouts, otherwise they won't access the current value

  const [menuItems, setMenuItems] = useState([]);
  const navigate = useNavigate();

  const userId = user?.userId;
  const { data: menuItemsData, loading: loadingMenuItems, refetch: refetchMenuItems } = useQuery(GET_MENU_ITEMS, {
    fetchPolicy: 'network-only',
    skip: !userId
  });

  // const { data: notificationsData } = useSubscription(
  //   USER_NOTIFICATIONS_SUBSCRIPTION, {
  //     variables: { subscriptionEventIds: ['my-notifications-' + userId] },
  //     skip: !userId
  //   }
  // );

  useEffect(() => {
    if (menuItemsData) {
      setMenuItems(menuItemsData.menuItems);
      constructMenuItems(menuItemsData.menuItems);
    }
  }, [menuItemsData]);

  useEffect(() => {
    if (userId) {
      refetchMenuItems();
    }
  }, []);


  const constructMenuItems = (menuItems: any[] = []) => {
    const menuItemsMapping: any = {
      'rolling_funds': ['funds', 'fund_requests', 'disclaimer'],
      'users': ['investors', 'fund_investors', 'fund_managers'],
      'my_account': ['profile', 'wallet'],
    };
    const menuLocationsMapping: any = {
      'dashboard': ['/private/dashboard'],
      'investor_requests': ['/private/investor-requests', '/private/request-detailed'],
      'funds': ['/private/funds', '/private/fund-detailed'],
      'fund_requests': ['/private/fund-requests', '/private/fund-request-detailed'],
      'requests': ['/private/requests', '/private/request-detailed'],
      'investors': ['/private/investors', '/private/investor-detailed'],
      'fund_investors': ['/private/investors', '/private/investor-detailed'],
      'fund_managers': ['/private/fund-managers'],
      'profile': ['/private/profile'],
      'wallet': ['/private/wallet'],
      'disclaimer': ['/private/disclaimer'],
    };
    let userMenuItems: any[] = [];

    menuItems.forEach((menuItem: any) => {
      const newItemMenu = {
        ...menuItem,
        link: menuItem.description,
        code: menuItem.code,
        label: menuItem.name,
        badge: numberLimitFormat(menuItem.badgeValue, 99, '99+'),
        icon: iconsMapping[menuItem.code as keyof IconsMapping],
        userValid: false,
        menuItems: [],
        allowedPaths: menuLocationsMapping[menuItem.code],
        isBottom: ['contact_support', 'faq'].includes(menuItem.code)
      };

      let parentMenuItem = userMenuItems.find(item => menuItemsMapping[item.code] && menuItemsMapping[item.code].includes(menuItem.code));

      if (parentMenuItem) {
        parentMenuItem.menuItems.push(newItemMenu);
      } else {
        userMenuItems.push(newItemMenu);
      }
    });

    setMenuItems(userMenuItems);
  };

  const login = useCallback((loginResult: LoginResult) => {
    setUser(loginResult.user);
    tokenStorage.setAccessToken(loginResult.authToken);
    userRef.current = user;

    refetchMenuItems();
    setIsInitialized(true);

    navigate('/private');

  }, []);

  const logout = useCallback(() => {
    logoutMutation().catch().then(() => {
      navigate('/login');
    });
  }, []);

  const clearSession = () => {
    tokenStorage.clearToken();
    setUser(clearUser);
    userRef.current = null;
    gqlClient.clearStore().catch();
  };

  useEffect(() => {
    if (logoutMutationData) {
      clearSession();
    }
  }, [logoutMutationError, logoutMutationData]);

  // region error message helpers
  const showMessage = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' });
  };

  // Current user request effect
  useEffect(() => {
    if (userData) {
      setUser(userData.me);
      userRef.current = userData.me;
      setIsInitialized(true);
      return;
    }
  }, [userData]);

  useEffect(() => {
    if (userError) {
      setIsInitialized(true);
      return;
    }
  }, [userError]);

  const updateNotificationCounter = () => {
    setNotificationCounter(notificationCounter + 1);
  };

  const updateUser = (params: any) => {
    setUser({ ...user, ...params });
  };

  const [blockpassWidget, setBlockpassWidget] = useState(null);
  const kycType = appConfig.bpServiceId;
  const [refId, setRefId] = useState('');

  useEffect(() => {
    if (user?.userId && user.userId !== '') {
      setRefId(user.email);
    }
  }, [user]);

  useEffect(() => {
    if (!kycType) return;
    if (refId) {
      loadBlockpassWidget();
    }
  }, [refId]);

  function loadBlockpassWidget() {
    // @ts-ignore
    const blockpass = new window.BlockpassKYCConnect(
      kycType, // service client_id from the admin console
      { refId: refId } // assign the local user_id of the connected user
    );
    blockpass.startKYCConnect();
    setBlockpassWidget(blockpass);
  }

  return (
    <AuthContext.Provider value={{
      isInitialized: isInitialized,
      user: user,
      login: login,
      logout: logout,
      clearSession: clearSession,
      showMessage: showMessage,
      isNewNotification: isNewNotification,
      setIsNewNotification: setIsNewNotification,
      notificationCounter: notificationCounter,
      incNotificationCounter: updateNotificationCounter,
      updateUser: updateUser,
      menuItems: menuItems,
      isContractSigner: user.roles.findIndex((role: UserRole) => role.code === 'CONTRACT_SIGNER') !== -1,
      isAdmin: user.roles.findIndex((role: UserRole) => role.code === 'ADMIN') !== -1,
      isUser: user.roles.findIndex((role: UserRole) => role.code === 'USER') !== -1,
      isFundOwner: user.roles.findIndex((role: UserRole) => role.code === 'FUND_OWNER') !== -1,
      isVerified: user.mode === UserMode.VerifiedUser && user.kycStatus === 'approved',
      blockpassWidget,
      setBlockpassWidget
    }}>
      {children}
      {userRef.current && <TimeoutDialog onTimeoutEnd={logout}/>}
      <div style={{ display: 'none' }} id="blockpass-kyc-connect"/>
    </AuthContext.Provider>
  );
};
