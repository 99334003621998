import { DatePicker } from '@mui/lab';
import { Grid, IconButton, PopperProps, TextField } from '@mui/material';
import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { FilterOperatorEnum, FilterTypeEnum } from '@store/generated-models';

export const DateFilter = (props: any) => {
  const { onChange, value: filterValue } = props;
  const [valueFrom, valueTo] = filterValue && filterValue.length
    ? [filterValue[0]?.value[0] || null, filterValue[1]?.value[0] || null]
    : [null, null];

  const handleChange = (from: Date, to: Date) => {
    const [fromFilter, toFilter] = [
      { operator: FilterOperatorEnum.Gte, type: FilterTypeEnum.Date, value: [from || null] },
      { operator: FilterOperatorEnum.Lte, type: FilterTypeEnum.Date, value: [to || null] }
    ];
    onChange([from ? fromFilter : null, to ? toFilter : null]);
  };

  const onClearFromDate = () => {
    handleChange(null, valueTo);
  };

  const onClearToDate = () => {
    handleChange(valueFrom, null);
  };

  const DatepickerInput = (params: any) => {
    return <TextField {...params} fullWidth InputProps={{
      ...params.InputProps,
      inputProps: {
        ...params.inputProps,
        style: { padding: '8px' }
      }
    }}/>;
  };

  const popperProps: Partial<PopperProps> = {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      }
    ]
  };

  return <Grid container columnSpacing={1} rowSpacing={0.5}>
    <Grid item xs={12}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ fontSize: '12px', width: '62px' }}>From</span>
        <DatePicker
          value={valueFrom}
          onChange={(newFrom) => handleChange(newFrom, valueTo)}
          renderInput={DatepickerInput}
          PopperProps={popperProps}
          inputFormat="dd.MM.yyyy"
          mask="__.__.____"
        />
        <div className="icon-button-wrapper">
          <IconButton onClick={() => {
            onClearFromDate();
          }}>
            <ClearIcon/>
          </IconButton>
        </div>
      </div>
    </Grid>
    <Grid item xs={12} style={{ fontSize: '12px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ fontSize: '12px', width: '62px' }}>To</span>
        <DatePicker
          value={valueTo}
          onChange={(newTo) => handleChange(valueFrom, newTo)}
          renderInput={DatepickerInput}
          PopperProps={popperProps}
          inputFormat="dd.MM.yyyy"
          mask="__.__.____"
        />
        <div className="icon-button-wrapper">
          <IconButton onClick={() => {
            onClearToDate();
          }}>
            <ClearIcon/>
          </IconButton>
        </div>
      </div>
    </Grid>
  </Grid>;
};
