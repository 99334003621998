import { Button, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { WITHDRAW_ROLLING_FUND_PARTICIPATION_REQUEST } from '@store/mutations/participationRequest';
import { Modal } from '@coreComponents/base/Modal/Modal';

export const useWithdrawModal = (request: any) => {
  const [isOpenWithdraw, setIsOpenWithdraw] = useState(false);
  const [withdrawComment, setWithdrawComment] = useState('');
  const navigate = useNavigate();

  const [withdrawParticipationRequest, { loading: loadingWithdraw }] = useMutation(WITHDRAW_ROLLING_FUND_PARTICIPATION_REQUEST);
  const handleAcceptWithdraw = useCallback(async () => {
    try {
      await withdrawParticipationRequest({
        variables: {
          participationRequestId: request.rollingFundPRequestId,
          comment: withdrawComment
        }
      });
      navigate('/private/fund-requests');
    } catch (e) {
    }
  }, [withdrawComment]);

  const handleWithdrawComment = (event: any) => {
    setWithdrawComment(event.target.value);
  };


  const modal = <Modal
    open={isOpenWithdraw}
    title="Withdraw Request?"
    renderActions={() => <>
      <Button onClick={() => setIsOpenWithdraw(false)} disabled={loadingWithdraw}>No</Button>
      <Button onClick={handleAcceptWithdraw} disabled={loadingWithdraw}>Yes</Button>
    </>}
  >
    <>
      <Typography sx={{ marginBottom: '16px' }}>
        {`Are you sure you want to withdraw the participation request for fund "${request.rollingFund.title}"?`}
      </Typography>
      <TextField label="Comment" name="withdrawComment" variant="outlined" multiline rows={5} fullWidth
                 onChange={handleWithdrawComment} value={withdrawComment}
      />
    </>
  </Modal>;

  return {
    modal,
    setIsOpenWithdraw
  };
};
