import React from 'react';
import { AuthContextProps } from '@coreProviders/AuthProvider';
import { Navigate, Route } from 'react-router-dom';
import { InternalPage } from '@coreComponents/base/InternalPage/InternalPage';
import { ProjectsSwitch } from '@businessComponents/Projects/ProjectsSwitch';
import { ProjectDetailedView } from '@businessComponents/Projects/ProjectDetailedView';
import {
  ParticipationRequestDetailed
} from '@businessComponents/ParticipationRequestDetailed/ParticipationRequestDetailed';
import { Profile } from '@businessComponents/Profile/Profile';
import { Users } from '@businessComponents/Users/Users';
import { UserDetailed } from '@businessComponents/Users/UserDetailed';
import { Disclaimer } from '@businessComponents/Disclaimer/Disclaimer';
import { Investors } from '@businessComponents/Investors/Investors';
import { FundManagers } from '@businessComponents/FundManagers/FundManagers';
import { Funds } from '@businessComponents/Funds/Funds';
import { Requests } from '@businessComponents/Requests/Requests';
import { FundDetailedView } from '@businessComponents/Funds/FundDetailedView';
import { FundsCards } from '@businessComponents/Funds/FundCards';
import { InvestorRequests } from '@businessComponents/InvestorRequests/InvestorRequests';
import { FundChangeRequest } from '@businessComponents/FundParticipation/FundChangeRequest';
import { Subscription } from '@businessComponents/Subscription/Subscription';
import { FundParticipationRequest } from '@businessComponents/FundParticipation/FundParticipationRequest';
import { FundRequests } from '@businessComponents/Funds/FundRequests';
import { FundParticipationRequestDetailed } from '@businessComponents/FundParticipation/ParticipationRequestDetailed';
import { Wallets } from '@businessComponents/Wallets/Wallets';
import { InvestorDetailed } from '@businessComponents/Users/InvestorDetailed';
import { FundOwnerDetailed } from '@businessComponents/Users/FundOwnerDetailed';
import { Dashboard } from '@businessComponents/Dashboard/Dashboard';

const Default: React.FC<any> = (props) => {
  return <Navigate to={props.path}/>;
};

export const privateRoutes = (authContext: AuthContextProps) => {
  let resultRoutes: any[] = [];

  const adminRoutes = [
    <Route path="funds" key="funds" element={<InternalPage title="Rolling funds"><Funds/></InternalPage>}/>,
    <Route path="requests" key="requests" element={<InternalPage title="Requests"><Requests/></InternalPage>}/>,
    <Route path="disclaimer" key="disclaimer" element={<InternalPage title="Disclaimer"><Disclaimer/></InternalPage>}/>,
    <Route path="investors" key="investors" element={<InternalPage title="Investors"><Investors/></InternalPage>}/>,
    <Route path="fund-managers" key="fund_managers"
           element={<InternalPage title="Fund Managers"><FundManagers/></InternalPage>}/>,
    <Route index key="default" element={<Default path="/private/funds"/>}/>
  ];

  const userRoutes = [
    <Route path="funds" key="funds">
      <Route index element={<InternalPage title="Rolling funds"><FundsCards/></InternalPage>}/>
      <Route path="participation-request/:id"
             element={<InternalPage title="Participation request"><FundParticipationRequest/></InternalPage>}/>
    </Route>,
    <Route path="investor-requests" key="investor-requests"
           element={<InternalPage title="Requests"><InvestorRequests/></InternalPage>}/>,
    <Route path="fund-change-request" key="fund-change-request">
      <Route index element={<InternalPage title="Requests"><FundChangeRequest/></InternalPage>}/>
      <Route path=":requestId" element={<InternalPage title="Requests"><FundChangeRequest/></InternalPage>}/>
    </Route>,
    <Route path="subscription" key="subscription" element={
      <InternalPage title="Subscription"><Subscription/></InternalPage>
    }/>,
    <Route index key="default" element={<Default path="/private/funds"/>}/>
  ];

  const fundOwnerRoutes = [
    <Route path="funds" key="funds" element={<InternalPage title="Rolling funds"><Funds/></InternalPage>}/>,
    <Route path="fund-detailed" key="fundDetailed">
      <Route index element={<InternalPage title="Rolling Funds"><FundDetailedView/></InternalPage>}/>
      <Route path=":id" element={<InternalPage title="Rolling Funds"><FundDetailedView/></InternalPage>}/>
    </Route>,
    <Route path="investors" key="investors" element={<InternalPage title="Investors"><Investors/></InternalPage>}/>,
    <Route path="subscription" key="subscription" element={
      <InternalPage title="Subscription"><Subscription/></InternalPage>
    }/>,
    <Route index key="default" element={<Default path="/private/funds"/>}/>
  ];

  const mutualRoutes = [
    <Route path="dashboard" key="dashboard"
           element={<InternalPage title="Dashboard"><Dashboard/></InternalPage>}/>,
    <Route path="fund-requests" key="fund_requests"
           element={<InternalPage title="Requests"><FundRequests/></InternalPage>}/>,
    <Route path="fund-request-detailed" key="fund-request-detailed">
      <Route index element={<InternalPage title="Requests"><FundParticipationRequestDetailed/></InternalPage>}/>
      <Route path=":id" element={<InternalPage title="Requests"><FundParticipationRequestDetailed/></InternalPage>}/>
    </Route>,
    <Route path="fund-detailed" key="fundDetailed">
      <Route index element={<InternalPage title="Rolling Funds"><FundDetailedView/></InternalPage>}/>
      <Route path=":id" element={<InternalPage title="Rolling Funds"><FundDetailedView/></InternalPage>}/>
    </Route>,
    <Route path="request-detailed" key="request-detailed">
      <Route index element={<InternalPage title="Requests"><ParticipationRequestDetailed/></InternalPage>}/>
      <Route path=":id" element={<InternalPage title="Requests"><ParticipationRequestDetailed/></InternalPage>}/>
    </Route>,
    <Route path="user-detailed" key="user-detailed">
      <Route index element={<InternalPage title="User"><UserDetailed/></InternalPage>}/>
      <Route path=":id" element={<InternalPage title="User"><UserDetailed/></InternalPage>}/>
    </Route>,
    <Route path="investor-detailed" key="investor-detailed">
      <Route index element={<InternalPage title="Investors"><InvestorDetailed/></InternalPage>}/>
      <Route path=":id" element={<InternalPage title="Investors"><InvestorDetailed/></InternalPage>}/>
    </Route>,
    <Route path="fund-owner-detailed" key="investor-detailed">
      <Route index element={<InternalPage title="Fund owners"><FundOwnerDetailed/></InternalPage>}/>
      <Route path=":id" element={<InternalPage title="Fund owners"><FundOwnerDetailed/></InternalPage>}/>
    </Route>,
    <Route path="wallet" key="wallet" element={<InternalPage title="Wallets"><Wallets/></InternalPage>}/>,
    <Route path="profile" key="profile" element={<InternalPage title="Profile"><Profile isCurrentUser/></InternalPage>}/>
  ];

  if (authContext.isAdmin) {
    resultRoutes = [...resultRoutes, ...adminRoutes];
  }
  if (authContext.isUser) {
    resultRoutes = [...resultRoutes, ...userRoutes];
  }
  if (authContext.isFundOwner) {
    resultRoutes = [...resultRoutes, ...fundOwnerRoutes];
  }

  return [...resultRoutes, ...mutualRoutes];
};
