import { VALIDATION_PATTERNS } from '@coreConstants/common';

export const concatClassNames = (classConditions: any) => {
  let classNames = '';

  Object.entries(classConditions).forEach((classCondition) => {
    classNames += classCondition[1] ? ` ${classCondition[0]}` : '';
  });

  return classNames;
};

interface IntlOptions {
  locale?: string,
  minimumFractionDigits?: number,
  maximumFractionDigits?: number
  precision?: number
}

export const numberFormat = (value: any, options?: IntlOptions) => {
  let locale = 'en-US',
    minimumFractionDigits = 0,
    maximumFractionDigits = 0;

  if (options) {
    locale = options.locale || locale;
    minimumFractionDigits = options.precision || options.minimumFractionDigits || 0;
    maximumFractionDigits = options.precision || options.maximumFractionDigits || 0;
  }

  const numberFormatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits
  });

  return numberFormatter.format(value);
};

export const numberLimitFormat = (value: any, maxLimit: number, maxLimitPlaceholder: any) => {
  let formattedValue = '';

  if (value > maxLimit) {
    formattedValue = maxLimitPlaceholder;
  } else if (value && value > 0) {
    formattedValue = numberFormat(value);
  }

  return formattedValue;
};

export const insertText = (text: string, start: number, delCount: number, value: string) => {
  return text.slice(0, start) + value + text.slice(start + Math.abs(delCount));
};

export function removeFromArrayByValue<T>(arr: Array<T>, value: T): Array<T> {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export function removeFromArrayByIndex<T>(arr: Array<T>, index: number): Array<T> {
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export const dateFormat = (value: string | Date) => {
  const date = value instanceof Date ? value : new Date(value);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const dayString = day < 10 ? `0${day}` : day;
  const monthString = month < 10 ? `0${month}` : month;

  return value ? `${dayString}.${monthString}.${date.getFullYear()}` : '';
};


export const checkNonZeroValue = (value: any) => {
  return typeof value !== 'undefined' && value > 0;
};

export const checkStringNonZero = (value: any) => {
  return typeof value !== 'undefined' && value !== '';
};

export const isValidPassword = (password: string) => {
  const regex = VALIDATION_PATTERNS.PASSWORD;

  regex.lastIndex = 0;
  return regex.test(
    password
  );
};

export const isValidEmail = (email: string) => {
  const regex = VALIDATION_PATTERNS.EMAIL;

  regex.lastIndex = 0;
  return regex.test(
    email
  );
};
