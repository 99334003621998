export enum crudStatus {
  CREATED,
  UPDATED,
  DELETED
}

export const VALIDATION_PATTERNS = {
  DEFAULT: /^[a-zA-Zа-яА-ЯёЁ-]{3,15}$/,
  EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  LOGIN: /^[a-z0-9_-]{3,15}$/,
  PHONE: /^\+?(\d{1})\(?(\d{3})\)?[-|\s]?(\d{3})[-|\s]?(\d{2})[-|\s]?(\d{2})$/,
  PASSWORD: /(?=.*[0-9])(?=.*[!@#$%^&*()_+}{":;?./><,-])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*()_+}{":;?./><,-]{6,}/g
};

export const SESSION_INACTIVITY_TIMEOUT = 1800; // seconds before auto-logout kicks in
export const SESSION_TIMEOUT_COUNTDOWN = 30; // seconds to show the dialog before logging out
