import { gql } from '@apollo/client';

export const GET_FUNDS = gql`
  query GetFunds($filterEx: [Filter], $orderBy: [OrderBy!]) {
    getRollingFunds(filterEx: $filterEx, orderBy: $orderBy) {
      count
      list {
        rollingFundId
        avatar
        title
        description
        introductionLink
        contributionWallet
        contributionWalletShort
        contributionWalletUrl
        externalTemplateId
        status
        approvalStatus
        managementFee
        minAmount
        minPeriod
        minPeriodType
        startDate
        portfolio
        terms
      }
    }
  }
`;

export const GET_FUND_BY_ID = gql`
  query GetFundById($rollingFundId: String) {
    getRollingFundById(rollingFundId: $rollingFundId) {
      rollingFundId
      avatar
      title
      description
      introductionLink
      contributionWallet
      contributionWalletShort
      contributionWalletUrl
      externalTemplateId
      status
      approvalStatus
      managementFee
      minAmount
      minPeriod
      minPeriodType
      startDate
      portfolio
      terms
    }
  }
`;

export const GET_DISCLAIMER = gql`
    query GetRollingFundsDisclaimer {
      getRollingFundsDisclaimer
    }
`
