import React, { useContext, useEffect, useState } from 'react';
import { MutationForgotPasswordArgs } from '@store/generated-models';
import { AuthContext } from '@coreProviders/AuthProvider';
import TextField from '@mui/material/TextField';
import { useMutation } from '@apollo/client';
import { Button, Grid } from '@mui/material';
import { AuthContentHeader } from '@businessComponents/Authentication/AuthContentHeader';
import { FORGOT_PASSWORD } from '@store/mutations/users';
import { useForm } from '@coreHooks/useForm';

export const ForgotPassword: React.FC<any> = () => {
  const [dialogState, setDialogState] = useState('input');
  const authContext = useContext(AuthContext);

  type ForgotPasswordForm = {
    email: string;
  }
  const initialValues: ForgotPasswordForm = {
    email: ''
  };
  const validationConfig = {
    email: {
      type: 'email',
      showToast: true
    }
  };

  const [forgotPassword, {
    loading: forgotPasswordLoading,
    error: forgotPasswordError,
    data: forgotPasswordData
  }] = useMutation<{ forgotPassword: boolean }, MutationForgotPasswordArgs>(FORGOT_PASSWORD);

  useEffect(() => {
    if (forgotPasswordData) {
      if (forgotPasswordData.forgotPassword) {
        setDialogState('success');
      } else {
        authContext.showMessage(
          'We couldn\'t send you the password resetting link. Please check the entered email or try again later');
      }
    } else {
      if (forgotPasswordError) {
        authContext.showMessage(
          'We couldn\'t send you the password resetting link. Please check the entered email or try again later');
      }
    }
  }, [forgotPasswordError, forgotPasswordData]); // eslint-disable-line

  const onSubmit = async () => {
    forgotPassword({
      variables: {
        email: data.email
      }
    });
  };
  const {
    data,
    errors,
    handleTextChange,
    handleSubmit
  } = useForm<ForgotPasswordForm>({ validationConfig, initialValues, onSubmit });

  let dialogContent: JSX.Element = null;

  const ForgotPasswordInProgress = <div>Processing...</div>;
  const ForgotPasswordForm = <>
    <AuthContentHeader title="Forgot Password?" subtitle="Don’t worry, we’ll send you reset instruction"/>

    <form autoComplete="new-password">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            name="email"
            variant="outlined"
            error={errors.email?.length > 0}
            fullWidth
            label="Enter your Email"
            autoComplete="email"
            value={data.email}
            onChange={handleTextChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size={'large'}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </form>
  </>;
  const ForgotPasswordSuccess = <>
    <AuthContentHeader
      title="Check your inbox"
      subtitle="Please follow the instructions in the message that we have sent to your Email"
      buttonConfig={{ title: 'Ok' }}
      isWithoutMargin
    />
  </>;

  if (forgotPasswordLoading) {
    dialogContent = ForgotPasswordInProgress;
  } else {
    if (dialogState === 'input') {
      dialogContent = ForgotPasswordForm;
    } else if (dialogState === 'success') {
      dialogContent = ForgotPasswordSuccess;
    }
  }

  return (
    <>
      {dialogContent}
    </>
  );
};
