import React, { useContext, useEffect, useState } from 'react';
import { MutationSetPasswordArgs } from '@store/generated-models';
import { AuthContext } from '@coreProviders/AuthProvider';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { Password } from '@coreComponents/base/Password';
import { AuthContentHeader } from '@businessComponents/Authentication/AuthContentHeader';
import { SET_PASSWORD } from '@store/mutations/users';
import { useForm } from '@coreHooks/useForm';

export const SetPassword: React.FC<any> = () => {
  const authContext = useContext(AuthContext);
  const { token } = useParams();
  const [dialogState, setDialogState] = useState<string>('input');

  type SetPasswordForm = {
    password: string;
    passwordConfirmation: string;
  }
  const initialValues: SetPasswordForm = {
    password: '',
    passwordConfirmation: ''
  };
  const validationConfig = {
    password: {
      type: 'password',
      showToast: true
    },
    passwordConfirmation: {
      type: 'passwordConfirmation',
      showToast: true
    }
  };

  const [
    callSetPassword, {
      loading: setPasswordLoading,
      error: setPasswordError,
      data: setPasswordData
    }] = useMutation<{ setPassword: boolean }, MutationSetPasswordArgs>(SET_PASSWORD);

  useEffect(() => {
    if (setPasswordData) {
      if (setPasswordData.setPassword) {
        setDialogState('success');
      } else {
        authContext.showMessage(
          'Unknown error. Please try again later.');
      }
    } else {
      if (setPasswordError) {
        const errorCode = setPasswordError.graphQLErrors[0].extensions.code;

        if (errorCode === 'auth.password_invalid') {
          authContext.showMessage('Password should be at least 6 symbols long and contain at least one letter (lower- and uppercase), number and special symbol');
        } else {
          authContext.showMessage(
            'Unknown error. Please try again later.');
        }
      }
    }
  }, [setPasswordError, setPasswordData]); // eslint-disable-line

  const onSubmit = async () => {
    callSetPassword({
      variables: {
        password: data.password,
        token: token
      }
    });
  };
  const {
    data,
    handleTextChange,
    handleSubmit
  } = useForm<SetPasswordForm>({ validationConfig, initialValues, onSubmit });

  let dialogContent: JSX.Element = null;

  const SetPasswordInProgress = <div>Setting new password...</div>;
  const SetPasswordFormBlock = <>
    <AuthContentHeader
      title="Enter new Password"
    />
    <form autoComplete="new-password">
      <Password
        id="password"
        name="password"
        autoComplete="password"
        fullWidth
        label="Password"
        value={data.password}
        onChange={handleTextChange}
      />
      <Password
        id="password-confirmation"
        name="passwordConfirmation"
        autoComplete="new-password"
        fullWidth
        label="Password confirmation"
        value={data.passwordConfirmation}
        onChange={handleTextChange}
        style={{ margin: '24px 0 24px 0' }}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        size={'large'}
        onClick={handleSubmit}
      >Save and Sign in</Button>
    </form>
  </>;
  const SetPasswordSuccess = <>
    <AuthContentHeader
      title="Check your inbox"
      subtitle="Please follow the instructions in the message that we have sent to your Email"
      buttonConfig={{ title: 'Back to Sign In' }}
    />
  </>;

  if (token) {
    if (setPasswordLoading) {
      dialogContent = SetPasswordInProgress;
    } else {
      if (dialogState === 'input') {
        dialogContent = SetPasswordFormBlock;
      } else if (dialogState === 'success') {
        if (setPasswordData) {
          dialogContent = SetPasswordSuccess;
        }
      }
    }
  }

  return dialogContent;
};
