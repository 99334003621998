import { FC, ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogProps, CircularProgress, PaperProps
} from '@mui/material';

type OwnProps = {
  title?: string;
  open: boolean;
  isLoading?: boolean;
  isLarge?: boolean;
  children: ReactNode | string;
  onAccept?: () => void;
  onDecline?: () => void;
  renderActions?: () => JSX.Element;
  paperProps?: PaperProps
} & DialogProps;

type ModalProps = FC<OwnProps>

export const Modal: ModalProps = (
  {
    children,
    open = false,
    isLoading = false,
    isLarge = false,
    title = '',
    onAccept = () => {
    },
    onDecline = () => {
    },
    renderActions,
    paperProps,
    ...DialogProps
  }) => {

  return (
    <Dialog
      PaperProps={{
        sx: {
          maxWidth: isLarge ? '760px' : '450px'
        },
        ...paperProps
      }}
      open={open}
      onClose={onDecline}
      {...DialogProps}
    >
      {
        isLoading && <div className="preloader">
          <CircularProgress color="primary"/>
        </div>
      }
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent sx={{display: 'flex',flexFlow: 'column', padding: '20px'}}>
        {children}
      </DialogContent>
      <DialogActions>
        {typeof renderActions === 'undefined' ?
          <>
            <Button onClick={onDecline}>No</Button>
            <Button onClick={onAccept}>Yes</Button>
          </>
          : renderActions()
        }
      </DialogActions>
    </Dialog>
  );
};
