import React from 'react';
import { concatClassNames, dateFormat, numberFormat } from '@coreHelpers/utils';
import { getIcon } from '@coreComponents/base/CustomIcon/CustomIcon';
import { Grid, Typography } from '@mui/material';
import './Transaction.scss';

const transactionStatuses = {
  pRequestCreate: 'Requested',
  pRequestWithdraw: 'Withdrawn',
  pRequestApprove: 'Approved',
  pRequestDecline: 'Declined',
  pRequestWithdrawApproval: 'Approval withdrawn',
  pRequestSignSaft: 'Contract signed',
  pRequestCountersignSaft: 'Contract countersigned',
  pRequestWithdrawSaft: 'Contract cancelled',
  pRequestNovation: 'Novation',
  pRequestSignNovation: 'Novation signed',
  pRequestCountersignNovation: 'Novation countersigned',
  pRequestWithdrawNovation: 'Novation cancelled'
};

const iconMapping = {
  pRequestCreate: getIcon('warning'),
  pRequestWithdraw: getIcon('error'),
  pRequestApprove: getIcon('checkboxChecked'),
  pRequestDecline: getIcon('error'),
  pRequestWithdrawApproval: getIcon('error'),
  pRequestSignSaft: getIcon('checkboxChecked'),
  pRequestCountersignSaft: getIcon('checkboxChecked'),
  pRequestWithdrawSaft: getIcon('stop'),
  pRequestNovation: getIcon('warning', 'blue'),
  pRequestSignNovation: getIcon('checkboxChecked'),
  pRequestCountersignNovation: getIcon('checkboxChecked'),
  pRequestWithdrawNovation: getIcon('stop')
};

export const Transaction = (props: any) => {
  const { request, data } = props;
  const classNames = concatClassNames({});
  const isRollingFundRequest = request.__typename === 'RollingFundPRequest';

  return (
    <div className={`transaction ${classNames}`}>
      <div className="transaction__content">
        <div className="transaction__header">
          <div className="transaction__icon">
            {iconMapping[data.status as keyof typeof iconMapping]}
          </div>
          <div className="transaction__title">
            {transactionStatuses[data.status as keyof typeof transactionStatuses]}
          </div>
        </div>
        <div className="transaction__data">
          <div className="transaction__date">
            {dateFormat(data.date)}
          </div>
          {
            data.allocations && data.allocations.length > 0 && data.allocations.map((allocation: any, index: number) =>
              allocation.tokenCount > 0.0 ? <React.Fragment key={index}>
                <div
                  className="transaction__round">
                  {`${allocation.projectRound.title} ${allocation.projectRound.price ? '($' + allocation.projectRound.price + ')' : ''}`}
                </div>
                {
                  <div className="transaction__amount">
                    {numberFormat(allocation.tokenCount)} tokens
                  </div>
                }
              </React.Fragment> : '')
          }
          {
            data.comment && <div className="transaction__comment">
              {data.comment}
            </div>
          }
          {
            isRollingFundRequest && data.info && data.info.terms && <Grid container rowSpacing={1}>
              {
                data.info.terms.startDate && <Grid item xs={12}>
                  <Typography style={{ fontSize: '12px', fontWeight: 600, color: '#BBBDC0' }}>SUBSCRIPTION START DATE</Typography>
                  <Typography
                    style={{ fontSize: '12px', fontWeight: 400 }}>{dateFormat(data.info.terms.startDate)}</Typography>
                </Grid>
              }
              {
                data.info.terms.investmentAmount && <Grid item xs={12}>
                  <Typography style={{ fontSize: '12px', fontWeight: 600, color: '#BBBDC0' }}>SUBSCRIPTION AMOUNT</Typography>
                  <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
                    {`${numberFormat(data.info.terms.investmentAmount)} ${data.info.terms.investmentCurrency}`}
                  </Typography>
                </Grid>
              }
              {
                data.info.terms.period && <Grid item xs={12}>
                  <Typography style={{ fontSize: '12px', fontWeight: 600, color: '#BBBDC0' }}>SUBSCRIPTION PERIOD</Typography>
                </Grid>
              }
            </Grid>
          }
        </div>
      </div>
    </div>
  );
};
