import React, { useContext, useState } from 'react';
import { LoginResult, MutationSignupArgs, UserMode } from '@store/generated-models';
import { AuthContext } from '@coreProviders/AuthProvider';
import { Button, Checkbox, FormControlLabel, Grid, TextField, Tooltip } from '@mui/material';
import { Password } from '@coreComponents/base/Password';
import { useMutation } from '@apollo/client';
import { SIGN_UP } from '@store/mutations/users';
import { ExternalLink } from '@coreComponents/base/TextLink/TextLink';
import { useForm } from '@coreHooks/useForm';
import { AuthContentHeader } from '@businessComponents/Authentication/AuthContentHeader';
import { Text, TEXT_COLOR, TEXT_VARIANT } from '@coreComponents/base/Text/Text';


export const ProvideInfo: React.FC<any> = (props) => {
  const { accountType, setAuthInfoBlock } = props;
  const [requestIsProcessing, setRequestIsProcessing] = useState(false);

  const authContext = useContext(AuthContext);

  const [signup] = useMutation<{ signup: LoginResult }, MutationSignupArgs>(SIGN_UP);

  const onSubmit = () => {
    signup({
      variables: {
        email: data.email,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        termsOfUse: data.termsOfUse,
        mode: UserMode.StandardUser,
        accountType: accountType
      }
    })
      .then((res: any) => {
        setAuthInfoBlock(
          <AuthContentHeader title="Check your inbox"
                             subtitle="Please follow the instructions in the message that we have sent to your Email"
                             buttonConfig={{ title: 'Ok' }}
          />
        );
      })
      .catch((error: any) => {
        const errorCode = error.graphQLErrors[0]?.extensions.code;
        if (errorCode === 'auth.password_invalid') {
          authContext.showMessage(
            'Password should be at least 6 symbols long and contain at least one letter (lower- and uppercase), number and special symbol'
          );
        } else if (errorCode === 'auth.user_already_exists') {
          authContext.showMessage('User already exists');
        } else if (errorCode === 'auth.referral_code_invalid') {
          authContext.showMessage('Referral code is invalid');
        } else {
          authContext.showMessage('Unknown error occurred');
        }
      });
  };

  type ProvideInfoForm = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    termsOfUse: boolean;
  }
  const initialValues: ProvideInfoForm = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    termsOfUse: false
  };
  const validationConfig = {
    firstName: {
      type: 'required',
      message: 'First name cannot be empty'
    },
    lastName: {
      type: 'required',
      message: 'Last name cannot be empty'
    },
    email: {
      type: 'email',
      showToast: true
    },
    password: {
      type: 'password',
      showToast: true
    },
    passwordConfirmation: {
      type: 'passwordConfirmation',
      showToast: true
    },
    termsOfUse: {
      type: 'required',
      message: 'You need to agree with the terms and conditions',
      showToast: true
    }
  };

  const {
    data,
    errors,
    handleTextChange,
    handleCheckboxChange,
    handleSubmit
  } = useForm<ProvideInfoForm>({ validationConfig, initialValues, onSubmit });

  return (
    <>
      <div style={{ marginBottom: '32px' }}>
        <Text variant={TEXT_VARIANT.h2}>
          Create your account
        </Text>
      </div>
      <form autoComplete="new-password">
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="First Name"
              name="firstName"
              value={data.firstName}
              disabled={requestIsProcessing}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Last Name"
              name="lastName"
              value={data.lastName}
              disabled={requestIsProcessing}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              autoComplete="email"
              label="Email"
              name="email"
              error={errors.email?.length > 0}
              value={data.email}
              disabled={requestIsProcessing}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Tooltip
              placement="top-start"
              enterDelay={200}
              leaveDelay={200}
              title={
                <div>
                  <div>Passwords must be at least 6 characters</div>
                  <div>Passwords must have at least one non alphanumeric character</div>
                  <div>Passwords must have at least one lowercase('a'- 'z')</div>
                  <div>Passwords must have at least one uppercase('A' - 'Z')</div>
                </div>
              }
            >
              <div>
                <Password
                  fullWidth
                  label="Password"
                  autoComplete="new-password"
                  name="password"
                  value={data.password}
                  error={errors.password?.length > 0}
                  disabled={requestIsProcessing}
                  onChange={handleTextChange}
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Password
              fullWidth
              label="Password confirmation"
              autoComplete="new-password"
              name="passwordConfirmation"
              value={data.passwordConfirmation}
              error={errors.passwordConfirmation?.length > 0}
              disabled={requestIsProcessing}
              onChange={handleTextChange}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="termsOfUse"
                  color="primary"
                  checked={data.termsOfUse}
                  onChange={handleCheckboxChange}
                />
              }
              label={
                <Text color={TEXT_COLOR.secondary} variant={TEXT_VARIANT['small-regular']}>
                  I agree to the{' '}
                  <ExternalLink text="Terms and conditions"
                                to={`${process.env.REACT_APP_URL_TERMS}`}
                                classNames="text--small-medium"
                  />
                </Text>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              color="primary"
              size={'large'}
              disabled={
                requestIsProcessing ||
                !data.email ||
                !data.password ||
                !data.passwordConfirmation ||
                !data.firstName ||
                !data.lastName ||
                !data.termsOfUse
              }
            >
              {!requestIsProcessing ? `Sign up` : 'Signing up...'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
