import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { AccountBalance } from '@businessComponents/Dashboard/AccountBalance';
import { TransactionTable } from './TransactionTable';
import { BrowseFunds } from './BrowseFunds';
import { Grid } from '@mui/material';

export const Dashboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const screenContext = useContext(ScreenContext);
  const { isPhone, isTablet, isMobile } = screenContext;


  return <Grid container spacing={8}>
    <Grid item xs={3}>
      <AccountBalance/>
    </Grid>
    <Grid item xs={3}>
      <BrowseFunds/>
    </Grid>
    <Grid item xs={12}>
      <TransactionTable/>
    </Grid>
  </Grid>;
};
