import { Context, FC, createContext, ReactNode } from 'react';
import { useMediaQuery } from "@mui/material";

type OwnProps = {
  children: ReactNode;
}

type ScreenContextProps = {
  isTablet: boolean;
  isPhone: boolean;
  isMobile: boolean;
}

export const ScreenContext: Context<ScreenContextProps> = createContext(null);

export const ScreenProvider: FC<OwnProps> = ({children}) => {
  const isTablet = useMediaQuery('(max-width:1200px)');
  const isPhone = useMediaQuery('(max-width:600px)');
  const contextValue = {
    isTablet: isTablet && !isPhone,
    isPhone,
    isMobile: isTablet || isPhone
  }

  return (
    <ScreenContext.Provider value={contextValue}>
      {children}
    </ScreenContext.Provider>
  );
}
