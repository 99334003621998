import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { ScreenContext } from '@coreProviders/ScreenProvider';

export const PageToolbar = (props: any) => {
  const { leftSide, rightSide } = props;
  const { isMobile } = useContext(ScreenContext);
  const ContainerStyles = {
    display: 'flex',
    position: isMobile ? 'fixed' : 'inherit',
    minHeight: '37px',
    width: isMobile ? 'calc(100vw - 32px)' : '100%',
    marginBottom: '24px'
  };

  return (
    <Box sx={ContainerStyles}>
      <Box sx={{ display: 'flex', flex: 1 }}>
        {leftSide}
      </Box>
      <Box style={{ marginLeft: 'auto' }}>
        {rightSide}
      </Box>
    </Box>
  );
};
