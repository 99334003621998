import { useQuery } from '@apollo/client';
import HelloSign from 'hellosign-embedded';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { AuthContext } from '@coreProviders/AuthProvider';
import { GET_DOCUMENT_FINAL_COPY_URL } from '@store/queries/requests';
import { GET_DOCUMENT_SIGN_URL } from '@store/queries/requests';
import config from '@src/config';
import { getDocumentsViewData } from '@businessComponents/FundParticipation/DocumentsViewData';

// BEGIN: Issue with 'getDocumentsViewData'
/* Todo 'getDocumentsViewData': This functionality is also needed in RequestProgress.tsx module since
 *  there is a place for user to see the current document state and take corresponding actions (sign)
 *  as well. We need to generalise this functionality and be able to use it in multiple places.
 *  Moreover, closing the popup which was opened from RequestProgress.tsx should also trigger
 *  refetch in CurrentSAFT section.
 */
// END: Issue with 'getDocumentsViewData'
export const useDocumentSign = (request: any, refetch: any) => {
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [isRefetching, setIsRefetching] = useState(false);
  const [fundsReceived, setFundsReceived] = useState(false);

  const { refetch: refetchDocumentSign } = useQuery(GET_DOCUMENT_SIGN_URL, {
    fetchPolicy: 'network-only',
    skip: true
  });

  const { refetch: refetchDocumentCopy } = useQuery(GET_DOCUMENT_FINAL_COPY_URL, {
    fetchPolicy: 'network-only',
    skip: true
  });

  const openDocument = (signUrl: string) => {
    const client = new HelloSign();
    client.on('close', () => {
      setIsRefetching(true);
      setTimeout(() => {
        refetch().then(() => {
          setIsRefetching(false);
        });
      }, 3000);
    });

    if (signUrl) {
      client.open(signUrl, {
        testMode: config.hsTestMode == 'true',
        skipDomainVerification: config.hsSkipDomainVerification == 'true',
        clientId: config.hsClientId
      });
    }
  };

  const handleSignDocument = (documentId: any, checkFunds: boolean = false) => {
    refetchDocumentSign({ documentId }).then((result) => {
      if (!checkFunds || fundsReceived) {
        openDocument(result.data.getDocumentSignUrl);
      } else {
        enqueueSnackbar('The Funds are not received, please check the transaction first', { ...{ variant: 'info' } });
      }
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { ...{ variant: 'error' } });
    });
  };

  const handleDownloadDocument = (documentId: any) => {
    refetchDocumentCopy({ documentId }).then((result) => {
      window.open(result.data.getDocumentFinalCopyUrl, '_blank');
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { ...{ variant: 'error' } });
    });
  };

  return {
    isRefetching,
    fundsReceived,
    setFundsReceived,
    handleSignDocument,
    handleDownloadDocument,
    documentsViewData: request?.contract ? getDocumentsViewData(
      request,
      authContext,
      handleSignDocument,
      handleDownloadDocument
    ) : {}
  };
};
