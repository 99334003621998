import { Avatar, Box, Button, Chip } from '@mui/material';
import avatarDefault from '@assets/icons/avatar-default.svg';
import React, { useContext } from 'react';
import { AuthContext } from '@coreProviders/AuthProvider';
import { getIcon } from '@coreComponents/base/CustomIcon/CustomIcon';
import { Text, TEXT_COLOR, TEXT_VARIANT } from '@coreComponents/base/Text/Text';

export const AccountInfo = () => {
  const authContext = useContext(AuthContext);

  const onLogoutClick = (e: any) => {
    e.preventDefault();
    authContext.logout();
  };

  let name = authContext.user.email;
  if (authContext.user.lastName && authContext.user.lastName) {
    name = `${authContext.user.firstName} ${authContext.user.lastName}`;
  }

  return (
    <Box sx={{
      marginLeft: 'auto',
      alignItems: 'center',
      fontSize: '12px',
      display: 'flex'
    }}>
      <Avatar sx={{ width: 32, height: 32, marginRight: '8px' }}
              src={''}
      >
        <img src={avatarDefault}/>
      </Avatar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: 'auto'
        }}
      >
        <Text color={TEXT_COLOR.light} variant={TEXT_VARIANT['body-regular']}>{name}</Text>
      </Box>
      {
        <Button onClick={onLogoutClick} style={{ minWidth: 'auto' }}>
          {
            getIcon('sign_out', 'light')
          }
        </Button>
      }
    </Box>
  );
};
