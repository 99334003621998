export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Byte: any;
  Date: any;
  DateTime: any;
  NonEmptyString: any;
  Upload: any;
  Void: any;
};

export enum AccountType {
  FundOwner = 'FundOwner',
  User = 'User'
}

export type ApiKey = {
  __typename?: 'ApiKey';
  apiKeyId: Scalars['ID'];
  created: Scalars['DateTime'];
  disabled?: Maybe<Scalars['DateTime']>;
};

export type ApiKeyListResult = {
  __typename?: 'ApiKeyListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<ApiKey>>;
};

export type ApiKeySecret = {
  __typename?: 'ApiKeySecret';
  apiKeyId: Scalars['ID'];
  created: Scalars['DateTime'];
  disabled?: Maybe<Scalars['DateTime']>;
  secret: Scalars['String'];
  userId: Scalars['String'];
};

export enum AssetsEnum {
  High = 'high',
  Low = 'low',
  Medium = 'medium'
}

export type Contract = {
  __typename?: 'Contract';
  agreementDate?: Maybe<Scalars['String']>;
  buyerAgreeTerms?: Maybe<Scalars['Boolean']>;
  buyerDesignation1?: Maybe<Scalars['String']>;
  buyerDesignation2?: Maybe<Scalars['String']>;
  buyerFraudReported?: Maybe<Scalars['Boolean']>;
  buyerId: Scalars['String'];
  buyerPep?: Maybe<Scalars['Boolean']>;
  buyerSignUrl?: Maybe<Scalars['String']>;
  buyerSignatureId: Scalars['String'];
  buyerSourceOfFunds?: Maybe<Scalars['String']>;
  buyerWalletAddress?: Maybe<Scalars['String']>;
  buyerWalletShort?: Maybe<Scalars['String']>;
  buyerWalletUrl?: Maybe<Scalars['String']>;
  contractId: Scalars['ID'];
  created?: Maybe<Scalars['Date']>;
  finalCopyUrl?: Maybe<Scalars['String']>;
  fundsReceived?: Maybe<Scalars['Boolean']>;
  isComplete?: Maybe<Scalars['Boolean']>;
  paymentModeUsdc?: Maybe<Scalars['Boolean']>;
  paymentModeUsdt?: Maybe<Scalars['Boolean']>;
  sellerId: Scalars['String'];
  sellerSignUrl?: Maybe<Scalars['String']>;
  sellerSignatureId: Scalars['String'];
  signatureRequestId: Scalars['String'];
  signedByBuyer?: Maybe<Scalars['Date']>;
  signedBySeller?: Maybe<Scalars['Date']>;
  transactionId?: Maybe<Scalars['String']>;
  transactionIdShort?: Maybe<Scalars['String']>;
  transactionUrl?: Maybe<Scalars['String']>;
};

export type ContractListResult = {
  __typename?: 'ContractListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Contract>>;
};

export enum CountryCodeType {
  Code2 = 'code2',
  Code3 = 'code3'
}

export type Crop = {
  height?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Float']>;
  x?: InputMaybe<Scalars['Float']>;
  y?: InputMaybe<Scalars['Float']>;
};

export type CryptoAsset = {
  __typename?: 'CryptoAsset';
  contractAddress?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Date']>;
  cryptoAssetId: Scalars['String'];
  deleted?: Maybe<Scalars['Date']>;
  isScam?: Maybe<Scalars['Boolean']>;
  logoUrl?: Maybe<Scalars['String']>;
  statistics?: Maybe<CryptoAssetStatistics>;
  symbol?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  userData?: Maybe<CryptoAssetUserData>;
};

export type CryptoAssetEventNotificationData = {
  __typename?: 'CryptoAssetEventNotificationData';
  subscriptionEventId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Int']>;
};

export type CryptoAssetMutationInput = {
  __typename?: 'CryptoAssetMutationInput';
  cryptoAssetId: Scalars['String'];
  isScam?: Maybe<Scalars['Boolean']>;
};

export type CryptoAssetStatistics = {
  __typename?: 'CryptoAssetStatistics';
  lastEventDate?: Maybe<Scalars['Date']>;
  numberOfEvents?: Maybe<Scalars['Int']>;
  numberOfFollowers?: Maybe<Scalars['Int']>;
  numberOfNewEvents?: Maybe<Scalars['Int']>;
};

export type CryptoAssetUserData = {
  __typename?: 'CryptoAssetUserData';
  isSubscribed?: Maybe<Scalars['Boolean']>;
};

export type CryptoEvent = {
  __typename?: 'CryptoEvent';
  description?: Maybe<Scalars['String']>;
  isFake?: Maybe<Scalars['Boolean']>;
  pubDate?: Maybe<Scalars['Date']>;
  source?: Maybe<Social>;
  title?: Maybe<Scalars['String']>;
};

export type CryptoEventData = {
  __typename?: 'CryptoEventData';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<CryptoEvent>>;
};

export type CryptoEventSource = {
  __typename?: 'CryptoEventSource';
  code?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Date']>;
  cryptoEventSourceId: Scalars['String'];
  deleted?: Maybe<Scalars['Date']>;
  link?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CryptoEventSourceInput = {
  code: Scalars['String'];
  link: Scalars['String'];
  title: Scalars['NonEmptyString'];
};

export type CryptoEventSourceListResult = {
  __typename?: 'CryptoEventSourceListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<CryptoEventSource>>;
};

export type CryptoEventSourceMutationInput = {
  code?: InputMaybe<Scalars['String']>;
  cryptoEventSourceId?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['NonEmptyString']>;
};

export enum CryptoEventType {
  Airdrop = 'airdrop',
  Bridging = 'bridging',
  Hardfork = 'hardfork',
  Rugpull = 'rugpull',
  Staking = 'staking'
}

export enum DocSignProvider {
  HelloSign = 'HelloSign',
  Local = 'Local'
}

export type DocumentSignedData = {
  __typename?: 'DocumentSignedData';
  subscriptionEventId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Int']>;
};

export type Feedback = {
  __typename?: 'Feedback';
  created?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  feedbackId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type FeedbackInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type FeedbackListResult = {
  __typename?: 'FeedbackListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Feedback>>;
};

export type File = {
  __typename?: 'File';
  encoding?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  originFileName?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<FileType>;
};

export type FileInfo = {
  order?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<FileType>;
};

export enum FileType {
  Avatar = 'Avatar',
  SupportTicket = 'SupportTicket'
}

export type Filter = {
  field: Scalars['String'];
  fieldFilters?: InputMaybe<Array<InputMaybe<FilterValue>>>;
};

export enum FilterOperatorEnum {
  Eq = 'eq',
  Gt = 'gt',
  Gte = 'gte',
  In = 'in',
  Like = 'like',
  Lt = 'lt',
  Lte = 'lte',
  Neq = 'neq'
}

export enum FilterTypeEnum {
  Array = 'array',
  Date = 'date',
  Number = 'number',
  String = 'string'
}

export type FilterValue = {
  includeNulls?: InputMaybe<Scalars['Boolean']>;
  operator: FilterOperatorEnum;
  type?: InputMaybe<FilterTypeEnum>;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type HsTemplate = {
  __typename?: 'HsTemplate';
  customFields?: Maybe<Array<Maybe<HsTemplateCustomField>>>;
  templateId: Scalars['ID'];
  title: Scalars['String'];
};

export type HsTemplateCustomField = {
  __typename?: 'HsTemplateCustomField';
  name: Scalars['String'];
};

export type HsTemplateListResult = {
  __typename?: 'HsTemplateListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<HsTemplate>>;
};

export enum HsTemplateType {
  ProjectPRequestContract = 'projectPRequestContract',
  ProjectPRequestNovation = 'projectPRequestNovation',
  RollingFundPRequestContract = 'rollingFundPRequestContract'
}

export enum IncomeEnum {
  High = 'high',
  Low = 'low',
  Medium = 'medium'
}

export type KycApplicant = {
  __typename?: 'KycApplicant';
  birthday?: Maybe<Scalars['Date']>;
  countryCode2?: Maybe<Scalars['String']>;
  countryCode3?: Maybe<Scalars['String']>;
  details?: Maybe<Array<StringMap>>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type KycAppliedDocument = {
  __typename?: 'KycAppliedDocument';
  code: Scalars['String'];
  countryCode2?: Maybe<Scalars['String']>;
  countryCode3?: Maybe<Scalars['String']>;
  details?: Maybe<Array<StringMap>>;
  firstName?: Maybe<Scalars['String']>;
  issuedDate?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['String']>;
};

export type KycDocumentType = {
  __typename?: 'KycDocumentType';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Scalars['String']>>;
  subTypes?: Maybe<Array<KycDocumentType>>;
  type?: Maybe<Scalars['String']>;
};

export type KycInfo = {
  __typename?: 'KycInfo';
  applicant?: Maybe<KycApplicant>;
  appliedDocuments?: Maybe<Array<KycAppliedDocument>>;
  requiredInfo?: Maybe<KycRequiredInfo>;
};

export type KycInfoField = {
  __typename?: 'KycInfoField';
  name?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
};

export enum KycProvider {
  BlockPass = 'BlockPass',
  Local = 'Local',
  SumSub = 'SumSub'
}

export type KycRejectedLabel = {
  __typename?: 'KycRejectedLabel';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type KycRequiredInfo = {
  __typename?: 'KycRequiredInfo';
  documents?: Maybe<Array<KycDocumentType>>;
  fields?: Maybe<Array<KycInfoField>>;
};

export enum KycStatus {
  Approved = 'approved',
  Completed = 'completed',
  Init = 'init',
  NotFound = 'notFound',
  OnHold = 'onHold',
  Pending = 'pending',
  Queued = 'queued',
  Rejected = 'rejected',
  Unknown = 'unknown'
}

export type LoginResult = {
  __typename?: 'LoginResult';
  authToken?: Maybe<Scalars['String']>;
  authTokenAction?: Maybe<Scalars['String']>;
  authTokenActionParam?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  badgeValue?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateRecurringSubscription?: Maybe<SubscriptionUpdateResult>;
  activateUser?: Maybe<User>;
  addCryptoEventSource?: Maybe<CryptoEventSource>;
  addFeedback: Feedback;
  addMyContact?: Maybe<User>;
  addProject?: Maybe<Project>;
  addProjectPRequest: ProjectPRequest;
  addRollingFund: RollingFund;
  addRollingFundPRequest: RollingFundPRequest;
  addSettingsKyc: SettingsKyc;
  addSettingsKycLevel: SettingsKycLevel;
  approveProjectPRequest?: Maybe<ProjectPRequest>;
  approveRollingFundPRequest?: Maybe<RollingFundPRequest>;
  assignRole?: Maybe<User>;
  broadcastNotification?: Maybe<Scalars['Void']>;
  cancelNovation?: Maybe<ProjectPRequest>;
  changePassword: Scalars['Boolean'];
  confirmDevice: Scalars['Boolean'];
  confirmEmail: Scalars['Boolean'];
  createMyApiKey?: Maybe<ApiKeySecret>;
  createSupportTicket: SupportTicket;
  createUser?: Maybe<User>;
  createUserApiKey?: Maybe<ApiKeySecret>;
  deactivateUser?: Maybe<User>;
  declineProjectPRequest?: Maybe<ProjectPRequest>;
  declineRollingFundPRequest?: Maybe<RollingFundPRequest>;
  deleteCryptoEventSource?: Maybe<CryptoEventSource>;
  deleteMyApiKey?: Maybe<ApiKey>;
  deleteMyContact?: Maybe<User>;
  deleteProject?: Maybe<Project>;
  deleteRollingFund: RollingFund;
  deleteSettingsKyc: SettingsKyc;
  deleteSettingsKycLevel: SettingsKycLevel;
  deleteUser?: Maybe<User>;
  deleteUserApiKey?: Maybe<ApiKey>;
  disable2fa: LoginResult;
  enable2fa: LoginResult;
  followCryptoAsset?: Maybe<Scalars['Void']>;
  foo: Scalars['String'];
  forgotPassword: Scalars['Boolean'];
  generate2faCode: TwoFactorAuthenticationResult;
  get2faQRCode: Scalars['String'];
  login: LoginResult;
  logout: Scalars['Boolean'];
  markViewedByLinkedObject?: Maybe<Scalars['Void']>;
  refreshToken: Scalars['String'];
  removeRole?: Maybe<User>;
  requestNovation?: Maybe<ProjectPRequest>;
  scrapeChannels?: Maybe<CryptoEventData>;
  sendEmailCodePasswordChange: Scalars['Boolean'];
  setFundsReceived?: Maybe<Contract>;
  setMyInfo: LoginResult;
  setPassword: Scalars['Boolean'];
  setUserInfo: LoginResult;
  signup: LoginResult;
  testSubscription?: Maybe<Scalars['Void']>;
  unfollowCryptoAsset?: Maybe<Scalars['Void']>;
  updateCryptoAsset?: Maybe<CryptoAsset>;
  updateCryptoEventSource?: Maybe<CryptoEventSource>;
  updateMe?: Maybe<User>;
  updateMyContact?: Maybe<User>;
  updateProject?: Maybe<Project>;
  updateProjectPRequest?: Maybe<ProjectPRequest>;
  updateRollingFund: RollingFund;
  updateRollingFundPRequest?: Maybe<RollingFundPRequest>;
  updateRollingFundsDisclaimer: SettingsCommon;
  updateSettingsKyc: SettingsKyc;
  updateSettingsKycLevel: SettingsKycLevel;
  updateUser?: Maybe<User>;
  verify2faCode: LoginResult;
  withdrawProjectPRequest?: Maybe<ProjectPRequest>;
  withdrawProjectPRequestApproval?: Maybe<ProjectPRequest>;
  withdrawRollingFundPRequest?: Maybe<RollingFundPRequest>;
  withdrawRollingFundPRequestApproval?: Maybe<RollingFundPRequest>;
};


export type MutationActivateRecurringSubscriptionArgs = {
  recurringSubscriptionId: Scalars['ID'];
};


export type MutationActivateUserArgs = {
  userId: Scalars['ID'];
};


export type MutationAddCryptoEventSourceArgs = {
  code: Scalars['String'];
  link: Scalars['String'];
  title: Scalars['String'];
};


export type MutationAddFeedbackArgs = {
  feedback: FeedbackInput;
};


export type MutationAddMyContactArgs = {
  contact?: InputMaybe<UserContactInput>;
};


export type MutationAddProjectArgs = {
  avatar?: InputMaybe<Scalars['Upload']>;
  crop?: InputMaybe<Crop>;
  project: ProjectInput;
  rounds?: InputMaybe<ProjectRoundMutationListInput>;
  socials?: InputMaybe<SocialDataMutationListInput>;
  spacing?: InputMaybe<Spacing>;
};


export type MutationAddProjectPRequestArgs = {
  communitySocials?: InputMaybe<SocialDataMutationListInput>;
  participationRequest: ProjectPRequestInput;
  userSocials?: InputMaybe<SocialDataMutationListInput>;
};


export type MutationAddRollingFundArgs = {
  avatar?: InputMaybe<Scalars['Upload']>;
  crop?: InputMaybe<Crop>;
  rollingFund: RollingFundInput;
  spacing?: InputMaybe<Spacing>;
};


export type MutationAddRollingFundPRequestArgs = {
  participationRequest: RollingFundPRequestInput;
};


export type MutationAddSettingsKycArgs = {
  settings: SettingsKycInput;
};


export type MutationAddSettingsKycLevelArgs = {
  settingsLevel: SettingsKycLevelInput;
};


export type MutationApproveProjectPRequestArgs = {
  document?: InputMaybe<Scalars['Upload']>;
  externalTemplateId?: InputMaybe<Scalars['String']>;
  participationRequestId: Scalars['ID'];
  tokenAllocation?: InputMaybe<Array<TokenAllocationForRoundInput>>;
};


export type MutationApproveRollingFundPRequestArgs = {
  externalTemplateId?: InputMaybe<Scalars['String']>;
  participationRequestId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Date']>;
};


export type MutationAssignRoleArgs = {
  roleCodes?: InputMaybe<Array<Scalars['String']>>;
  userId: Scalars['ID'];
};


export type MutationCancelNovationArgs = {
  participationRequestId: Scalars['ID'];
};


export type MutationChangePasswordArgs = {
  code2fa?: InputMaybe<Scalars['String']>;
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationConfirmDeviceArgs = {
  token: Scalars['String'];
};


export type MutationConfirmEmailArgs = {
  code?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};


export type MutationCreateSupportTicketArgs = {
  ticket: SupportTicketInput;
};


export type MutationCreateUserArgs = {
  password?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserInput>;
  wallets?: InputMaybe<WalletDataMutationListInput>;
};


export type MutationCreateUserApiKeyArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type MutationDeactivateUserArgs = {
  userId: Scalars['ID'];
};


export type MutationDeclineProjectPRequestArgs = {
  comment: Scalars['String'];
  participationRequestId: Scalars['ID'];
};


export type MutationDeclineRollingFundPRequestArgs = {
  comment: Scalars['String'];
  participationRequestId: Scalars['ID'];
};


export type MutationDeleteCryptoEventSourceArgs = {
  cryptoEventSourceId: Scalars['ID'];
};


export type MutationDeleteMyApiKeyArgs = {
  apiKeyId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteMyContactArgs = {
  contactId: Scalars['ID'];
};


export type MutationDeleteProjectArgs = {
  projectId: Scalars['ID'];
};


export type MutationDeleteRollingFundArgs = {
  rollingFundId: Scalars['ID'];
};


export type MutationDeleteSettingsKycArgs = {
  settingsId: Scalars['ID'];
};


export type MutationDeleteSettingsKycLevelArgs = {
  settingsId: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID'];
};


export type MutationDeleteUserApiKeyArgs = {
  apiKeyId?: InputMaybe<Scalars['String']>;
};


export type MutationDisable2faArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
};


export type MutationEnable2faArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
};


export type MutationFollowCryptoAssetArgs = {
  cryptoCryptoAssetId: Scalars['ID'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationGet2faQrCodeArgs = {
  data: Scalars['String'];
};


export type MutationLoginArgs = {
  email?: InputMaybe<Scalars['String']>;
  oAuthProvider?: InputMaybe<OAuthProvider>;
  oAuthToken?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};


export type MutationMarkViewedByLinkedObjectArgs = {
  linkedObjects?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationRemoveRoleArgs = {
  roleCodes?: InputMaybe<Array<Scalars['String']>>;
  userId: Scalars['ID'];
};


export type MutationRequestNovationArgs = {
  participationRequestId: Scalars['ID'];
};


export type MutationSetFundsReceivedArgs = {
  contractId: Scalars['ID'];
  value: Scalars['Boolean'];
};


export type MutationSetMyInfoArgs = {
  address?: InputMaybe<PostAddress>;
  birthday?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};


export type MutationSetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSetUserInfoArgs = {
  address?: InputMaybe<PostAddress>;
  birthday?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type MutationSignupArgs = {
  accountType?: InputMaybe<AccountType>;
  address?: InputMaybe<PostAddress>;
  birthday?: InputMaybe<Scalars['Date']>;
  countryCode2?: InputMaybe<Scalars['String']>;
  countryCode3?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mode: UserMode;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  termsOfUse: Scalars['Boolean'];
};


export type MutationTestSubscriptionArgs = {
  subscriptionEventId: Scalars['ID'];
};


export type MutationUnfollowCryptoAssetArgs = {
  cryptoCryptoAssetId: Scalars['ID'];
};


export type MutationUpdateCryptoAssetArgs = {
  cryptoAssetId: Scalars['ID'];
  isScam?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateCryptoEventSourceArgs = {
  code?: InputMaybe<Scalars['String']>;
  cryptoEventSourceId: Scalars['ID'];
  link?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMeArgs = {
  password?: InputMaybe<PasswordInput>;
  user?: InputMaybe<UserInput>;
  wallets?: InputMaybe<WalletDataMutationListInput>;
};


export type MutationUpdateMyContactArgs = {
  contact?: InputMaybe<UserContactInput>;
  contactId: Scalars['String'];
};


export type MutationUpdateProjectArgs = {
  avatar?: InputMaybe<Scalars['Upload']>;
  crop?: InputMaybe<Crop>;
  project?: InputMaybe<ProjectMutationInput>;
  rounds?: InputMaybe<ProjectRoundMutationListInput>;
  socials?: InputMaybe<SocialDataMutationListInput>;
  spacing?: InputMaybe<Spacing>;
};


export type MutationUpdateProjectPRequestArgs = {
  communitySocials?: InputMaybe<SocialDataMutationListInput>;
  participationRequest?: InputMaybe<ProjectPRequestMutationInput>;
  userSocials?: InputMaybe<SocialDataMutationListInput>;
};


export type MutationUpdateRollingFundArgs = {
  avatar?: InputMaybe<Scalars['Upload']>;
  crop?: InputMaybe<Crop>;
  rollingFund?: InputMaybe<RollingFundMutationInput>;
  spacing?: InputMaybe<Spacing>;
};


export type MutationUpdateRollingFundPRequestArgs = {
  participationRequest?: InputMaybe<RollingFundPRequestMutationInput>;
};


export type MutationUpdateRollingFundsDisclaimerArgs = {
  disclaimer: Scalars['NonEmptyString'];
};


export type MutationUpdateSettingsKycArgs = {
  settings: SettingsKycInput;
  settingsId: Scalars['ID'];
};


export type MutationUpdateSettingsKycLevelArgs = {
  settingsLevel: SettingsKycLevelInput;
  settingsLevelId: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  password?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserInput>;
  userId: Scalars['ID'];
  wallets?: InputMaybe<WalletDataMutationListInput>;
};


export type MutationVerify2faCodeArgs = {
  code: Scalars['String'];
};


export type MutationWithdrawProjectPRequestArgs = {
  comment: Scalars['String'];
  participationRequestId: Scalars['ID'];
};


export type MutationWithdrawProjectPRequestApprovalArgs = {
  comment: Scalars['String'];
  participationRequestId: Scalars['ID'];
};


export type MutationWithdrawRollingFundPRequestArgs = {
  comment: Scalars['String'];
  participationRequestId: Scalars['ID'];
};


export type MutationWithdrawRollingFundPRequestApprovalArgs = {
  comment: Scalars['String'];
  participationRequestId: Scalars['ID'];
};

export type MyAssetsUpdateData = {
  __typename?: 'MyAssetsUpdateData';
  subscriptionEventId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Int']>;
};

export enum NotificationType {
  CryptoAssetEvent = 'crypto_asset_event'
}

export type Novation = {
  __typename?: 'Novation';
  buyerId: Scalars['String'];
  buyerSignUrl?: Maybe<Scalars['String']>;
  buyerSignatureId: Scalars['String'];
  created?: Maybe<Scalars['Date']>;
  finalCopyUrl?: Maybe<Scalars['String']>;
  isComplete?: Maybe<Scalars['Boolean']>;
  novationId: Scalars['ID'];
  sellerId: Scalars['String'];
  sellerSignUrl?: Maybe<Scalars['String']>;
  sellerSignatureId: Scalars['String'];
  signatureRequestId: Scalars['String'];
  signedByBuyer?: Maybe<Scalars['Date']>;
  signedBySeller?: Maybe<Scalars['Date']>;
};

export type NovationListResult = {
  __typename?: 'NovationListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Novation>>;
};

export enum OAuthProvider {
  Facebook = 'facebook',
  Google = 'google',
  Microsoft = 'microsoft',
  Twitter = 'twitter'
}

export enum ObjectTypeEnum {
  MenuItem = 'menu_item',
  Page = 'page'
}

export enum OccupationEnum {
  Employed = 'employed',
  Retired = 'retired',
  SelfEmployed = 'selfEmployed',
  Student = 'student',
  Unemployed = 'unemployed'
}

export type OrderBy = {
  desc: Scalars['Boolean'];
  orderBy: Scalars['String'];
};

export type PRequestActionLogEntry = {
  __typename?: 'PRequestActionLogEntry';
  comment?: Maybe<Scalars['String']>;
  tokenAllocation?: Maybe<Array<Maybe<TokenAllocation>>>;
  userAction?: Maybe<UserAction>;
};

export type PRequestProgress = {
  __typename?: 'PRequestProgress';
  isComplete?: Maybe<Scalars['Boolean']>;
  steps?: Maybe<Array<Maybe<PRequestProgressStep>>>;
};

export type PRequestProgressStep = {
  __typename?: 'PRequestProgressStep';
  code?: Maybe<PRequestStepCodeEnum>;
  isComplete?: Maybe<Scalars['Boolean']>;
  isError?: Maybe<Scalars['Boolean']>;
};

export enum PRequestSignatureStatusFilterEnum {
  Signed = 'signed',
  WaitingMe = 'waitingMe',
  WaitingOther = 'waitingOther'
}

export enum PRequestStepCodeEnum {
  Novation = 'novation',
  NovationApproval = 'novationApproval',
  NovationSignatureBuyer = 'novationSignatureBuyer',
  NovationSignatureSeller = 'novationSignatureSeller',
  RequestApproval = 'requestApproval',
  RequestCreation = 'requestCreation',
  RequestSaftSignatureBuyer = 'requestSaftSignatureBuyer',
  RequestSaftSignatureSeller = 'requestSaftSignatureSeller'
}

export enum PRequestStepCodeLegalEnum {
  RequestCreation = 'requestCreation'
}

export enum ParticipationRequestStatusEnum {
  Approved = 'approved',
  Declined = 'declined',
  Novation = 'novation',
  Pending = 'pending',
  Withdrawn = 'withdrawn'
}

export type PasswordAsAdminInput = {
  newPassword: Scalars['String'];
  userId: Scalars['ID'];
};

export type PasswordInput = {
  code2fa?: InputMaybe<Scalars['String']>;
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export enum PaymentProviderEnum {
  Stripe = 'Stripe'
}

export type PostAddress = {
  addressEndDate?: InputMaybe<Scalars['DateTime']>;
  addressStartDate?: InputMaybe<Scalars['DateTime']>;
  buildingName?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['String']>;
  flatNumber?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['String']>;
  stateName?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  subStreet?: InputMaybe<Scalars['String']>;
  town?: InputMaybe<Scalars['String']>;
};

export type Project = {
  __typename?: 'Project';
  avatar?: Maybe<Scalars['String']>;
  contributionWallet?: Maybe<Scalars['String']>;
  contributionWalletShort?: Maybe<Scalars['String']>;
  contributionWalletUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  externalTemplateId?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  rounds?: Maybe<Array<Maybe<ProjectRound>>>;
  socials?: Maybe<Array<Maybe<SocialData>>>;
  status?: Maybe<ProjectStatusEnum>;
  title?: Maybe<Scalars['String']>;
  tokenGenerationDate?: Maybe<Scalars['Date']>;
  tokenName?: Maybe<Scalars['String']>;
  tokenSymbol?: Maybe<Scalars['String']>;
  whitepaperLink?: Maybe<Scalars['String']>;
};

export type ProjectInput = {
  contributionWallet?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  externalTemplateId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProjectStatusEnum>;
  title: Scalars['NonEmptyString'];
  tokenGenerationDate?: InputMaybe<Scalars['Date']>;
  tokenName?: InputMaybe<Scalars['String']>;
  tokenSymbol?: InputMaybe<Scalars['String']>;
  whitepaperLink?: InputMaybe<Scalars['String']>;
};

export type ProjectListResult = {
  __typename?: 'ProjectListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Project>>;
};

export type ProjectMutationInput = {
  contributionWallet?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  externalTemplateId?: InputMaybe<Scalars['String']>;
  projectId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['NonEmptyString']>;
  tokenGenerationDate?: InputMaybe<Scalars['Date']>;
  tokenName?: InputMaybe<Scalars['String']>;
  tokenSymbol?: InputMaybe<Scalars['String']>;
  whitepaperLink?: InputMaybe<Scalars['String']>;
};

export type ProjectPRequest = {
  __typename?: 'ProjectPRequest';
  communitySocials?: Maybe<Array<Maybe<SocialData>>>;
  contract?: Maybe<Contract>;
  contribution: Scalars['String'];
  created?: Maybe<Scalars['Date']>;
  decided?: Maybe<Scalars['Date']>;
  fundName?: Maybe<Scalars['String']>;
  investmentAmount: Scalars['Float'];
  investmentApprovedAmount?: Maybe<Scalars['Float']>;
  investmentCurrency: Scalars['String'];
  isLegalPerson: Scalars['Boolean'];
  leadSource?: Maybe<Scalars['String']>;
  novations?: Maybe<Array<Maybe<Novation>>>;
  progress?: Maybe<PRequestProgress>;
  project: Project;
  projectPRequestId: Scalars['ID'];
  status: ParticipationRequestStatusEnum;
  tokenAllocation?: Maybe<Array<Maybe<TokenAllocation>>>;
  updated?: Maybe<Scalars['Date']>;
  user: User;
  userActionLog?: Maybe<Array<Maybe<PRequestActionLogEntry>>>;
  userSocials?: Maybe<Array<Maybe<SocialData>>>;
};

export type ProjectPRequestInput = {
  contribution: Scalars['NonEmptyString'];
  fundName?: InputMaybe<Scalars['String']>;
  investmentAmount: Scalars['Float'];
  investmentCurrency: Scalars['NonEmptyString'];
  isLegalPerson: Scalars['Boolean'];
  leadSource?: InputMaybe<Scalars['String']>;
  projectId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type ProjectPRequestListResult = {
  __typename?: 'ProjectPRequestListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<ProjectPRequest>>;
};

export type ProjectPRequestMutationInput = {
  contribution?: InputMaybe<Scalars['NonEmptyString']>;
  fundName?: InputMaybe<Scalars['String']>;
  investmentAmount?: InputMaybe<Scalars['Float']>;
  investmentCurrency?: InputMaybe<Scalars['NonEmptyString']>;
  isLegalPerson?: InputMaybe<Scalars['Boolean']>;
  leadSource?: InputMaybe<Scalars['String']>;
  participationRequestId: Scalars['ID'];
};

export type ProjectRound = {
  __typename?: 'ProjectRound';
  price?: Maybe<Scalars['Float']>;
  projectRoundId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  tokenCount?: Maybe<Scalars['Float']>;
};

export type ProjectRoundInput = {
  price: Scalars['Float'];
  title: Scalars['NonEmptyString'];
  tokenCount: Scalars['Float'];
};

export type ProjectRoundMutationInput = {
  price?: InputMaybe<Scalars['Float']>;
  projectRoundId: Scalars['ID'];
  title?: InputMaybe<Scalars['NonEmptyString']>;
  tokenCount?: InputMaybe<Scalars['Float']>;
};

export type ProjectRoundMutationListInput = {
  created?: InputMaybe<Array<InputMaybe<ProjectRoundInput>>>;
  deleted?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updated?: InputMaybe<Array<InputMaybe<ProjectRoundMutationInput>>>;
};

export type ProjectRoundStatistics = {
  __typename?: 'ProjectRoundStatistics';
  tokensApproved?: Maybe<Scalars['Float']>;
  tokensSold?: Maybe<Scalars['Float']>;
};

export type ProjectRoundWithSummary = {
  __typename?: 'ProjectRoundWithSummary';
  price?: Maybe<Scalars['Float']>;
  projectRoundId: Scalars['ID'];
  statistics?: Maybe<ProjectRoundStatistics>;
  title?: Maybe<Scalars['String']>;
  tokenCount?: Maybe<Scalars['Float']>;
};

export enum ProjectStatusEnum {
  Active = 'active',
  Inactive = 'inactive'
}

export type ProjectWithSummary = {
  __typename?: 'ProjectWithSummary';
  avatar?: Maybe<Scalars['String']>;
  contributionWallet?: Maybe<Scalars['String']>;
  contributionWalletShort?: Maybe<Scalars['String']>;
  contributionWalletUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  externalTemplateId?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  rounds?: Maybe<Array<Maybe<ProjectRoundWithSummary>>>;
  roundsTotal?: Maybe<Scalars['Float']>;
  socials?: Maybe<Array<Maybe<SocialData>>>;
  status?: Maybe<ProjectStatusEnum>;
  title?: Maybe<Scalars['String']>;
  tokenGenerationDate?: Maybe<Scalars['Date']>;
  tokenName?: Maybe<Scalars['String']>;
  tokenSymbol?: Maybe<Scalars['String']>;
  tokensTotal?: Maybe<Scalars['Float']>;
  whitepaperLink?: Maybe<Scalars['String']>;
};

export type ProjectWithSummaryListResult = {
  __typename?: 'ProjectWithSummaryListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<ProjectWithSummary>>;
};

export type Query = {
  __typename?: 'Query';
  cryptoEventSources?: Maybe<Array<Maybe<CryptoEventSource>>>;
  generateWebApiToken: Scalars['String'];
  getApiKeys?: Maybe<ApiKeyListResult>;
  getAppropriateSettingsKyc?: Maybe<SettingsKyc>;
  getBillingPortalUrl?: Maybe<Scalars['String']>;
  getCryptoAssetById?: Maybe<CryptoAsset>;
  getCryptoAssets?: Maybe<Array<Maybe<CryptoAsset>>>;
  getCryptoEventSourceById?: Maybe<CryptoEventSource>;
  getCryptoEventSources?: Maybe<CryptoEventSourceListResult>;
  getCryptoEventsByAssetId?: Maybe<Array<Maybe<CryptoEvent>>>;
  getDocumentFinalCopyUrl?: Maybe<Scalars['String']>;
  getDocumentSignUrl?: Maybe<Scalars['String']>;
  getFeedbacks?: Maybe<FeedbackListResult>;
  getHsTemplates: Array<Maybe<HsTemplate>>;
  getProjectById?: Maybe<Project>;
  getProjectPRequestById?: Maybe<ProjectPRequest>;
  getProjectPRequests: ProjectPRequestListResult;
  getProjects: ProjectListResult;
  getProjectsWithSummary: ProjectWithSummaryListResult;
  getRecurringSubscriptions?: Maybe<RecurringSubscriptionListResult>;
  getRollingFundById?: Maybe<RollingFund>;
  getRollingFundPRequestById?: Maybe<RollingFundPRequest>;
  getRollingFundPRequests: RollingFundPRequestListResult;
  getRollingFunds: RollingFundListResult;
  getRollingFundsDisclaimer?: Maybe<Scalars['String']>;
  getRoundsForProject?: Maybe<Array<Maybe<ProjectRound>>>;
  getSettingsCommon?: Maybe<SettingsCommon>;
  getSettingsKyc?: Maybe<SettingsKycListResult>;
  getSettingsKycLevels?: Maybe<SettingsKycLevelListResult>;
  getSettingsKycShort?: Maybe<SettingsKycShortListResult>;
  getSupportTickets?: Maybe<SupportTicketListResult>;
  getUserActions: UserActionListResult;
  getUserContacts: UserContactListResult;
  getUserKycInfo?: Maybe<KycInfo>;
  getUserState: UserState;
  getUsers: UserListResult;
  me: User;
  menuItems?: Maybe<Array<Maybe<MenuItem>>>;
  myActions: UserActionListResult;
  myApiKeys?: Maybe<ApiKeyListResult>;
  myAssets?: Maybe<Array<Maybe<CryptoAsset>>>;
  myContacts: UserContactListResult;
  myKycInfo?: Maybe<KycInfo>;
  myKycStatus: Scalars['String'];
  myRecurringSubscriptions?: Maybe<Array<Maybe<UserRecurringSubscription>>>;
  mySettingsKyc?: Maybe<SettingsKycShort>;
  mySettingsKycFull?: Maybe<SettingsKyc>;
  myState: UserState;
  mySupportTickets?: Maybe<SupportTicketListResult>;
  serverTime: Scalars['String'];
  userByEmail: User;
  userById: User;
  userByName: User;
  userByOAuthAppId: User;
  userByReferralCode: User;
  userCount?: Maybe<Scalars['Int']>;
  userKycInfo: KycInfo;
};


export type QueryGetApiKeysArgs = {
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAppropriateSettingsKycArgs = {
  filterType?: InputMaybe<SettingsKycTargetFilterType>;
  filterValue?: InputMaybe<Scalars['String']>;
  targetKycProvider: KycProvider;
  targetUserMode: UserMode;
};


export type QueryGetCryptoAssetByIdArgs = {
  cryptoAssetId?: InputMaybe<Scalars['String']>;
};


export type QueryGetCryptoEventSourceByIdArgs = {
  cryptoEventSourceId?: InputMaybe<Scalars['String']>;
};


export type QueryGetCryptoEventSourcesArgs = {
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCryptoEventsByAssetIdArgs = {
  cryptoAssetId?: InputMaybe<Scalars['String']>;
};


export type QueryGetDocumentFinalCopyUrlArgs = {
  documentId?: InputMaybe<Scalars['String']>;
};


export type QueryGetDocumentSignUrlArgs = {
  documentId?: InputMaybe<Scalars['String']>;
};


export type QueryGetFeedbacksArgs = {
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetHsTemplatesArgs = {
  templateType?: InputMaybe<HsTemplateType>;
};


export type QueryGetProjectByIdArgs = {
  projectId?: InputMaybe<Scalars['String']>;
};


export type QueryGetProjectPRequestByIdArgs = {
  participationRequestId?: InputMaybe<Scalars['String']>;
};


export type QueryGetProjectPRequestsArgs = {
  filterEx?: InputMaybe<Array<InputMaybe<Filter>>>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetProjectsArgs = {
  filterEx?: InputMaybe<Array<InputMaybe<Filter>>>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetProjectsWithSummaryArgs = {
  filterEx?: InputMaybe<Array<InputMaybe<Filter>>>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetRollingFundByIdArgs = {
  rollingFundId?: InputMaybe<Scalars['String']>;
};


export type QueryGetRollingFundPRequestByIdArgs = {
  participationRequestId?: InputMaybe<Scalars['String']>;
};


export type QueryGetRollingFundPRequestsArgs = {
  filterEx?: InputMaybe<Array<InputMaybe<Filter>>>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetRollingFundsArgs = {
  filterEx?: InputMaybe<Array<InputMaybe<Filter>>>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetRoundsForProjectArgs = {
  projectId?: InputMaybe<Scalars['String']>;
};


export type QueryGetSettingsKycArgs = {
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetSettingsKycLevelsArgs = {
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetSettingsKycShortArgs = {
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetSupportTicketsArgs = {
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserActionsArgs = {
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
  withResult?: InputMaybe<UserActionResult>;
};


export type QueryGetUserContactsArgs = {
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserKycInfoArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserStateArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetUsersArgs = {
  filterEx?: InputMaybe<Array<InputMaybe<Filter>>>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryMyActionsArgs = {
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
  withResult?: InputMaybe<UserActionResult>;
};


export type QueryMyApiKeysArgs = {
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryMyContactsArgs = {
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryMySupportTicketsArgs = {
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderBy>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryUserByEmailArgs = {
  email?: InputMaybe<Scalars['String']>;
};


export type QueryUserByIdArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryUserByNameArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type QueryUserByOAuthAppIdArgs = {
  oAuthAppId?: InputMaybe<Scalars['String']>;
  oAuthProvider?: InputMaybe<OAuthProvider>;
};


export type QueryUserByReferralCodeArgs = {
  referralCode?: InputMaybe<Scalars['Int']>;
};


export type QueryUserKycInfoArgs = {
  userId: Scalars['String'];
};

export type RecurringSubscription = {
  __typename?: 'RecurringSubscription';
  id: Scalars['ID'];
  listItems?: Maybe<Array<Maybe<Scalars['String']>>>;
  maxCryptoAssetsToFollow: Scalars['Int'];
  price: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
};

export type RecurringSubscriptionListResult = {
  __typename?: 'RecurringSubscriptionListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<RecurringSubscription>>>;
};

export type RequiredUserPermission = {
  fullAccess?: InputMaybe<Scalars['Boolean']>;
  objectCode?: InputMaybe<Scalars['String']>;
};

export type RollingFund = {
  __typename?: 'RollingFund';
  approvalStatus?: Maybe<RollingFundApprovalStatusEnum>;
  avatar?: Maybe<Scalars['String']>;
  contributionWallet?: Maybe<Scalars['String']>;
  contributionWalletShort?: Maybe<Scalars['String']>;
  contributionWalletUrl?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  externalTemplateId?: Maybe<Scalars['String']>;
  introductionLink?: Maybe<Scalars['String']>;
  managementFee: Scalars['Float'];
  minAmount: Scalars['Float'];
  minPeriod: Scalars['Int'];
  minPeriodType: RollingFundPeriodTypeEnum;
  portfolio: Scalars['String'];
  rollingFundId: Scalars['ID'];
  startDate: Scalars['Date'];
  status?: Maybe<RollingFundStatusEnum>;
  terms: Scalars['String'];
  title: Scalars['String'];
  updated?: Maybe<Scalars['Date']>;
};

export enum RollingFundApprovalStatusEnum {
  Approved = 'approved',
  Declined = 'declined'
}

export type RollingFundInput = {
  approvalStatus?: InputMaybe<RollingFundApprovalStatusEnum>;
  contributionWallet?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  externalTemplateId?: InputMaybe<Scalars['String']>;
  introductionLink?: InputMaybe<Scalars['String']>;
  managementFee: Scalars['Float'];
  minAmount: Scalars['Float'];
  minPeriod: Scalars['Int'];
  minPeriodType: RollingFundPeriodTypeEnum;
  portfolio: Scalars['NonEmptyString'];
  startDate: Scalars['Date'];
  status?: InputMaybe<RollingFundStatusEnum>;
  terms: Scalars['NonEmptyString'];
  title: Scalars['NonEmptyString'];
};

export type RollingFundListResult = {
  __typename?: 'RollingFundListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<RollingFund>>;
};

export type RollingFundMutationInput = {
  approvalStatus?: InputMaybe<RollingFundApprovalStatusEnum>;
  contributionWallet?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  externalTemplateId?: InputMaybe<Scalars['String']>;
  introductionLink?: InputMaybe<Scalars['String']>;
  managementFee?: InputMaybe<Scalars['Float']>;
  minAmount?: InputMaybe<Scalars['Float']>;
  minPeriod?: InputMaybe<Scalars['Int']>;
  minPeriodType?: InputMaybe<RollingFundPeriodTypeEnum>;
  portfolio?: InputMaybe<Scalars['NonEmptyString']>;
  rollingFundId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<RollingFundStatusEnum>;
  terms?: InputMaybe<Scalars['NonEmptyString']>;
  title?: InputMaybe<Scalars['NonEmptyString']>;
};

export type RollingFundPRequest = {
  __typename?: 'RollingFundPRequest';
  assets: AssetsEnum;
  contract?: Maybe<Contract>;
  created?: Maybe<Scalars['Date']>;
  decided?: Maybe<Scalars['Date']>;
  income: IncomeEnum;
  investmentAmount: Scalars['Float'];
  investmentCurrency: Scalars['String'];
  isLegalPerson: Scalars['Boolean'];
  motivation: Scalars['String'];
  occupation: OccupationEnum;
  period: Scalars['Int'];
  periodType: RollingFundPeriodTypeEnum;
  progress?: Maybe<PRequestProgress>;
  rollingFund: RollingFund;
  rollingFundPRequestId: Scalars['ID'];
  sourceOfFunds: SourceOfFundsEnum;
  sourceOfFundsOther?: Maybe<Scalars['String']>;
  status: ParticipationRequestStatusEnum;
  updated?: Maybe<Scalars['Date']>;
  user: User;
  userActionLog?: Maybe<Array<Maybe<PRequestActionLogEntry>>>;
};

export type RollingFundPRequestInput = {
  assets: AssetsEnum;
  income: IncomeEnum;
  investmentAmount: Scalars['Float'];
  investmentCurrency: Scalars['NonEmptyString'];
  isLegalPerson: Scalars['Boolean'];
  motivation: Scalars['NonEmptyString'];
  occupation: OccupationEnum;
  period: Scalars['Int'];
  periodType: RollingFundPeriodTypeEnum;
  rollingFundId: Scalars['ID'];
  sourceOfFunds: SourceOfFundsEnum;
  sourceOfFundsOther?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type RollingFundPRequestListResult = {
  __typename?: 'RollingFundPRequestListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<RollingFundPRequest>>;
};

export type RollingFundPRequestMutationInput = {
  assets?: InputMaybe<AssetsEnum>;
  income?: InputMaybe<IncomeEnum>;
  investmentAmount?: InputMaybe<Scalars['Float']>;
  investmentCurrency?: InputMaybe<Scalars['NonEmptyString']>;
  isLegalPerson?: InputMaybe<Scalars['Boolean']>;
  motivation?: InputMaybe<Scalars['NonEmptyString']>;
  occupation?: InputMaybe<OccupationEnum>;
  participationRequestId: Scalars['ID'];
  period?: InputMaybe<Scalars['Int']>;
  periodType?: InputMaybe<RollingFundPeriodTypeEnum>;
  sourceOfFunds?: InputMaybe<SourceOfFundsEnum>;
  sourceOfFundsOther?: InputMaybe<Scalars['String']>;
};

export enum RollingFundPeriodTypeEnum {
  Month = 'month',
  Quarter = 'quarter',
  Semester = 'semester',
  Year = 'year'
}

export enum RollingFundStatusEnum {
  Active = 'active',
  Inactive = 'inactive'
}

export type SettingsCommon = {
  __typename?: 'SettingsCommon';
  additionalSettings?: Maybe<Scalars['String']>;
  adminEmails?: Maybe<Array<Scalars['String']>>;
  docSignBaseAddress?: Maybe<Scalars['String']>;
  docSignProvider?: Maybe<Scalars['String']>;
  kycBaseAddress?: Maybe<Scalars['String']>;
  kycProvider?: Maybe<Scalars['String']>;
  paymentProvider?: Maybe<Scalars['String']>;
  rollingFundsDisclaimer?: Maybe<Scalars['String']>;
  settingsCommonId?: Maybe<Scalars['String']>;
};

export type SettingsCommonInput = {
  additionalSettings?: InputMaybe<Scalars['String']>;
  adminEmails?: InputMaybe<Array<Scalars['String']>>;
  docSignProvider?: InputMaybe<Scalars['String']>;
  kycProvider?: InputMaybe<Scalars['String']>;
  rollingFundsDisclaimer?: InputMaybe<Scalars['String']>;
};

export type SettingsKyc = {
  __typename?: 'SettingsKyc';
  apiKey: Scalars['String'];
  deleted?: Maybe<Scalars['DateTime']>;
  serviceId?: Maybe<Scalars['String']>;
  serviceName: Scalars['String'];
  settingsKycId: Scalars['ID'];
  targetKycProviders?: Maybe<Array<KycProvider>>;
  targetUserModes?: Maybe<Array<UserMode>>;
};

export type SettingsKycInput = {
  apiKey: Scalars['String'];
  deleted?: InputMaybe<Scalars['DateTime']>;
  serviceId?: InputMaybe<Scalars['String']>;
  serviceName: Scalars['String'];
  targetKycProviders?: InputMaybe<Array<KycProvider>>;
  targetUserModes?: InputMaybe<Array<UserMode>>;
};

export type SettingsKycLevel = {
  __typename?: 'SettingsKycLevel';
  created?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  settingsKycLevelId: Scalars['ID'];
};

export type SettingsKycLevelInput = {
  data: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
};

export type SettingsKycLevelListResult = {
  __typename?: 'SettingsKycLevelListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<SettingsKycLevel>>;
};

export type SettingsKycLevelShort = {
  __typename?: 'SettingsKycLevelShort';
  data?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  settingsKycLevelId: Scalars['ID'];
};

export type SettingsKycListResult = {
  __typename?: 'SettingsKycListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<SettingsKyc>>;
};

export type SettingsKycShort = {
  __typename?: 'SettingsKycShort';
  passed: Scalars['Boolean'];
  serviceId?: Maybe<Scalars['String']>;
  serviceName: Scalars['String'];
  settingsKycId: Scalars['ID'];
};

export type SettingsKycShortListResult = {
  __typename?: 'SettingsKycShortListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<SettingsKycShort>>;
};

export enum SettingsKycTargetFilterType {
  AccountId = 'AccountId',
  AffiliateId = 'AffiliateId',
  Country = 'Country',
  InitiateFrom = 'InitiateFrom',
  None = 'None'
}

export type Social = {
  __typename?: 'Social';
  socialCode?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum SocialCodes {
  Telegram = 'telegram'
}

export type SocialData = {
  __typename?: 'SocialData';
  href?: Maybe<Scalars['String']>;
  pRequestSocialDataId?: Maybe<Scalars['ID']>;
  profileName?: Maybe<Scalars['String']>;
  profileUrl?: Maybe<Scalars['String']>;
  projectSocialDataId?: Maybe<Scalars['ID']>;
  social?: Maybe<Social>;
  socialDataId?: Maybe<Scalars['ID']>;
  userSocialDataId?: Maybe<Scalars['ID']>;
};

export type SocialDataInput = {
  profileName?: InputMaybe<Scalars['String']>;
  profileUrl?: InputMaybe<Scalars['String']>;
  socialCode: Scalars['NonEmptyString'];
};

export type SocialDataMutationInput = {
  profileName?: InputMaybe<Scalars['String']>;
  profileUrl?: InputMaybe<Scalars['String']>;
  socialCode?: InputMaybe<Scalars['NonEmptyString']>;
  socialDataId: Scalars['ID'];
};

export type SocialDataMutationListInput = {
  created?: InputMaybe<Array<InputMaybe<SocialDataInput>>>;
  deleted?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updated?: InputMaybe<Array<InputMaybe<SocialDataMutationInput>>>;
};

export enum SocialTypeEnum {
  Community = 'Community',
  Personal = 'Personal'
}

export enum SourceOfFundsEnum {
  FinancialInstrumentsSale = 'financialInstrumentsSale',
  Inheritance = 'inheritance',
  Other = 'other',
  Pension = 'pension',
  RealEstateSale = 'realEstateSale',
  Salary = 'salary',
  Savings = 'savings',
  SharesSale = 'sharesSale'
}

export type Spacing = {
  bottomSpace?: InputMaybe<Scalars['Float']>;
  leftSpace?: InputMaybe<Scalars['Float']>;
  rightSpace?: InputMaybe<Scalars['Float']>;
  topSpace?: InputMaybe<Scalars['Float']>;
};

export type StringMap = {
  __typename?: 'StringMap';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  documentSigned?: Maybe<Scalars['Void']>;
  myAssetsUpdate?: Maybe<Scalars['Void']>;
  newNotification?: Maybe<Scalars['Void']>;
};


export type SubscriptionDocumentSignedArgs = {
  subscriptionEventIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SubscriptionMyAssetsUpdateArgs = {
  subscriptionEventIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SubscriptionNewNotificationArgs = {
  subscriptionEventIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SubscriptionUpdateResult = {
  __typename?: 'SubscriptionUpdateResult';
  checkoutUrl?: Maybe<Scalars['String']>;
};

export type SupportTicket = {
  __typename?: 'SupportTicket';
  created?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<SupportTicketStatusEnum>;
  supportTicketId: Scalars['ID'];
  topic?: Maybe<SupportTicketCategoryEnum>;
};

export enum SupportTicketCategoryEnum {
  Other = 'other',
  ParticipationRequest = 'participation_request',
  Saft = 'saft'
}

export type SupportTicketInput = {
  description: Scalars['String'];
  email: Scalars['String'];
  topic: SupportTicketCategoryEnum;
};

export type SupportTicketListResult = {
  __typename?: 'SupportTicketListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<SupportTicket>>;
};

export enum SupportTicketStatusEnum {
  Closed = 'closed',
  Open = 'open'
}

export enum TokenAction {
  ApiKey = 'ApiKey',
  ConfirmDevice = 'ConfirmDevice',
  ConfirmEmail = 'ConfirmEmail',
  ConfirmPasswordChange = 'ConfirmPasswordChange',
  Default = 'Default',
  KycRequired = 'KycRequired',
  TwoFactorAuth = 'TwoFactorAuth',
  UserInfoRequired = 'UserInfoRequired'
}

export type TokenAllocation = {
  __typename?: 'TokenAllocation';
  projectRound: ProjectRound;
  tokenAllocationId: Scalars['ID'];
  tokenCount: Scalars['Float'];
  totalPrice: Scalars['Float'];
};

export type TokenAllocationForRoundInput = {
  projectRoundId: Scalars['ID'];
  tokenCount: Scalars['Float'];
  totalPrice: Scalars['Float'];
};

export type TwoFactorAuthenticationResult = {
  __typename?: 'TwoFactorAuthenticationResult';
  code: Scalars['String'];
  otpauthUrl: Scalars['String'];
  qr: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  accessFailedCount?: Maybe<Scalars['Int']>;
  addressEndDate?: Maybe<Scalars['DateTime']>;
  addressStartDate?: Maybe<Scalars['DateTime']>;
  avatar?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  buildingName?: Maybe<Scalars['String']>;
  buildingNumber?: Maybe<Scalars['String']>;
  changePasswordRequired?: Maybe<Scalars['Boolean']>;
  countryCode2?: Maybe<Scalars['String']>;
  countryCode3?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  emailConfirmed?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  flatNumber?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  hasEmailAuth?: Maybe<Scalars['Boolean']>;
  is2faEnabled?: Maybe<Scalars['Boolean']>;
  kybStatus?: Maybe<UserKybStatus>;
  kycApplicantId?: Maybe<Scalars['String']>;
  kycPrivateComment?: Maybe<Scalars['String']>;
  kycProvider?: Maybe<Scalars['String']>;
  kycReviewComment?: Maybe<Scalars['String']>;
  kycReviewDate?: Maybe<Scalars['DateTime']>;
  kycReviewRejectedLabels?: Maybe<Array<Scalars['String']>>;
  kycReviewRejectedType?: Maybe<Scalars['String']>;
  kycReviewResult?: Maybe<Scalars['String']>;
  kycServiceId?: Maybe<Scalars['String']>;
  kycStatus?: Maybe<Scalars['String']>;
  kycStatusDate?: Maybe<Scalars['DateTime']>;
  kycStatusUpdateRequired?: Maybe<Scalars['Boolean']>;
  kycValid?: Maybe<Scalars['Boolean']>;
  kycValidationTierId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mode?: Maybe<UserMode>;
  nationality?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<UserRolePermission>>>;
  phone?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Maybe<UserRole>>>;
  socials?: Maybe<Array<Maybe<SocialData>>>;
  stateName?: Maybe<Scalars['String']>;
  status?: Maybe<UserAccountStatus>;
  street?: Maybe<Scalars['String']>;
  subStreet?: Maybe<Scalars['String']>;
  systemUser: Scalars['Boolean'];
  termsOfUse?: Maybe<Scalars['Boolean']>;
  town?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
  userId: Scalars['ID'];
  wallets?: Maybe<Array<Maybe<Wallet>>>;
};

export enum UserAccountStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type UserAction = {
  __typename?: 'UserAction';
  actionType?: Maybe<UserActionType>;
  date?: Maybe<Scalars['DateTime']>;
  info?: Maybe<Scalars['String']>;
  linkedIds?: Maybe<Array<Scalars['String']>>;
  objectId?: Maybe<Scalars['String']>;
  result?: Maybe<UserActionResult>;
  status?: Maybe<Scalars['String']>;
  userActionId: Scalars['ID'];
  userId: Scalars['String'];
};

export type UserActionListResult = {
  __typename?: 'UserActionListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<UserAction>>;
};

export enum UserActionResult {
  Canceled = 'canceled',
  Error = 'error',
  Failed = 'failed',
  Succeeded = 'succeeded',
  Unknown = 'unknown'
}

export enum UserActionType {
  Deposit = 'deposit',
  Exchange = 'exchange',
  Login = 'login',
  Logout = 'logout',
  PRequestApprove = 'pRequestApprove',
  PRequestCountersignNovation = 'pRequestCountersignNovation',
  PRequestCountersignSaft = 'pRequestCountersignSaft',
  PRequestCreate = 'pRequestCreate',
  PRequestDecline = 'pRequestDecline',
  PRequestNovation = 'pRequestNovation',
  PRequestSignNovation = 'pRequestSignNovation',
  PRequestSignSaft = 'pRequestSignSaft',
  PRequestWithdraw = 'pRequestWithdraw',
  PRequestWithdrawApproval = 'pRequestWithdrawApproval',
  PRequestWithdrawNovation = 'pRequestWithdrawNovation',
  PRequestWithdrawSaft = 'pRequestWithdrawSaft',
  Receive = 'receive',
  Send = 'send',
  Signup = 'signup',
  System = 'system',
  Transfer = 'transfer',
  Withdraw = 'withdraw'
}

export type UserContact = {
  __typename?: 'UserContact';
  address?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  userContactId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserContactInput = {
  address?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
};

export type UserContactListResult = {
  __typename?: 'UserContactListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<UserContact>>;
};

export enum UserCryptoAssetStatus {
  Active = 'active',
  AutoPause = 'auto_pause',
  Inactive = 'inactive'
}

export type UserDevice = {
  __typename?: 'UserDevice';
  area?: Maybe<Scalars['Int']>;
  browser?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode2?: Maybe<Scalars['String']>;
  countryCode3?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  device?: Maybe<Scalars['String']>;
  deviceConfirmed?: Maybe<Scalars['DateTime']>;
  eu?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  metro?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  userDeviceId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserInput = {
  addressEndDate?: InputMaybe<Scalars['DateTime']>;
  addressStartDate?: InputMaybe<Scalars['DateTime']>;
  avatar?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['Date']>;
  buildingName?: InputMaybe<Scalars['String']>;
  buildingNumber?: InputMaybe<Scalars['String']>;
  changePasswordRequired?: InputMaybe<Scalars['Boolean']>;
  countryCode2?: InputMaybe<Scalars['String']>;
  countryCode3?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmed?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  flatNumber?: InputMaybe<Scalars['String']>;
  kybStatus?: InputMaybe<UserKybStatus>;
  lastName?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<UserMode>;
  phone?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['String']>;
  stateName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserAccountStatus>;
  street?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  subStreet?: InputMaybe<Scalars['String']>;
  termsOfUse?: InputMaybe<Scalars['Boolean']>;
  town?: InputMaybe<Scalars['String']>;
};

export enum UserKybStatus {
  Approved = 'approved',
  NotVerified = 'not_verified'
}

export type UserListResult = {
  __typename?: 'UserListResult';
  count?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<User>>;
};

export type UserLogin = {
  __typename?: 'UserLogin';
  date: Scalars['DateTime'];
  ip?: Maybe<Scalars['String']>;
  result: Scalars['Int'];
  resultTokenAction?: Maybe<Scalars['String']>;
  userDeviceId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userLoginId?: Maybe<Scalars['String']>;
};

export enum UserMode {
  StandardUser = 'StandardUser',
  VerifiedUser = 'VerifiedUser'
}

export type UserRecurringSubscription = {
  __typename?: 'UserRecurringSubscription';
  dueDate?: Maybe<Scalars['Date']>;
  isActive?: Maybe<Scalars['Boolean']>;
  subscriptionId: Scalars['ID'];
};

export type UserRole = {
  __typename?: 'UserRole';
  code: Scalars['String'];
  immutable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type UserRoleObject = {
  __typename?: 'UserRoleObject';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  immutable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  type: ObjectTypeEnum;
};

export type UserRolePermission = {
  __typename?: 'UserRolePermission';
  fullAccess: Scalars['Boolean'];
  object: UserRoleObject;
};

export type UserShort = {
  __typename?: 'UserShort';
  avatar?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  countryCode2?: Maybe<Scalars['String']>;
  countryCode3?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  kycReviewComment?: Maybe<Scalars['String']>;
  kycStatus?: Maybe<Scalars['String']>;
  kycValid?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
};

export type UserState = {
  __typename?: 'UserState';
  date?: Maybe<Scalars['DateTime']>;
};

export type Wallet = {
  __typename?: 'Wallet';
  createdAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Date']>;
  link?: Maybe<Scalars['String']>;
  processingStatusCode?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
  walletId: Scalars['ID'];
};

export type WalletCryptoAsset = {
  __typename?: 'WalletCryptoAsset';
  cryptoAsset?: Maybe<CryptoAsset>;
  wallet?: Maybe<Wallet>;
};

export type WalletDataMutationListInput = {
  created?: InputMaybe<Array<InputMaybe<WalletMutationInput>>>;
  deleted?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updated?: InputMaybe<Array<InputMaybe<WalletMutationInput>>>;
};

export type WalletMutationInput = {
  link?: InputMaybe<Scalars['String']>;
  walletId: Scalars['ID'];
};
