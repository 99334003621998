import { gql } from '@apollo/client';
import { LOGIN_RESULT } from '@store/common';

export const VERIFY_2FA_CODE = gql`
  mutation Verify2faCode(
    $code: String!,
    #$token: String!
  ) {
    verify2faCode (
      code: $code,
      #token: $token
    ) {
      ${LOGIN_RESULT}
    }
  }
`;

export const SET_PASSWORD = gql`
  mutation setPassword(
    $password: String!,
    $token: String!
  ) {
    setPassword (
      password: $password
      token: $token
    )
  }
`;

export const CONFIRM_DEVICE = gql`
  mutation ConfirmDevice(
    $token: String!
  ) {
    confirmDevice (
      token: $token
    )
  }
`;

export const CONFIRM_EMAIL = gql`
  mutation ConfirmEmail(
    $token: String!
  ) {
    confirmEmail (
      token: $token
    )
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword(
    $email: String!
  ) {
    forgotPassword (
      email: $email
    )
  }
`;

export const GENERATE_2FA = gql`
  mutation Generate2faCode {
    generate2faCode {
      code
      qr
    }
  }
`;

export const ENABLE_2F = gql`
  mutation Enable2fa (
    $code: String!,
    $password: String!
  ){
    enable2fa (
      code: $code,
      password: $password
    ) {
      user {
        userId
        is2faEnabled
      }
    }
  }
`;

export const DISABLE_2FA = gql`
  mutation Disable2fa (
    $code: String!,
    $password: String!
  ){
    disable2fa (
      code: $code,
      password: $password
    ) {
      user {
        userId
        is2faEnabled
      }
    }
  }
`;

export const SIGN_UP = gql`
  mutation Signup(
    $email: String!,
    $password: String,
    $firstName: String,
    $lastName: String,
    $termsOfUse: Boolean!,
    $mode: UserMode!,
    $accountType: AccountType
  ) {
    signup (
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      termsOfUse: $termsOfUse
      mode: $mode
      accountType: $accountType
    ) {
      ${LOGIN_RESULT}
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($user: UserInput, $password: String, $wallets: WalletDataMutationListInput) {
    createUser(user: $user, password: $password, wallets: $wallets) {
      userId
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($userId: ID!, $user: UserInput, $password: String, $wallets: WalletDataMutationListInput) {
    updateUser(userId: $userId, user: $user, password: $password, wallets: $wallets) {
      userId
    }
  }
`;

export const UPDATE_ME = gql`
  mutation UpdateMe($wallets: WalletDataMutationListInput, $user: UserInput, $password: PasswordInput) {
    updateMe(wallets: $wallets, user: $user, password: $password) {
      userId
      email
    }
  }
`;

export const UPDATE_WALLETS = gql`
  mutation UpdateMe($wallets: WalletDataMutationListInput) {
    updateMe(wallets: $wallets) {
      wallets {
        walletId
        ref
        link
        createdAt
        deleted
        processingStatusCode
      }
    }
  }
`;

export const DEACTIVATE_USER = gql`
  mutation DeactivateUser($userId: ID!) {
    deactivateUser(userId: $userId) {
      userId
    }
  }
`;

export const ACTIVATE_USER = gql`
  mutation ActivateUser($userId: ID!) {
    activateUser(userId: $userId) {
      userId
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($userId: ID!) {
    deleteUser(userId: $userId) {
      userId
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

