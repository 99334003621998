interface Config {
  apiUrl: string;
  apiUrlWS: string;
  apiUrlRest: string;
  hsClientId: string;
  hsTestMode: string;
  hsSkipDomainVerification: string;
  bpServiceId: string;
  kybUrl: string;
  markets: {
    [attribute: string]: {
      pricePrecision: number;
      minTradeAmount: number;
    };
  };
  symbols: {
    [attribute: string]: {
      amountPrecision: number;
    };
  };
}

let config: Config = {
  apiUrl: process.env.REACT_APP_API_URL,
  apiUrlWS: process.env.REACT_APP_API_URL_WS,
  apiUrlRest: process.env.REACT_APP_API_URL_REST,
  hsClientId: process.env.REACT_APP_HS_CLIENT_ID,
  hsTestMode: process.env.REACT_APP_HS_TEST_MODE,
  hsSkipDomainVerification: process.env.REACT_APP_HS_SKIP_DOMAIN_VERIFICATION,
  bpServiceId: process.env.REACT_APP_BP_SERVICE_ID,
  kybUrl: 'https://kyb.blockpass.org/registration-form',
  markets: {},
  symbols: {}
};

// precision: "this affects price column, in orderbooks - price, in last trades - price and in trading panel - price"
config.markets = {
  default: {
    pricePrecision: 6,
    minTradeAmount: 0.000001
  }
};

// precision: "my assets related columns, amount columns in trade panel & orderbooks  &  lasttrade, withdrawals, trading history, etc"
config.symbols = {
  default: {
    amountPrecision: 6
  }
};

export default Object.freeze(config);
