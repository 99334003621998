import React from 'react';
import { concatClassNames } from '@coreHelpers/utils';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './AuthContentHeader.scss';

export const AuthContentHeader = (props: any) => {
  const { title, subtitle, buttonConfig, isWithoutMargin } = props;
  const navigate = useNavigate();
  const classNames = concatClassNames({
    'auth-content-header--no-margin': isWithoutMargin
  });

  return <>
    <div className={`auth-content-header ${classNames}`}>
      <div className="auth-content-header__title text--h2">
        {title}
      </div>
      <div className="text--body-regular text--secondary">
        {subtitle}
      </div>
    </div>
    {
      buttonConfig && <>
        <Button fullWidth
                variant="contained"
                color="primary"
                onClick={() => navigate(buttonConfig.navigate || '/login')}
                size={'large'}
        >
          {buttonConfig.title}
        </Button>
      </>
    }
  </>;
};
