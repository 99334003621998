import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, Popover, Typography } from '@mui/material';
import { getIcon } from '../CustomIcon/CustomIcon';
import { FilterTypeEnum } from '@store/generated-models';
import { FilterItem } from './FilterItem';
import { Sorter } from './Sorter';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import useDebounce from '@coreHooks/useDebounce';
import './Filters.scss';

export const Filters = (props: any) => {
  const { columns, onChange } = props;
  const [filterButton, setFilterButton] = React.useState<HTMLButtonElement | null>(null);
  const [activeItems, setActiveItems] = useState<any>(0);
  const [filters, setFilters] = useState<any>({});
  const [sorters, setSorters] = useState<any>({});
  const { isPhone, isMobile } = useContext(ScreenContext);

  const open = Boolean(filterButton);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterButton(event.currentTarget);
  };

  const handleClose = () => {
    setFilterButton(null);
  };

  const updateFilter = (fieldName: string, type: FilterTypeEnum, value: any) => {
    setFilters({
      ...filters,
      [fieldName]: {
        field: fieldName,
        fieldFilters: value
      }
    });
  };

  const updateSorter = (fieldName: string, value: any) => setSorters({
    [fieldName]: value
  });

  const clearFilters = () => {
    setFilters({});
  };

  const resetSorting = () => {
    setSorters({});
  };

  const debouncedFilters = useDebounce(filters, 300);
  const debouncedSorters = useDebounce(sorters, 300);

  useEffect(() => {
    const newFilters = Object.entries(debouncedFilters).filter((filter: any) => {
      const filterInstance = filter[1].fieldFilters;
      const isWithValue = filterInstance.some((instance: any) => instance && instance.value[0] && instance.value[0]);

      return filterInstance && filterInstance.length > 0 && isWithValue;
    }).map(filter => filter[1]);
    const newSorters = Object.entries(debouncedSorters)
      .filter(sorter => sorter[1] !== '')
      .map(sorter => ({ orderBy: sorter[0], desc: sorter[1] === 'true' }));

    setActiveItems((Object.keys(newFilters).length + Object.keys(newSorters).length) || 0);
    onChange(newFilters, newSorters);
  }, [debouncedFilters, debouncedSorters]);

  const filterColumns = columns.filter((column: any) => Boolean(column.filter));
  const isWithFilters = filterColumns.length > 0;
  const sorterColumns = columns.filter((column: any) => Boolean(column.sorter));
  const isWithSorters = sorterColumns.length > 0;
  const filtersStyle = isWithSorters && !isPhone ? { paddingRight: '20px', borderRight: '1px solid #E1E2E3' } : {};
  const sortersStyle = !isPhone ? { paddingLeft: '20px' } : {};

  const buttonStyles = isPhone ? {
    minWidth: '42px',
    height: '42px'
  } : {};

  return (isWithFilters || isWithSorters) && <>
    <Popover
      open={open}
      anchorEl={filterButton}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: isMobile ? 'center' : 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: isMobile ? 'center' : 'right'
      }}
      PaperProps={{
        sx: {
          maxHeight: 'calc(100vh - 140px)',
          minWidth: isWithSorters && !isPhone ? '544px' : 'calc(100vw - 32px)',
          maxWidth: isWithSorters && !isPhone ? '544px' : 'calc(100vw - 32px)',
          marginTop: '8px',
          padding: '20px 16px'
        }
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={isWithSorters ? 6 : 12} style={filtersStyle}>
          <Box className="filters">
            <div className="filters__header">
              <span>Filters</span>
              <span className="filters__clear" onClick={clearFilters}>Clear all</span>
            </div>
            <div className="filters__content">
              {
                filterColumns.map((column: any) => <React.Fragment key={column.code}>
                  <FilterItem title={column.label} filter={column.filter} value={filters[column.code]?.fieldFilters}
                              onChange={(type: FilterTypeEnum, value: any) => updateFilter(column.code, type, value)}
                  />
                </React.Fragment>)
              }
            </div>
          </Box>
        </Grid>
        {
          isWithSorters && <Grid item xs={12} sm={6} style={sortersStyle}>
            <Box className="filters">
              <div className="filters__header">
                <span>Sort</span>
                <span className="filters__clear" onClick={resetSorting}>Reset</span>
              </div>
              <div className="filters__content">
                {
                  sorterColumns.map((column: any) => <React.Fragment key={column.code}>
                    <Sorter title={column.label} config={column.sorter} value={sorters[column.code]}
                            onChange={(value: any) => updateSorter(column.code, value)}
                    />
                  </React.Fragment>)
                }
              </div>
            </Box>
          </Grid>
        }
      </Grid>
    </Popover>
    <Button onClick={handleClick}
            variant="contained"
            color="primaryLight"
            className={`filters__button ${open && 'filters__button--active'}`}
            style={buttonStyles}
    >
      {getIcon('filter', 'grey')}
      {
        !isPhone && <Typography style={{ marginLeft: '8px' }}>
          Filters & Sort
        </Typography>
      }
      {
        activeItems > 0 && <span className="filters__active-label">{activeItems}</span>
      }
    </Button>
  </>;
};
