import React, { useContext, useEffect, useMemo, useState } from 'react';
import { privateRoutes } from '@businessRoutes/privateRoutes';
import { AuthContext } from '@coreProviders/AuthProvider';
import { Sidebar } from '@coreComponents/base/Sidebar/Sidebar';
import { Toolbar } from '@coreComponents/base/Toolbar/Toolbar';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { Routes } from 'react-router-dom';
import { AppBar } from '@mui/material';
import './PrivateSection.scss';

const Layout = (props: any) => {
  const [open, setOpen] = useState(false);
  const { isMobile } = useContext(ScreenContext);

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  return (
    <>
      <AppBar position="fixed"
              sx={{
                backgroundColor: isMobile ? '#1E232E' : 'inherit',
                boxShadow: 'none',
                color: isMobile ? '#fff' : '#000000'
              }}
      >
        <Toolbar handleDrawerOpen={handleDrawerOpen} open={open}/>
      </AppBar>
      <Sidebar handleDrawerClose={handleDrawerClose}
               handleDrawerOpen={handleDrawerOpen}
               open={open}
      />
    </>
  );
};

const Content = React.memo(() => {
  const authContext = useContext(AuthContext);

  return (
    <Routes>
      {privateRoutes(authContext)}
    </Routes>
  );
});

export const PrivateSection: React.FC<any> = () => {
  const authContext = useContext(AuthContext);
  const screenContext = useContext(ScreenContext);

  const { isPhone, isMobile } = screenContext;

  return useMemo(() => {
    return (
      <div className="page">
        <Layout/>
        <div className="page__content">
          <Content/>
        </div>
      </div>
    );
  }, [authContext.isInitialized, isPhone, isMobile]);
};
