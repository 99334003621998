import { Button, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { DECLINE_ROLLING_FUND_PARTICIPATION_REQUEST } from '@store/mutations/participationRequest';
import { Modal } from '@coreComponents/base/Modal/Modal';

export const useDeclineModal = (request: any) => {
  const [isOpenDecline, setIsOpenDecline] = useState(false);
  const [declineComment, setDeclineComment] = useState('');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [declineParticipationRequest, { loading }] = useMutation(DECLINE_ROLLING_FUND_PARTICIPATION_REQUEST);
  const handleAcceptDecline = async () => {
    await declineParticipationRequest({
      variables: {
        participationRequestId: request.rollingFundPRequestId,
        comment: declineComment
      }
    }).then(() => {
      navigate('/private/fund-requests');
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { ...{ variant: 'error' } });
    });
  };

  const handleDeclineComment = (event: any) => {
    setDeclineComment(event.target.value);
  };

  const modal = <Modal open={isOpenDecline} title="Decline Request" renderActions={() => <>
    <Button onClick={() => setIsOpenDecline(false)} disabled={loading}>No</Button>
    <Button onClick={handleAcceptDecline} disabled={loading}>Yes</Button>
  </>}
  >
    <>
      <Typography sx={{ marginBottom: '16px' }}>
        Please leave a comment on why you declined the application
      </Typography>
      <TextField label="Comment" name="declineComment" variant="outlined" multiline rows={5}
                 fullWidth
                 onChange={handleDeclineComment} value={declineComment}/>
    </>
  </Modal>;

  return {
    modal,
    setIsOpenDecline
  };
};
