import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Chip, Grid, Link, Skeleton, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { RollingFund, RollingFundApprovalStatusEnum, RollingFundStatusEnum } from '@store/generated-models';
import DataTable, { COLUMN_TYPE } from '@coreComponents/base/DataTable/DataTable';
import { ComplexAvatar } from '@coreComponents/base/ComplexAvatar/ComplexAvatar';
import { Panel } from '@coreComponents/base/Panel/Panel';
import { AuthContext } from '@coreProviders/AuthProvider';
import { numberFormat } from '@coreHelpers/utils';
import { GET_FUNDS } from '@store/queries/funds';
import { getIcon } from '@coreComponents/base/CustomIcon/CustomIcon';
import { PERIOD_TYPES } from '@businessConstants/enums';
import { PageToolbar } from '@coreComponents/base/PageToolbar/PageToolbar';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { useFilters } from '@coreHooks/useFilters';
import { Filters } from '@coreComponents/base/DataTable/Filters';
import { Search } from '@coreComponents/base/DataTable/Search';

export const FundsTable: FC<any> = (props) => {
  const { toolbarItem } = props;
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);
  const { queryVariables, onFilterChange, onSearchChange } = useFilters();
  const authContext = useContext(AuthContext);

  const { data, refetch, loading } = useQuery(GET_FUNDS, {
    variables: {
      filterEx: queryVariables.queryFilters,
      orderBy: queryVariables.sorters
    },
    fetchPolicy: 'network-only'
  });
  const [funds, setFunds] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
    } else {
      setFunds(data?.getRollingFunds?.list || []);
    }
  }, [data]);

  const columns = [
    {
      code: 'title',
      label: 'Fund Name',
      width: '200px',
      render: (row: any, column: any) => {
        const title = row['title'],
          avatar = row['avatar'],
          link = avatar ? `${process.env.REACT_APP_API_URL_REST}${avatar}` : '';

        return <ComplexAvatar width={32} height={32} src={link} label={title}/>;
      },
      loadingRender: () => {
        return <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 0, marginRight: '16px' }}>
            <Skeleton variant="circular" width={32} height={32}/>
          </div>
          <div style={{ flex: 1 }}>
            <Skeleton variant="text"/>
          </div>
        </div>;
      }
    },
    {
      code: 'startDate',
      label: 'Start date',
      width: '50px',
      emptyPlaceholder: 'Coming soon',
      type: COLUMN_TYPE.DATE,
      filter: {
        type: 'date'
      }
    },
    {
      code: 'minPeriod',
      label: 'Min. period',
      width: '100px',
      emptyPlaceholder: 'N/A',
      render: (row: RollingFund, column: any) => {
        return <>
          {row[column.code as keyof RollingFund]} {PERIOD_TYPES[row.minPeriodType]}
        </>;
      }
    },
    {
      code: 'minAmount',
      label: 'Min. amount',
      width: '80px',
      contentAlign: 'center',
      emptyPlaceholder: 'N/A',
      render: (row: any, column: any) => {
        const value = row[column.code];
        return `$${value ? numberFormat(value) : 0}`;
      }
    },
    {
      code: 'managementFee',
      label: 'Management fee',
      width: '80px',
      contentAlign: 'center',
      emptyPlaceholder: 'N/A',
      render: (row: any, column: any) => {
        return <>
          {row[column.code] || 0}%
        </>;
      }
    },
    {
      code: 'status',
      label: 'Status',
      width: '80px',
      render: (row: any, column: any) => {
        const value = row[column.code];

        return <Chip label={value === RollingFundStatusEnum.Inactive ? 'Inactive' : 'Active'}
                     sx={{
                       color: value === RollingFundStatusEnum.Inactive ? '#7A858F' : '#2BB596', width: '80px',
                       backgroundColor: value === RollingFundStatusEnum.Inactive ? '#E1E2E3' : '#D6F5EE'
                     }}
        />;
      },
      sorter: {
        options: [
          {
            label: 'Active',
            value: false
          },
          {
            label: 'Inactive',
            value: true
          }
        ]
      }
    },
    {
      code: 'approvalStatus',
      label: 'Approval Status',
      width: '80px',
      render: (row: any, column: any) => {
        const value = row[column.code];

        return <Chip label={value === RollingFundApprovalStatusEnum.Declined ? 'Not approved' : 'Approved'}
                     sx={{
                       color: value === RollingFundApprovalStatusEnum.Declined ? '#7A858F' : '#2BB596', width: '128px',
                       backgroundColor: value === RollingFundApprovalStatusEnum.Declined ? '#E1E2E3' : '#D6F5EE'
                     }}
        />;
      },
      sorter: {
        options: [
          {
            label: 'Approved',
            value: false
          },
          {
            label: 'Not Approved',
            value: true
          }
        ]
      }
    },
    {
      code: 'introductionLink',
      label: '',
      width: '120px',
      contentAlign: 'center',
      emptyPlaceholder: 'N/A',
      render: (row: any, column: any) => {
        const value = row[column.code];

        return value ?
          <Link href={value}
                onClick={(event) => event.stopPropagation()}
                target="_blank" underline="hover"
                style={{ display: 'flex', alignItems: 'center' }}
          >
            <span style={{ marginRight: '8px' }}>{getIcon('file')}</span>
            <Typography variant="subtitle2">Fund intro</Typography>
          </Link> :
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '8px' }}>{getIcon('file', 'stroke-light-blue')}</span>
            <Typography variant="subtitle2" sx={{ color: '#b8bbff' }}>Fund intro</Typography>
          </div>;
      }
    }
  ];

  const rowOnClick = (row: any) => {
    const id = row.rollingFundId;

    if (id) {
      navigate(`/private/fund-detailed/${id}`);
    }
  };

  useEffect(() => {
    refetch();
  }, [queryVariables]);

  const filterBlockStyles: any = {
    display: 'flex',
    justifyContent: !isMobile ? 'end' : 'normal',
    flex: 1,
    marginLeft: isPhone ? 'inherit' : 'auto',
    position: isMobile ? 'fixed' : 'inherit',
    right: isPhone ? '16px' : '24px',
    left: isPhone ? '16px' : 'auto'
  };

  const getToolbarContainerHeight = () => {
    let height = '48px';

    if (isPhone) {
      height = '112px';
    }

    return height;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ minHeight: getToolbarContainerHeight() }}>
          <PageToolbar
            leftSide={
              <>
                {
                  !isPhone && !authContext.isUser && <RouterLink to="/private/fund-detailed" style={{ textDecoration: 'none' }}>
                    <Button variant="contained">Create fund</Button>
                  </RouterLink>
                }
                <div style={filterBlockStyles}>
                  <Filters onChange={onFilterChange} columns={columns}/>
                  <Search placeholder="Search fund" onChange={onSearchChange} field="title"/>
                  {isMobile && toolbarItem}
                </div>
                {
                  isPhone && <div style={{ marginTop: '54px' }}>
                    <RouterLink to="/private/fund-detailed" style={{ textDecoration: 'none', width: '100%' }}>
                      <Button variant="contained" style={{ height: '42px', width: 'calc(100vw - 32px)' }}>
                        Create fund
                      </Button>
                    </RouterLink>
                  </div>
                }
              </>
            }
            rightSide={!isMobile && toolbarItem}
          />
        </Grid>
        <Grid item xs={12}>
          <Panel>
            {
              <DataTable rows={funds} columns={columns} isEditOnClick rowOnClick={rowOnClick} isWithPagination
                         isLoading={loading}/>
            }
          </Panel>
        </Grid>
      </Grid>
    </>
  );
};
