import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Transaction } from './Transaction';
import { Panel } from '@coreComponents/base/Panel/Panel';
import { PRequestActionLogEntry } from '@store/generated-models';

export const TransactionList = (props: any) => {
  const { request } = props;

  const actions = request.userActionLog;
  const transactions = actions.map((action: PRequestActionLogEntry) => {
    return {
      status: action.userAction.actionType,
      date: action.userAction.date,
      allocations: action.tokenAllocation,
      info: JSON.parse(action.userAction.info),
      comment: action.comment
    };
  });

  return <>
    <Panel>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ fontSize: '20px', fontWeight: 500 }}>
            History
          </Typography>
        </Grid>
        <Grid item>
          {
            transactions.map((transaction: any, index: number) => <Transaction key={index} request={request} data={transaction}/>)
          }
        </Grid>
      </Grid>
    </Panel>
  </>;
};
