import { Link } from 'react-router-dom';
import './TextLink.scss';

/*TODO: rename*/
export const TextLink = (props: any) => {
  const { text, startIcon, to, classNames } = props;

  return <Link className={`text-link ${classNames}`} to={to}>
    <div className="text-link__content">
      {startIcon} {text}
    </div>
  </Link>;
};

export const ExternalLink = (props: any) => {
  const { text, startIcon, to, classNames } = props;

  return <a className={`text-link ${classNames}`} href={to} target={'_blank'}>
    <div className="text-link__content">
      {startIcon} {text}
    </div>
  </a>;
};
