import React, { FC, MouseEvent, useCallback, useContext, useState } from 'react';
import { Box, Button, Chip, Grid, TextField, Typography } from '@mui/material';
import { ProjectPRequest, ParticipationRequestStatusEnum } from '@store/generated-models';
import { AuthContext } from '@coreProviders/AuthProvider';
import { useMutation } from '@apollo/client';
import { WITHDRAW_PARTICIPATION_REQUEST } from '@store/mutations';
import { useNavigate } from 'react-router-dom';
import { numberFormat } from '@coreHelpers/utils';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { Modal } from '@coreComponents/base/Modal/Modal';
import { RequestStatusChip } from '@businessComponents/RequestStatusChip/RequestStatusChip';
import { Social } from '@businessComponents/Social/Social';
import { Panel } from '@coreComponents/base/Panel/Panel';
import appConfig from '@src/config';

type OwnProps = {
  request: ProjectPRequest
};
type RequestSummaryProps = FC<OwnProps>;

export const RequestSummary: RequestSummaryProps = ({ request }) => {
  const { contract } = request;
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [isOpenWithdrawDialog, setIsOpenWithdrawDialog] = useState(false);
  const [isOpenChangeTermsDialog, setIsOpenChangeTermsDialog] = useState(false);
  const [withdrawComment, setWithDrawComment] = useState('');
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  const handleWithdraw = useCallback((_e: MouseEvent<HTMLButtonElement>) => {
    setIsOpenWithdrawDialog(true);
  }, []);

  const navigateToEditRequest = useCallback(() => {
    navigate(`/private/change-request/${request.projectPRequestId}`);
  }, [request.projectPRequestId]);

  const handleChangeTerms = (_e: MouseEvent<HTMLButtonElement>) => {
    if (request.status === ParticipationRequestStatusEnum.Approved) {
      setIsOpenChangeTermsDialog(true);
    } else {
      navigateToEditRequest();
    }
  };

  const [withdrawParticipationRequest, {
    loading: loadingWithdraw
  }] = useMutation(WITHDRAW_PARTICIPATION_REQUEST);
  const handleAcceptWithdraw = useCallback(async () => {
    try {
      await withdrawParticipationRequest({
        variables: {
          participationRequestId: request.projectPRequestId,
          comment: withdrawComment
        }
      });
      navigate('/private/investor-requests');
    } catch (e) {
    }
  }, [withdrawComment]);

  const handleWithdrawComment = (event: any) => {
    setWithDrawComment(event.target.value);
  };

  return (
    <>
      <Modal
        open={isOpenWithdrawDialog}
        title="Withdraw Request?"
        renderActions={() => <>
          <Button onClick={() => setIsOpenWithdrawDialog(false)} disabled={loadingWithdraw}>No</Button>
          <Button onClick={handleAcceptWithdraw} disabled={loadingWithdraw}>Yes</Button>
        </>}
      >
        <>
          <Typography sx={{ marginBottom: '16px' }}>
            {`Are you sure you want to withdraw the participation request for project "${request.project.title}"?`}
          </Typography>
          <TextField label="Comment" name="withdrawComment" variant="outlined" multiline rows={5}
                     fullWidth
                     onChange={handleWithdrawComment} value={withdrawComment}/>
        </>
      </Modal>
      <Modal
        open={isOpenChangeTermsDialog}
        onDecline={() => {
          setIsOpenChangeTermsDialog(false);
        }}
        onAccept={navigateToEditRequest}
      >
        <>
          <Typography sx={{ marginBottom: '16px' }}>
            {'Your request was already approved. Are you sure you want to change the request terms and resend your request for a new approval?'}
          </Typography>
        </>
      </Modal>

      <Panel>
        <Grid container>
          <Grid item xs={12} sx={{ marginBottom: '32px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h2" sx={{ fontSize: '20px', fontWeight: 500, marginRight: '24px' }}>
                {request.project.title}
              </Typography>
              <RequestStatusChip status={request.status} sx={{ width: '86px', fontSize: '12px' }}/>
              {
                request.isLegalPerson && <Chip sx={{ fontSize: '12px', marginLeft: '16px' }} color="error" label="KYB"/>
              }
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Grid container>
                <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="viewModeLabel" color="text.secondary" component="span">
                        Investor name
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="viewModeValue">
                        {request.user.fullName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="viewModeLabel" color="text.secondary" component="span">
                        Fund name
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="viewModeValue">
                        {request.fundName || 'N/A'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="viewModeLabel" color="text.secondary" component="span">
                        Social links
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{ display: 'flex' }}>
                        {request.userSocials && request.userSocials.length && request.userSocials
                          .map((socialData: Record<string, any>, idx: number) =>
                            <React.Fragment key={idx}>
                              <Social key={idx} socialCode={socialData.social.socialCode} href={socialData.href}/>
                            </React.Fragment>
                          ) || <Typography variant="viewModeValue">N/A</Typography>}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="viewModeLabel" color="text.secondary" component="span">
                        Communities and influencers
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{ display: 'flex' }}>
                        {request.communitySocials && request.communitySocials.length && request.communitySocials
                          .map((socialData: Record<string, any>, idx: number) =>
                            <React.Fragment key={idx}>
                              <Social key={idx} socialCode={socialData.social.socialCode} href={socialData.href}/>
                            </React.Fragment>
                          ) || <Typography variant="viewModeValue">N/A</Typography>}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                {request.isLegalPerson &&
                  <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant="viewModeLabel" color="text.secondary" component="span">
                          KYB verification
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex' }}>
                          <Social socialCode="link" href={appConfig.kybUrl}/>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                }

                <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="viewModeLabel" color="text.secondary" component="span">
                        Investment
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="viewModeValue">
                        {numberFormat(request.investmentAmount)} {request.investmentCurrency}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={0} sm={1}>
            <div style={{ display: 'flex', height: '100%', justifyContent: 'center' }}>
              <div style={{ width: '1px', borderLeft: '1px solid #D6D6D6' }}/>
            </div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Grid container sx={{ marginBottom: '24px' }}>
              <Grid item xs={5} md={4}>
                <Box>
                  <Typography variant="viewModeLabel" color="text.secondary" component="span">
                    How did YOU find out about Roller?
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1}/>
              <Grid item xs={6} md={7}>
                <Typography variant="viewModeValue">
                  {request.leadSource || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={5} md={4}>
                <Box>
                  <Typography variant="viewModeLabel" color="text.secondary" component="span">
                    Strategic value
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1}/>
              <Grid item xs={6} md={7}>
                <Typography variant="viewModeValue">
                  {request.contribution || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {!contract?.isComplete && authContext.isUser && authContext.user.userId === request.user.userId &&
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '64px' }}>
                <Button variant="outlined"
                        sx={{ width: { xs: '140px', md: 'auto' } }}
                        onClick={handleWithdraw} color="error"
                >{isPhone ? 'Withdraw' : 'Withdraw Request'}</Button>
                <Button variant="contained"
                        sx={{ width: { xs: '140px', md: 'auto' } }}
                        onClick={handleChangeTerms}

                >Change Terms</Button>
              </Box>
            </Grid>
          }
        </Grid>
      </Panel>
    </>
  );
};
