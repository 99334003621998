import React, { useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { LoginResult } from '@store/generated-models';
import { AuthContext } from '@coreProviders/AuthProvider';
import { useSnackbar } from 'notistack';
import { Button, Grid } from '@mui/material';
import { useMutation } from '@apollo/client';
import { DISABLE_2FA } from '@store/mutations/users';

export interface ComponentProps {
  onCancel: () => void;
}

export const Step2faDisable: React.FC<ComponentProps> = ({ onCancel }) => {

  const authContext = useContext(AuthContext);
  const [disable2fa] = useMutation<{ disable2fa: LoginResult }>(DISABLE_2FA);
  const [authCode, setAuthCode] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const setDisable2fa = async () => {
    let errMessage: string = '';
    try {
      const res = await disable2fa({
        variables: {
          code: authCode,
          password: 'ignored'
        }
      });

      if (res.data.disable2fa.user.is2faEnabled === false) {
        authContext.updateUser({ is2faEnabled: false });
        enqueueSnackbar('2FA has been disabled', { variant: 'success' });
        return;
      } else {
        errMessage = 'Wrong Code, please try again';
      }
    } catch (error: any) {
      try {
        const errorCode = error.graphQLErrors[0].extensions.code;
        if (errorCode === 'auth.access_denied') errMessage = 'Access denied';
        if (!errMessage && error.graphQLErrors[0].message) errMessage = error.graphQLErrors[0].message;
      } catch (ignored) {
      }
    }

    if (!errMessage) errMessage = 'Unknown error';
    enqueueSnackbar(errMessage, { variant: 'error' });
  };

  return (
    <Grid container spacing={0}>
      <Grid item md={6}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography component="span" sx={{ fontSize: '28px', fontWeight: 600, color: '#000000' }}>2FA
              Code</Typography>
          </Grid>
          <Grid item md={12}>
            <Typography component="span" sx={{ fontSize: '14px', color: '#000000' }}>For disable Google 2FA enter the
              generated code in the input bellow.</Typography>
          </Grid>
          <Grid item md={12}>
            <TextField
              sx={{ width: '294px' }}
              variant="outlined"
              label="2FA Code"
              value={authCode}
              onChange={e => setAuthCode(e.target.value)}
            />
          </Grid>
          <Grid item md={12}>
            <Button
              sx={{ width: '140px', marginRight: '14px' }}
              variant="outlined"
              color="primary"
              size={'large'}
              onClick={() => onCancel()}
            >
              cancel
            </Button>
            <Button
              sx={{ width: '140px' }}
              variant="contained"
              color="primary"
              size={'large'}
              disabled={!authCode}
              onClick={setDisable2fa}
            >
              Disable 2FA
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6}></Grid>
    </Grid>
  );
};
