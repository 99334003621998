import React, { useState } from 'react';
import MuiFormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import styled, { css } from 'styled-components';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const FormControl = styled(MuiFormControl)<{ mb: number }>`
  ${props => props.mb && css`
      margin-bottom: ${props.theme.spacing(props.mb)}px;
`}`;

interface ComponentProps {
  id?: string;
  label?: any;
  value?: string;
  name?: string;
  helperText?: string;
  onChange?: (e: any) => void;
  size?: 'small' | 'medium',
  fullWidth?: boolean,
  mb?: number,
  disabled?: boolean,
  readOnly?: boolean,
  autoComplete?: string,
  style?: any,
  error?: any
}

export const Password: React.FC<ComponentProps> = (props) => {
  const {id, label, name, value, onChange, error, size, fullWidth, mb, style} = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(oldVal => !oldVal);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <FormControl
      size={size}
      fullWidth={fullWidth}
      mb={mb}
      style={style}
      variant="outlined"
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        id={id}
        name={name}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        error={error}
        label={label}
        endAdornment={
          <InputAdornment position="end" sx={{margin: '0 8px'}}>
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility/> : <VisibilityOff/>}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );

};
