import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import { insertText, numberFormat } from '@coreHelpers/utils';

interface NumberInputProps {
  name?: string,
  label?: string,
  required?: boolean,
  precision?: number,
  value?: any,
  onChange?: Function,
  fullWidth?: boolean,
  variant?: 'standard' | 'filled' | 'outlined',
  error?: boolean,
  InputProps?: any
}

enum INPUT_ACTIONS {
  TYPE,
  DELETE,
  MOVE
}

export const NumberInput = (props: NumberInputProps) => {
  const {
    name,
    label,
    required,
    variant,
    error,
    precision = 0,
    value,
    onChange: handleChange,
    InputProps,
    fullWidth
  } = props;
  const [state, setState] = useState({
    inputValue: '0',
    desiredValue: numberFormat(value, { precision }),
    caretPosition: 0,
    expectedCaretPosition: 0
  });

  const inputRef = useRef();
  const inputReference = inputRef.current as HTMLInputElement;

  const onChange = (event: any) => {
    const numericValue = getNumericWithDecimal(state.desiredValue);
    const newInputValue = getFormattedValue(numericValue);

    handleChange(event, numericValue);
  };

  const getNumericWithDecimal = (number: any) => {
    const stringNumber = String(number);
    const decimalIndex = stringNumber.indexOf('.');
    const isEditingDecimals = decimalIndex < state.caretPosition;
    let [integerPart, decimalPart] = stringNumber.split('.');

    if (isEditingDecimals && decimalPart) {
      decimalPart = decimalPart.slice(0, precision);
    } else {

    }
    const resultNumber = `${integerPart.replaceAll(',', '')}.${decimalPart}`;

    return parseFloat(resultNumber);
  };

  const getFormattedValue = (numericValue: number) => {
    return numberFormat(numericValue, { precision });
  };

  useEffect(() => {
    setState({
      ...state,
      inputValue: numberFormat(value, { precision })
    });
  }, [value]);

  const checkCaretConstraints = (desiredCaretPosition: number, value: any) => {
    let newCaretPosition = desiredCaretPosition;

    if (desiredCaretPosition > value.length) {
      newCaretPosition = value.length;
    }
    if (desiredCaretPosition < 0) {
      newCaretPosition = 0;
    }

    return newCaretPosition;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const isNumericKey = !isNaN(Number(event.key));
    const isZero = Number(event.key) === 0;
    const oldValue = inputReference.value;

    const caretStart = inputReference.selectionStart;
    const caretEnd = inputReference.selectionEnd;
    const isSelection = caretStart !== caretEnd;

    let newValue = inputReference.value.slice(0);
    let valueToDelete = '';

    const stringNumber = String(oldValue);
    const decimalIndex = stringNumber.indexOf('.');
    const isEditingDecimals = precision !== 0 && decimalIndex < caretStart;
    let newCaretPosition = 0;
    let currentAction = null;

    switch (event.code) {
      case 'Backspace':
        if (isSelection) {
          newCaretPosition = caretStart;
          valueToDelete = oldValue.slice(caretStart, caretEnd);
          if (isNaN(parseFloat(valueToDelete))) {
            currentAction = INPUT_ACTIONS.MOVE;
          } else {
            newValue = newValue.substring(0, caretStart) + newValue.substring(caretEnd, oldValue.length);
            currentAction = INPUT_ACTIONS.DELETE;
          }
        } else {
          newCaretPosition = caretStart - 1;
          valueToDelete = oldValue.slice(caretStart - 1, caretStart);
          if (isNaN(Number(valueToDelete)) || (isEditingDecimals && Number(valueToDelete) === 0)) {
            currentAction = INPUT_ACTIONS.MOVE;
          } else {
            newValue = newValue.substring(0, caretStart - 1) + newValue.substring(caretStart, oldValue.length);
            currentAction = INPUT_ACTIONS.DELETE;
          }
        }
        break;
      case 'Delete':
        if (isSelection) {
          newCaretPosition = caretStart;
          valueToDelete = oldValue.slice(caretStart, caretEnd);
          if (isNaN(parseFloat(valueToDelete))) {
            currentAction = INPUT_ACTIONS.MOVE;
          } else {
            newValue = newValue.substring(0, caretStart) + newValue.substring(caretEnd, oldValue.length);
            currentAction = INPUT_ACTIONS.DELETE;
          }
        } else {
          valueToDelete = oldValue.slice(caretStart, caretStart + 1);
          if (isNaN(Number(valueToDelete)) || (isEditingDecimals && Number(valueToDelete) === 0)) {
            currentAction = INPUT_ACTIONS.MOVE;
            newCaretPosition = caretStart + 1;
          } else {
            newValue = newValue.substring(0, caretStart) + newValue.substring(caretStart + 1, oldValue.length);
            currentAction = INPUT_ACTIONS.DELETE;
            newCaretPosition = caretStart;
          }
        }
        break;
      case 'ArrowUp':
        newCaretPosition = 0;
        currentAction = INPUT_ACTIONS.MOVE;
        break;
      case 'ArrowLeft':
        newCaretPosition = caretStart - 1;
        currentAction = INPUT_ACTIONS.MOVE;
        break;
      case 'ArrowDown':
        newCaretPosition = oldValue.length;
        currentAction = INPUT_ACTIONS.MOVE;
        break;
      case 'ArrowRight':
      case 'Space':
        newCaretPosition = caretStart + 1;
        currentAction = INPUT_ACTIONS.MOVE;
        break;
      default:
        newCaretPosition = caretStart + 1;
        if (isSelection) {
          valueToDelete = oldValue.slice(caretStart, caretEnd);
          if (isNaN(parseFloat(valueToDelete))) {
            currentAction = INPUT_ACTIONS.MOVE;
          } else {
            newValue = insertText(inputReference.value, caretStart, caretEnd - caretStart, event.key);
            currentAction = INPUT_ACTIONS.TYPE;
          }
        } else {
          currentAction = INPUT_ACTIONS.TYPE;
          if (isEditingDecimals) {
            if (isNumericKey && !isZero) {
              newValue = insertText(inputReference.value, caretStart, 1, event.key);
            }
          } else {
            if (isNumericKey) {
              newValue = insertText(inputReference.value, caretStart, 0, event.key);
            }
          }
        }
        break;
    }

    let [integerPart, decimalPart] = newValue.split('.');

    newValue = `${integerPart.replaceAll(',', '') || 0}${decimalPart?.replaceAll('0', '') ? '.' + decimalPart : ''}`;
    newValue = numberFormat(parseFloat(newValue), { precision });

    let oldThousands = (oldValue.substring(0, caretStart).match(/,/g) || []).length;
    let newThousands = (newValue.substring(0, newCaretPosition).match(/,/g) || []).length;

    if (oldValue === newValue) {
      event.preventDefault();
    }

    switch (currentAction) {
      case INPUT_ACTIONS.MOVE:
        newCaretPosition = checkCaretConstraints(newCaretPosition, oldValue);
        setState({
          ...state,
          caretPosition: newCaretPosition
        });
        break;
      case INPUT_ACTIONS.DELETE:
        newCaretPosition = newCaretPosition - Math.abs(oldThousands - newThousands);
        newCaretPosition = checkCaretConstraints(newCaretPosition, newValue);
        setState({
          ...state,
          desiredValue: newValue,
          caretPosition: newCaretPosition
        });
        break;
      case INPUT_ACTIONS.TYPE:
        if (!precision || caretStart < oldValue.length) {
          if (isSelection) {
            newCaretPosition = newCaretPosition - Math.abs(oldThousands - newThousands);
          } else {
            newCaretPosition = newCaretPosition + Math.abs(oldThousands - newThousands);
          }
          newCaretPosition = checkCaretConstraints(newCaretPosition, newValue);
          setState({
            ...state,
            desiredValue: newValue,
            caretPosition: newCaretPosition
          });
        }
        break;
    }
  };

  useEffect(() => {
    if (inputReference) {
      window.requestAnimationFrame(() => {
        inputReference.selectionStart = state.caretPosition;
        inputReference.selectionEnd = state.caretPosition;
      });
    }
  }, [state.inputValue, state.caretPosition]);

  return (
    <TextField name={name}
               label={label}
               required={required}
               onKeyDown={handleKeyDown}
               onChange={onChange}
               value={state.inputValue}
               variant={variant}
               fullWidth={fullWidth}
               error={error}
               InputProps={{
                 ...InputProps,
                 inputRef: inputRef
               }}
    />
  );
};
