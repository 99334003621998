import React, { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Badge, BadgeProps, Grid, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { useFilters } from '@coreHooks/useFilters';
import { AuthContext } from '@coreProviders/AuthProvider';
import { GET_FUND_PARTICIPATION_REQUESTS } from '@store/queries/requests';
import DataTable, { COLUMN_TYPE, DataTableColumn } from '@coreComponents/base/DataTable/DataTable';
import { getIcon } from '@coreComponents/base/CustomIcon/CustomIcon';
import { numberFormat } from '@coreHelpers/utils';
import {
  ParticipationRequestStatusEnum,
  PRequestSignatureStatusFilterEnum,
  RollingFundPRequest
} from '@store/generated-models';
import { PERIOD_TYPES } from '@businessConstants/enums';
import { RequestStatusChip } from '@businessComponents/RequestStatusChip/RequestStatusChip';
import { Panel } from '@coreComponents/base/Panel/Panel';
import { KycRestrictionPanel } from '@businessComponents/KYCRestriction/KycRestrictionPanel';
import { PageToolbar } from '@coreComponents/base/PageToolbar/PageToolbar';
import { Filters } from '@coreComponents/base/DataTable/Filters';
import { Search } from '@coreComponents/base/DataTable/Search';

const moment = require('moment');

export const FundRequests: FC<any> = () => {
  const [requests, setRequests] = useState([]);
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);
  const { queryVariables, onFilterChange, onSearchChange } = useFilters();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const isFullyVerified = authContext.isAdmin || authContext.isVerified
    && (authContext.user?.kybStatus === 'approved' || !authContext.isFundOwner);

  const {data, refetch, loading} = useQuery(GET_FUND_PARTICIPATION_REQUESTS, {
    variables: {
      filterEx: queryVariables.queryFilters,
      orderBy: queryVariables.sorters
    },
    fetchPolicy: 'network-only',
    skip: !isFullyVerified
  });

  useEffect(() => {
    if (loading || !isFullyVerified) {
    } else {
      setRequests(data.getRollingFundPRequests.list);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [queryVariables]);

  if (isFullyVerified) {
    const KYBBadge = styled(Badge)<BadgeProps>(({theme}) => ({
      '&.MuiBadge-root': {
        display: 'flex'
      },
      '& .MuiBadge-badge': {
        borderRadius: '50%',
        right: '-16px',
        top: 0,
        width: '24px',
        height: '24px'
      }
    }));

    const columns: DataTableColumn[] = [
      {
        code: 'fullName',
        label: 'Investor Name',
        style: {
          paddingRight: '32px'
        },
        isHidden: authContext.isUser,
        render: (row: any, column: any) => {
          const {isLegalPerson} = row;

          return <>
            {
              isLegalPerson ?
                <KYBBadge color="error" badgeContent={getIcon('suitcase')}>
                  <span>{row['user']['fullName']}</span>
                </KYBBadge>
                :
                <span>{row['user']['fullName']}</span>
            }
          </>;
        }
      },
      {
        code: 'fundName',
        label: 'Fund Name',
        style: {
          paddingRight: authContext.isUser ? '32px' : '16px'
        },
        render: (row: any) => {
          const {isLegalPerson} = row;

          return <>
            {
              authContext.isUser && isLegalPerson ?
                <KYBBadge color="error" badgeContent={getIcon('suitcase')}>
                  <span>{row['rollingFund']['title']}</span>
                </KYBBadge>
                :
                <span>{row['rollingFund']['title']}</span>
            }
          </>;
        }
      },
      {
        code: 'created',
        label: 'Request Date',
        type: COLUMN_TYPE.DATE,
        filter: {
          type: 'date'
        }
      },
      {
        code: 'investmentApprovedAmount',
        label: 'Amount',
        render: (row: any, column: any) => {
          return `${numberFormat(row[column.code] || row['investmentAmount'])} ${row['investmentCurrency']}`;
        },
        filter: {
          type: 'range',
          range: [0, 100000]
        }
      },
      {
        code: 'period',
        label: 'Period',
        render: (row: RollingFundPRequest, column: any) => {
          return <>
            {row[column.code as keyof RollingFundPRequest]} {PERIOD_TYPES[row.periodType]}
          </>;
        },
        sorter: {
          options: [
            {
              label: 'From less to more',
              value: false
            },
            {
              label: 'From more to less',
              value: true
            }
          ]
        }
      },
      {
        code: 'status',
        label: 'Request Status',
        align: 'center',
        render: (row: any, column: any) => {
          const value = row[column.code];
          return <div style={{display: 'flex', justifyContent: 'center'}}>
            <RequestStatusChip status={value} sx={{width: '100px'}}/>
          </div>;
        },
        filter: {
          type: 'checkbox',
          options: [
            {
              name: ParticipationRequestStatusEnum.Approved,
              label: 'Approved',
              value: ParticipationRequestStatusEnum.Approved
            },
            {
              name: ParticipationRequestStatusEnum.Declined,
              label: 'Declined',
              value: ParticipationRequestStatusEnum.Declined
            },
            {
              name: ParticipationRequestStatusEnum.Pending,
              label: 'Pending',
              value: ParticipationRequestStatusEnum.Pending
            }
          ]
        }
      },
      {
        code: 'saft',
        label: 'Contract',
        render: (row: any, column: any) => {
          const {contract} = row;
          return <>
            {contract?.created ? <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{marginRight: '8px'}}>{getIcon('file')}</span>
                <Link
                  underline="none"
                  target="_blank"
                  sx={{fontSize: '12px'}}
                >{moment(contract.created).format('DD.MM.YYYY')}</Link>
              </div> :
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{marginRight: '8px'}}>{getIcon('file', 'stroke-light-blue')}</span>
                <Typography variant="subtitle2" sx={{color: '#b8bbff'}}>Not signed</Typography>
              </div>
            }
          </>;
        }
      },
      {
        code: 'signatureStatus',
        label: 'Signatures',
        render: (row: any, column: any) => {
          const {status, contract, novations} = row;
          let signedByBuyer = contract && contract.signedByBuyer;
          let signedBySeller = contract && contract.signedBySeller;

          if (novations?.length > 0 || status === ParticipationRequestStatusEnum.Novation) {
            signedByBuyer = novations[0]?.signedByBuyer;
            signedBySeller = novations[0]?.signedBySeller;
          }

          const buyerSign = signedByBuyer ? 'checkboxChecked' : 'checkboxUnchecked';
          const sellerSign = signedBySeller ? 'checkboxChecked' : 'checkboxUnchecked';

          return <div style={{display: 'flex'}}>
            {novations?.length > 0 ?
              <>
                {getIcon(buyerSign)}
                {getIcon(sellerSign)}
              </>
              :
              <>
                {getIcon(buyerSign)}
                {getIcon(sellerSign)}
              </>
            }
          </div>;
        },
        filter: {
          type: 'checkbox',
          options: [
            {
              name: PRequestSignatureStatusFilterEnum.WaitingMe,
              label: 'Waiting for your signature',
              value: PRequestSignatureStatusFilterEnum.WaitingMe
            },
            {
              name: PRequestSignatureStatusFilterEnum.WaitingOther,
              label: 'Waiting for signature',
              value: PRequestSignatureStatusFilterEnum.WaitingOther
            },
            {
              name: PRequestSignatureStatusFilterEnum.Signed,
              label: 'Signed',
              value: PRequestSignatureStatusFilterEnum.Signed
            }
          ]
        }
      },
      {
        code: 'isLegalPerson',
        label: 'KYB',
        isHidden: true,
        sorter: {
          options: [
            {
              label: 'With KYB',
              value: true
            },
            {
              label: 'Without KYB',
              value: false
            }
          ]
        }
      }
    ];

    const rowOnClick = (row: RollingFundPRequest) => {
      const id = row.rollingFundPRequestId;

      if (id) {
        navigate(`/private/fund-request-detailed/${id}`);
      }
    };

    const filterBlockStyles: any = {
      display: 'flex',
      justifyContent: !isMobile ? 'end' : 'normal',
      flex: 1,
      marginLeft: isPhone ? 'inherit' : 'auto',
      position: isMobile ? 'fixed' : 'inherit',
      right: isPhone ? '16px' : '24px',
      left: isPhone ? '16px' : 'auto'
    };

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{minHeight: isPhone ? '60px' : '48px'}}>
            <PageToolbar
              leftSide={
                <div style={filterBlockStyles}>
                  <Filters onChange={onFilterChange} columns={columns}/>
                  <Search placeholder="Search Investor or Fund" onChange={onSearchChange} fullWidth={isPhone}/>
                </div>
              }/>
          </Grid>
          <Grid item xs={12}>
            <Panel>
              <DataTable rows={requests} columns={columns} isEditOnClick rowOnClick={rowOnClick} isWithPagination
                         isLoading={loading}/>
            </Panel>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <KycRestrictionPanel/>;
  }
};
