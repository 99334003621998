export type DocumentView = {
  documentId: string
  label: string
  labelColor: string
  actionHandler: any
}

export const getDocumentsViewData = (
  request: any, authContext: any,
  handleSignDocument: any, // Todo 'getDocumentsViewData': Remove this arg for general func.
  handleDownloadDocument: any // Todo 'getDocumentsViewData': Remove this arg for general func.
) => {
  const L_WAITING_FOR_INVESTOR: string = 'waiting for Investor’s signature';
  const L_WAITING_FOR_ADMIN: string = 'waiting for Admin’s signature';
  const L_WAITING_FOR_CONTRACT_SIGNER: string = 'waiting for Admin’s signature';
  const L_WAITING_FOR_YOU: string = 'waiting for your signature';
  const C_WAITING_FOR_OTHER: string = '#7A858F';
  const C_WAITING_FOR_YOU: string = '#E29F50';
  const C_COMPLETE: string = '#2BB596';

  let saftIsShown = false;
  let saftView: DocumentView = {
    documentId: null,
    label: 'sent',
    labelColor: null,
    actionHandler: null
  };

  const { contract, novations } = request;
  const userIsBuyer = contract?.buyerId === authContext.user.userId;
  const userIsSeller = authContext.isAdmin
    || authContext.isFundOwner && contract?.sellerId === authContext.user.userId;

  let currentUserAction: {label: string, actionHandler: any};
  if (contract?.isComplete && (userIsSeller || userIsBuyer)) {
    saftView.label = 'countersigned'; // Review final countersigned version.
    saftView.actionHandler = () => {
      handleDownloadDocument(contract.contractId);
    };
    saftView.labelColor = C_COMPLETE;
    saftIsShown = true;
  } else {
    if (userIsSeller) { // If I am a Seller (Admin).
      if (contract?.signedByBuyer) { // Contract is signed by Buyer
        if (!contract?.signedBySeller) { // and is waiting for Seller signature.
          if (authContext.isContractSigner) {
            saftView.label = L_WAITING_FOR_YOU;
            saftView.actionHandler = () => {
              handleSignDocument(contract.contractId, true);
            };
            saftView.labelColor = C_WAITING_FOR_YOU;
            saftIsShown = true;

            currentUserAction = {
              label: 'Sign contract',
              actionHandler: saftView.actionHandler
            };
          } else {
            saftView.label = L_WAITING_FOR_CONTRACT_SIGNER;
            saftView.actionHandler = () => {
              handleDownloadDocument(contract.contractId);
            };
            saftView.labelColor = C_WAITING_FOR_OTHER;
            saftIsShown = true;
          }
        }
      } else if (!userIsBuyer) { // Additional check if I am both buyer and seller (for tests).
        // Contract is waiting for Buyer signature, but Admin can still download and review draft.
        saftView.label = L_WAITING_FOR_INVESTOR;
        saftView.actionHandler = () => {
          handleDownloadDocument(contract.contractId);
        };
        saftView.labelColor = C_WAITING_FOR_OTHER;
        saftIsShown = true;
      }
    } else if (userIsBuyer) { // If I am a Buyer (Investor).
      if (!contract?.signedByBuyer) { // Contract is waiting for Buyer signature.
        saftView.label = L_WAITING_FOR_YOU;
        saftView.actionHandler = () => {
          handleSignDocument(contract.contractId);
        };
        saftView.labelColor = C_WAITING_FOR_YOU;
        saftIsShown = true;

        currentUserAction = {
          label: 'Sign contract',
          actionHandler: saftView.actionHandler
        };
      } else if (!userIsSeller) { // Additional check if I am both buyer and seller (for tests).
        // Contract is waiting for Seller signature, but Buyer can still download and review draft.
        saftView.label = L_WAITING_FOR_ADMIN;
        saftView.actionHandler = () => {
          handleDownloadDocument(contract.contractId);
        };
        saftView.labelColor = C_WAITING_FOR_OTHER;
        saftIsShown = true;
      }
    }
  }

  let novationViews: DocumentView[] = [];
  for (let i = 0; i < novations?.length; i++) {
    let novationIsShown = false;
    let novationLabelColor = '';
    let novationLabel = 'sent';
    let handleNovationAction: (documentId: any) => void;
    const novation = novations[i];
    const userIsBuyer = novation.buyerId === authContext.user.userId;
    const userIsSeller = authContext.isAdmin;

    if (novation.isComplete && (userIsSeller || userIsBuyer)) {
      novationLabel = 'countersigned'; // Review final countersigned version.
      handleNovationAction = handleDownloadDocument;
      novationLabelColor = C_COMPLETE;
      novationIsShown = true;
    } else {
      if (userIsBuyer) { // If I am a Buyer (Investor).
        if (!novation.signedByBuyer) { // Novation is waiting for Buyer signature.
          novationLabel = L_WAITING_FOR_YOU;
          handleNovationAction = handleSignDocument;
          novationLabelColor = C_WAITING_FOR_YOU;
          novationIsShown = true;

          currentUserAction = {
            label: 'Sign Novation',
            actionHandler: () => {
              handleSignDocument(novation.novationId);
            }
          };
        } else if (!userIsSeller) { // Additional check if I am both buyer and seller (for tests).
          // Novation is waiting for Seller signature, but Buyer can still download and review draft.
          novationLabel = L_WAITING_FOR_ADMIN;
          handleNovationAction = handleDownloadDocument;
          novationLabelColor = C_WAITING_FOR_OTHER;
          novationIsShown = true;
        }
      } else if (userIsSeller) { // If I am a Seller (Admin).
        if (!novation.signedBySeller) { // Novation is waiting for Seller signature.
          if (authContext.isContractSigner) {
            novationLabel = L_WAITING_FOR_YOU;
            handleNovationAction = handleSignDocument;
            novationLabelColor = C_WAITING_FOR_YOU;
            novationIsShown = true;

            currentUserAction = {
              label: 'Sign Novation',
              actionHandler: () => {
                handleSignDocument(novation.novationId);
              }
            };
          } else {
            novationLabel = L_WAITING_FOR_CONTRACT_SIGNER;
            handleNovationAction = handleDownloadDocument;
            novationLabelColor = C_WAITING_FOR_OTHER;
            novationIsShown = true;
          }
        } else if (!userIsBuyer) { // Additional check if I am both buyer and seller (for tests).
          // Novation is waiting for Buyer signature, but Seller can still download and review draft.
          novationLabel = L_WAITING_FOR_INVESTOR;
          handleNovationAction = handleDownloadDocument;
          novationLabelColor = C_WAITING_FOR_OTHER;
          novationIsShown = true;
        }
      }
    }

    if (novationIsShown) {
      novationViews.push({
        label: novationLabel,
        labelColor: novationLabelColor,
        actionHandler: () => {
          handleNovationAction(novation.novationId);
        }
      } as DocumentView);
    }
  }

  return {
    saftView: saftIsShown ? saftView : null,
    novationViews: novationViews,
    currentUserAction: currentUserAction
  }
}
