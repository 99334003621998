import React from 'react';
import { appRoutes } from './business/routes/appRoutes';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './core/providers/AuthProvider';
import { RestApiProvider } from './core/providers/RestApiProvider';
import { AppThemeProvider } from './core/providers/ThemeProvider';
import { ScreenProvider } from './core/providers/ScreenProvider';
import { apolloClient } from './store/apolloClient';
import { ApolloProvider } from '@apollo/client';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { BrowserRouter, history } from 'core/components/system/BrowserRouter';
import { Routes } from 'react-router-dom';
import './App.scss';

const App: React.FC = () => {
  return (
    <>
      <CssBaseline/>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter history={history}>
          <AppThemeProvider>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <AuthProvider>
                <RestApiProvider>
                  <ScreenProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                      <Routes>
                        {appRoutes}
                      </Routes>
                    </LocalizationProvider>
                  </ScreenProvider>
                </RestApiProvider>
              </AuthProvider>
            </SnackbarProvider>
          </AppThemeProvider>
        </BrowserRouter>
      </ApolloProvider>
    </>
  );
};

export default App;
