import React, { FC, MouseEvent, useCallback, useContext, useState } from 'react';
import {
  Avatar,
  Box,
  Button, Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  Link, Skeleton,
  Typography
} from '@mui/material';
import avatarDefault from '@assets/images/image-default.svg';
import './FundCard.scss';
import { useNavigate } from 'react-router-dom';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { AuthContext } from '@coreProviders/AuthProvider';
import { ExpandMore } from '@coreComponents/base/ExpandMore/ExpandMore';
import { RollingFund, RollingFundStatusEnum } from '@store/generated-models';
import { dateFormat, numberFormat } from '@coreHelpers/utils';
import { PERIOD_TYPES } from '@businessConstants/enums';
import { getIcon } from '@coreComponents/base/CustomIcon/CustomIcon';

interface FundCardProps {
  fund: RollingFund;
  isLoading: Boolean;
}

export const FundCard = (props: FundCardProps) => {
  const { fund, isLoading } = props;
  const {
    rollingFundId,
    avatar,
    introductionLink,
    title,
    description,
    startDate,
    minPeriod,
    minPeriodType,
    minAmount,
    managementFee,
    portfolio
  } = fund || {};
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  const handleParticipateClick = useCallback((_e: MouseEvent<HTMLButtonElement>) => {
    navigate(`participation-request/${rollingFundId}`);
  }, []);

  const handleExpandClick = (_e: MouseEvent) => setExpanded(!expanded);


  // if (!portfolio || typeof portfolio === 'undefined') {
  //   portfolio = [];
  // }

  const expandMore = <ExpandMore
    expand={expanded}
    onClick={handleExpandClick}
    aria-expanded={expanded}
    aria-label="show more"
  />;

  let IntroductionLink;
  if (introductionLink && introductionLink.length) {
    const marginTop = portfolio ? '12px' : 0;
    IntroductionLink = (
      isLoading ?
        <Skeleton variant="text" width={100}/>
        :
        <div style={{ marginTop: marginTop }}>
          <Link href={introductionLink} underline="hover"
                sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '22px', marginTop: marginTop }}
          >
            Fund Intro Material
          </Link>
        </div>
    );
  }

  const cardWidth = 368;
  let cardStyles: any = {
    minWidth: `${cardWidth}px`,
    maxWidth: `${cardWidth}px`
  };
  if (isPhone) {
    cardStyles = {
      maxWidth: `100%`
    };
  }

  return (
    <Card variant="outlined" sx={cardStyles}>
      <CardHeader
        avatar={
          isLoading ?
            <Skeleton variant="circular" width={60} height={60}/>
            :
            <Avatar sx={{ width: 60, height: 60 }}
                    src={avatar ? `${process.env.REACT_APP_API_URL_REST}${avatar}` : ''}>
              <img src={avatarDefault}/>
            </Avatar>
        }
        title={
          isLoading ?
            <Skeleton variant="text"/>
            :
            <Typography variant="h5" component="span" sx={{
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '30px',
              fontStyle: 'normal'
            }}
            >
              {title}
            </Typography>
        }
      />
      {
        (isLoading || !isLoading && description) && <CardContent>
          {
            isLoading ? <>
                <Skeleton variant="rectangular" width={334} height={118}/>
                <Skeleton variant="text" width={100}/>
              </>
              :
              description && <>
                <Box sx={{ bgcolor: 'text.primary, color: background.paper' }}>
                  <Typography sx={{ fontSize: 14, whiteSpace: 'pre-line', marginBottom: '0px' }} color="text.secondary"
                              gutterBottom>
                    {description}
                  </Typography>
                </Box>
                <Grid container style={{ marginTop: '8px', marginBottom: '24px' }} spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={{ fontSize: '12px', color: '#BBBDC0' }}>PORTFOLIO</Typography>
                    <Typography style={{ fontSize: '14px', fontWeight: 400 }}>{portfolio}</Typography>
                  </Grid>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent style={{ paddingBottom: 0 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography style={{ fontSize: '12px', color: '#BBBDC0' }}>START DATE</Typography>
                          <Typography style={{ fontSize: '14px', fontWeight: 400 }}>{dateFormat(startDate)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography style={{ fontSize: '12px', color: '#BBBDC0' }}>MINIMUM PERIOD</Typography>
                          <Typography style={{
                            fontSize: '14px',
                            fontWeight: 400
                          }}>{minPeriod} {PERIOD_TYPES[minPeriodType]}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography style={{ fontSize: '12px', color: '#BBBDC0' }}>MINIMUM AMOUNT</Typography>
                          <Typography style={{ fontSize: '14px', fontWeight: 400 }}>${numberFormat(minAmount)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography style={{ fontSize: '12px', color: '#BBBDC0' }}>MANAGEMENT FEE</Typography>
                          <Typography style={{ fontSize: '14px', fontWeight: 400 }}>{managementFee}% per annum</Typography>
                        </Grid>
                        {
                          introductionLink && <Grid item xs={12}>
                            <Link href={introductionLink}
                                  target="_blank" underline="hover"
                                  style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <div className="social">{getIcon('file')}</div>
                              <Typography variant="subtitle2">Fund Intro Materials</Typography>
                            </Link>
                          </Grid>
                        }
                      </Grid>
                    </CardContent>
                  </Collapse>
                </Grid>
                {/*<Grid item xs={12}>*/}
                {expandMore}
                {/*</Grid>*/}
              </>
          }
        </CardContent>
      }
      {
        authContext.isUser && <CardContent sx={{ paddingTop: 0 }}>
          <Button variant="contained"
                  onClick={handleParticipateClick}
                  disabled={!authContext.isVerified || fund?.status !== RollingFundStatusEnum.Active}
                  sx={{ width: '100%', fontWeight: '500', fontSize: '12px', lineHeight: '24px' }}>
            Invest Now
          </Button>
        </CardContent>
      }
    </Card>
  );
};
