import { gql } from '@apollo/client';

export const CREATE_FUND = gql`
  mutation AddFund(
    $rollingFund: RollingFundInput!,
    $avatar: Upload,
    $crop: Crop,
    $spacing: Spacing
  ) {
    addRollingFund(rollingFund: $rollingFund, avatar: $avatar, crop: $crop, spacing: $spacing) {
      rollingFundId
      avatar
      title
      description
      introductionLink
      contributionWallet
      contributionWalletShort
      contributionWalletUrl
      externalTemplateId
      status
      portfolio
    }
  }
`;

export const UPDATE_FUND = gql`
  mutation UpdateFund(
    $rollingFund: RollingFundMutationInput,
    $avatar: Upload,
    $crop: Crop,
    $spacing: Spacing
  ) {
    updateRollingFund(rollingFund: $rollingFund, avatar: $avatar, crop: $crop, spacing: $spacing) {
      rollingFundId
      avatar
      title
      description
      introductionLink
      contributionWallet
      contributionWalletShort
      contributionWalletUrl
      externalTemplateId
      status
      portfolio
    }
  }
`;

export const DELETE_FUND = gql`
  mutation DeleteFund($rollingFundId: ID!) {
    deleteRollingFund(rollingFundId: $rollingFundId) {
      rollingFundId
    }
  }
`;

export const UPDATE_DISCLAIMER = gql`
  mutation UpdateRollingFundsDisclaimer($disclaimer: NonEmptyString!) {
    updateRollingFundsDisclaimer(disclaimer: $disclaimer) {
      rollingFundsDisclaimer
    }
  }
`;
