import React, { FC, useContext, useState } from 'react';
import { AuthContext } from '@coreProviders/AuthProvider';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { TabPanel } from '@coreComponents/base/TabPanel/TabPanel';
import { UserDetailed } from '@businessComponents/Users/UserDetailed';
import { Security } from '@businessComponents/Settings/Security';

export const Profile: FC<any> = (props) => {
  const authContext = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState(0);
  const handleActiveTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flex: 1 }}>
          <Tabs
            value={activeTab}
            onChange={handleActiveTabChange}
            aria-label="secondary tabs example"
          >
            <Tab value={0} label="Password" sx={{ textTransform: 'none' }}/>
            <Tab value={1} label="Security" sx={{ textTransform: 'none' }}/>
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={activeTab} index={0}>
          {
            <UserDetailed {...props}/>
          }
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Security/>
        </TabPanel>
      </Grid>
    </Grid>
  );
};
