import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';

export const Sorter = (props: any) => {
  const { title, config, onChange, value } = props;
  const sorterValue = value || '';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value);
  };

  return <div className="filter">
    <div className="filter__title">
      {title || config.label}
    </div>
    <div className="filter__control">
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue=""
          name="radio-buttons-group"
          onChange={handleChange}
          value={sorterValue}
        >
          <FormControlLabel value="" control={<Radio/>} label="Without sorting"/>
          {config.options.map((option: any) => <React.Fragment key={option.label}>
              <FormControlLabel value={option.value}
                                control={<Radio/>}
                                label={option.label}
              />
            </React.Fragment>
          )}
        </RadioGroup>
      </FormControl>
    </div>
  </div>;
};
