import React, { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Panel } from '@coreComponents/base/Panel/Panel';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { Text, TEXT_VARIANT } from '@coreComponents/base/Text/Text';
import { Tab, Tabs } from '@mui/material';
import { TabPanel } from '@coreComponents/base/TabPanel/TabPanel';
import './TransactionTable.scss';
import { AllTransactionsTable } from '@businessComponents/Dashboard/AllTransactionsTable';

export const TransactionTable = () => {
  const { enqueueSnackbar } = useSnackbar();
  const screenContext = useContext(ScreenContext);
  const { isPhone, isTablet, isMobile } = screenContext;

  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
   setActiveTab(newValue);
  }
  return <div className="transactions">

    <div className="transactions__title">
      <Text variant={TEXT_VARIANT.h2}>Recent Transactions</Text>
    </div>
    <div className="transactions__tabs">
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="secondary tabs example"
      >
        <Tab value={0} label="All transactions" sx={{ textTransform: 'none' }}/>
        <Tab value={1} label="Income" sx={{ textTransform: 'none' }}/>
        <Tab value={2} label="Expense" sx={{ textTransform: 'none' }}/>
      </Tabs>
    </div>
    <div className="transactions__panel">
      <Panel>
        <TabPanel value={activeTab} index={0}>
          <AllTransactionsTable/>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          TABLE 2
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          TABLE 3
        </TabPanel>
      </Panel>
    </div>
  </div>;
};
