import React, {FC} from 'react';
import avatarDefault from '@assets/icons/image-default.svg';
import {concatClassNames} from '@coreHelpers/utils';
import {Avatar} from '@mui/material';
import './ComplexAvatar.scss';

interface AvatarProps {
  src: string;
  label: string;
  width: number;
  height: number;
}

export const ComplexAvatar: FC<AvatarProps> = (props) => {
  const {src, label, width, height} = props;
  const classNames = concatClassNames({
    'avatar--labeled': label !== ''
  });

  return (
    <div className={`avatar ${classNames}`}>
      <Avatar sx={{width, height}} src={src}>
        <img src={avatarDefault}/>
      </Avatar>
      {
        label && <span className="avatar__label">{label}</span>
      }
    </div>
  );
};
