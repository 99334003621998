import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';

import { TwoFactorAuthenticationResult } from '@store/generated-models';
import { AuthContext } from '@coreProviders/AuthProvider';
import { useMutation } from '@apollo/client';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { Step2faEnable } from '@businessComponents/Settings/Step2faEnable';
import { Step2faDisable } from '@businessComponents/Settings/Step2faDisable';
import { Panel } from '@coreComponents/base/Panel/Panel';
import { GENERATE_2FA } from '@store/mutations/users';

const Row = styled.div`
  display: flex;
  justify-content: center;
`;

const Alert = styled.span`
  color: ${props => props.theme.custom.palette.alert};
  font-weight: bold;
`;

const Success = styled.span`
  color: ${props => props.theme.custom.palette.success};
  font-weight: bold;
`;

export interface ComponentProps {
}

export const Security: React.FC<ComponentProps> = () => {

  const authContext = useContext(AuthContext);
  const [generate2faCode] = useMutation<{ generate2faCode: TwoFactorAuthenticationResult }>(GENERATE_2FA);

  const [status, setStatus] = useState('disabled');
  const [qrCode, setQrCode] = useState('');
  const [qrString, setQrString] = useState('');

  const { isPhone } = useContext(ScreenContext);

  useEffect(() => {
    setStatus(authContext.user.is2faEnabled ? 'enabled' : 'disabled');
  }, [authContext.user.is2faEnabled]);

  const changeStatusClickHandler = async () => {
    if (status === 'disabled') {
      try {
        const res = await generate2faCode({});
        if (res && res.data) {
          setQrCode(res.data.generate2faCode.code);
          setQrString(res.data.generate2faCode.qr);
          setStatus('step-enable');
        }
      } catch (error) {
        console.warn('getOAuthParams error:', error);
      }
    } else {
      setStatus('step-disable');
    }
  };

  return (
    <div style={{ display: isPhone ? 'block' : 'flex' }}>
      <Panel>
        {(status === 'step-enable') && <Step2faEnable
          qrCode={qrCode}
          qrString={qrString}
          onCancel={() => setStatus('disabled')}
        />}

        {(status === 'step-disable') && <Step2faDisable
          onCancel={() => setStatus('enabled')}
        />}

        {(status === 'disabled' || status === 'enabled') && <>
          <Row style={{ marginTop: '4px' }}>
            <Typography variant={'body1'}>
              Two-factor authentication is&nbsp;
              {status === 'disabled' ? <Alert>DISABLED</Alert> : <Success>ENABLED</Success>}
            </Typography>
          </Row>

          <Row style={{ marginTop: '24px' }}>
            <Button
              variant="contained"
              color="primary"
              size={'large'}
              style={{ minWidth: '240px' }}
              onClick={changeStatusClickHandler}
            >
              {status === 'disabled' ? 'Enable 2FA' : 'Disable 2FA'}
            </Button>
          </Row>
        </>
        }
      </Panel>
    </div>
  );
};
