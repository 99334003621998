import React, { useContext, useState } from 'react';
import { Step, StepIconProps, StepLabel, Stepper } from '@mui/material';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { StepHeader } from '@coreComponents/base/Stepper/StepHeader';
import { AccountTypeSelection } from './SignUpSteps/AccountTypeSelection';
import { ProvideInfo } from './SignUpSteps/ProvideInfo';
import { AccountType } from '@store/generated-models';

const StepContent: Record<number, any> = {
  0: AccountTypeSelection,
  1: ProvideInfo
};

export const SignUp: React.FC<any> = (props) => {
  const { withAccountTypeSelection } = props;

  const steps = ['', ''];
  const [activeStep, setActiveStep] = useState(0);
  const [accountType, setAccountType] = useState(AccountType.User);
  const [AuthInfoBlock, setAuthInfoBlock] = useState(null);
  const { isPhone } = useContext(ScreenContext);

  function getStep(index = 0, props: Record<string, unknown> = {}) {
    const Step = StepContent[index];
    return <Step {...props}/>;
  }

  const stepStyles = (index: number) => ({
    display: isPhone && activeStep !== index ? 'none' : 'block',
    ...(isPhone && activeStep === 0 && { marginLeft: '25%' }),
    ...(isPhone && activeStep === steps.length - 1 && { marginRight: '25%' })
  });

  const stepProps: { completed?: boolean } = {};
  const StepperBlock = <Stepper activeStep={activeStep} sx={{ margin: '0 5%', marginBottom: '40px' }}>
    {
      steps.map((label, index) => {
        return (
          <Step key={index} {...stepProps} style={stepStyles(index)}>
            <StepLabel StepIconComponent={(props: StepIconProps) =>
              <StepHeader {...props} index={index} variant="square"/>
            }>{label}</StepLabel>
          </Step>
        );
      })
    }
  </Stepper>;

  return <>
    {
      !AuthInfoBlock && withAccountTypeSelection && <div style={{ width: '100%' }}>
        {StepperBlock}
      </div>
    }
    {!AuthInfoBlock && getStep(activeStep, { accountType, setAccountType, setActiveStep, setAuthInfoBlock })}
    {AuthInfoBlock}
  </>;
};
