import { PropsWithChildren } from 'react';

export enum TEXT_COLOR {
  main = 'main',
  secondary = 'secondary',
  light = 'light',
  primaryDark = 'accent-primary-dark',
  primaryLight = 'accent-primary-light',
  errorDark = 'accent-error-dark',
  errorLight = 'accent-error-light',
  warningDark = 'accent-warning-dark',
  warningLight = 'accent-warning-light',
  successDark = 'accent-success-dark',
  successLight = 'accent-success-light'
}

export enum TEXT_VARIANT {
  'h0' = 'h0',
  'h1' = 'h1',
  'h2' = 'h2',
  'h3' = 'h3',
  'body-medium' = 'body-medium',
  'body-regular' = 'body-regular',
  'small-semibold' = 'small-semibold',
  'small-medium' = 'small-medium',
  'small-regular' = 'small-regular',
  'super-small-regular' = 'super-small-regular'
}

interface TextProps {
  color?: TEXT_COLOR;
  variant?: TEXT_VARIANT;
}

export const Text = (props: PropsWithChildren<TextProps>) => {
  const { color = TEXT_COLOR.main, variant = TEXT_VARIANT['body-medium'], children } = props;

  return <span className={`text--${color} text--${variant}`}>
    {children}
  </span>;
};
