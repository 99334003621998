import React, { useState } from 'react';
import { Confirm2fa } from './Confirm2fa';
import { SignInForm } from '@businessComponents/Authentication/SignInForm';

export const SignIn: React.FC<any> = (props) => {
  const [isConfirm2faStep, setIsConfirm2faStep] = useState(false);
  const [token, setToken] = useState(null);
  const [AuthInfoBlock, setAuthInfoBlock] = useState(null);

  const methods = { setToken, setIsConfirm2faStep, setAuthInfoBlock };

  let SignInWorkflowComponent = null;

  if (AuthInfoBlock) {
    SignInWorkflowComponent = AuthInfoBlock;
  } else if (isConfirm2faStep) {
    SignInWorkflowComponent = <Confirm2fa token={token}/>;
  } else {
    SignInWorkflowComponent = <SignInForm methods={methods}/>;
  }

  return SignInWorkflowComponent;
};
