import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button, FormControlLabel,
  Grid, Link, Switch,
  Typography
} from '@mui/material';
import '../History/Transaction.scss';
import { getIcon } from '@coreComponents/base/CustomIcon/CustomIcon';
import './CurrentSAFT.scss';
import { AuthContext } from '@coreProviders/AuthProvider';
import { Panel } from '@coreComponents/base/Panel/Panel';
import HelloSign from 'hellosign-embedded';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_DOCUMENT_FINAL_COPY_URL,
  GET_DOCUMENT_SIGN_URL
} from '@store/queries/requests';
import { copyTextToClipboard } from '@coreHelpers/Clipboard';
import { useSnackbar } from 'notistack';
import { SET_FUNDS_RECEIVED } from '@store/mutations/participationRequest';
import appConfig from '@src/config';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { getDocumentsViewData } from './DocumentsViewData';

export const CurrentSAFT = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const authContext = useContext(AuthContext);
  const { request, refetch, isRefetching, setIsRefetching } = props;
  const { project, contract, novations } = request;
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);
  
  const {
    contributionWallet,
    contributionWalletShort,
    contributionWalletUrl
  } = request.rollingFund ? request.rollingFund : request.project;

  const data = {
    transactionId: contract.transactionId,
    transactionIdShort: contract.transactionIdShort,
    transactionUrl: contract.transactionUrl,
    buyerWalletAddress: contract.buyerWalletAddress,
    buyerWalletShort: contract.buyerWalletShort,
    buyerWalletUrl: contract.buyerWalletUrl,
    contributionWallet: contributionWallet,
    contributionWalletShort: contributionWalletShort,
    contributionWalletUrl: contributionWalletUrl,
    signatures: {
      buyer: {
        signed: contract.signedByBuyer !== null,
        date: contract.signedByBuyer
      },
      seller: {
        signed: contract.signedBySeller !== null,
        date: contract.signedBySeller
      }
    }
  };

// BEGIN: Issue with 'getDocumentsViewData'
  /* Todo 'getDocumentsViewData': This functionality is also needed in RequestProgress.tsx module since
   *  there is a place for user to see the current document state and take corresponding actions (sign)
   *  as well. We need to generalise this functionality and be able to use it in multiple places.
   *  Moreover, closing the popup which was opened from RequestProgress.tsx should also trigger
   *  refetch in CurrentSAFT section.
   */
  const { refetch: refetchDocumentSign } = useQuery(GET_DOCUMENT_SIGN_URL, {
    fetchPolicy: 'network-only',
    skip: true
  });

  const { refetch: refetchDocumentCopy } = useQuery(GET_DOCUMENT_FINAL_COPY_URL, {
    fetchPolicy: 'network-only',
    skip: true
  });

  const openDocument = (signUrl: string) => {
    const client = new HelloSign();
    client.on('close', () => {
      setIsRefetching(true);
      setTimeout(() => {
        refetch().then(() => {
          setIsRefetching(false);
        });
      }, 3000);
    });

    if (signUrl) {
      client.open(signUrl, {
        testMode: appConfig.hsTestMode == 'true',
        skipDomainVerification: appConfig.hsSkipDomainVerification == 'true',
        clientId: appConfig.hsClientId
      });
    }
  };

  const handleSignDocument = (documentId: any, checkFunds: boolean = false) => {
    refetchDocumentSign({ documentId }).then((result) => {
      if (!checkFunds || fundsReceived) {
        openDocument(result.data.getDocumentSignUrl);
      } else {
        enqueueSnackbar('The Funds are not received, please check the transaction first', { ...{ variant: 'info' } });
      }
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { ...{ variant: 'error' } });
    });
  };

  const handleDownloadDocument = (documentId: any) => {
    refetchDocumentCopy({ documentId }).then((result) => {
      window.open(result.data.getDocumentFinalCopyUrl, '_blank');
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { ...{ variant: 'error' } });
    });
  };
// END: Issue with 'getDocumentsViewData'

  const CopyButton = (props: any) => {
    const { value } = props;

    return <>
      <Button onClick={() => copyTextToClipboard(value)}>
        {getIcon('copy')}
      </Button>
    </>;
  };

  const SAFTLink = (props: any) => {
    const { label, url, value, valueToDisplay } = props;

    return <>
      <Grid item xs={12} md={5}>
        <div>
          <Typography variant="viewModeLabel" color="text.secondary" component="span">
            {label}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={7}>
        <div className="saft-link">
          {value ?
            <>
              <Link href={url} target="_blank" underline="hover">
                <Typography variant="viewModeValue">
                  {valueToDisplay}
                </Typography>
              </Link>
              <div className="saft-link__actions">
                <CopyButton value={value}/>
              </div>
            </>
            :
            <Typography variant="viewModeValue">N/A</Typography>
          }
        </div>
      </Grid>
    </>;
  };

  const [fundsReceived, setFundsReceived] = useState(false);

  useEffect(() => {
    setFundsReceived(contract.fundsReceived);
  }, [contract]);

  const [updateFunds, {
    loading: loadingFunds
  }] = useMutation(SET_FUNDS_RECEIVED);
  const handleFundsReceived = async (event: any) => {
    setFundsReceived(event.target.checked);
    await updateFunds({
      variables: {
        contractId: request.contract.contractId,
        value: event.target.checked
      }
    }).then(() => {
      enqueueSnackbar('Saved', { variant: 'success' });
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    });
  };

  const documentsViewData = getDocumentsViewData(
    request, authContext,
    handleSignDocument, // Todo 'getDocumentsViewData': Remove this arg for general func.
    handleDownloadDocument // Todo 'getDocumentsViewData': Remove this arg for general func.
  );

  return <>
    <Panel isLoading={isRefetching}>
      <Grid container spacing={{ xs: 2 }}>
        <Grid item xs={12} sx={{ marginBottom: '32px' }}>
          <Typography variant="h2" sx={{ fontSize: '20px', fontWeight: 500 }}>
            Current contract
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container rowSpacing={2}>
            {
              data && <>
                <SAFTLink label="Contribution wallet"
                          url={data.contributionWalletUrl}
                          value={data.contributionWallet}
                          valueToDisplay={data.contributionWalletShort}
                />
                <SAFTLink label="Transaction ID"
                          url={data.transactionUrl}
                          value={data.transactionId}
                          valueToDisplay={data.transactionIdShort}
                />
                <SAFTLink label="Buyer wallet Address"
                          url={data.buyerWalletUrl}
                          value={data.buyerWalletAddress}
                          valueToDisplay={data.buyerWalletShort}
                />
              </>
            }
            {
              authContext.isAdmin && <>
                <Grid item sm={6} xs={12}>
                  <FormControlLabel
                    disabled={loadingFunds || contract.isComplete}
                    control={
                      <Switch name="status" checked={!!fundsReceived}
                              onChange={handleFundsReceived}
                      />
                    }
                    label="Funds received"
                  />
                </Grid>
              </>
            }
          </Grid>
        </Grid>
        {
          !isPhone && <Grid item sm={1}>
            <div style={{ display: 'flex', height: '100%', justifyContent: 'center' }}>
              <div style={{ width: '1px', borderLeft: '1px solid #D6D6D6' }}/>
            </div>
          </Grid>
        }
        <Grid item xs={12} sm={5}>
          <Grid container>
            {
              isPhone && <Grid item xs={12}>
                <hr style={{ margin: '8px 0px 16px 0', border: '1px solid #d6d6d6', borderBottom: 'none' }}/>
              </Grid>
            }
            <Grid item xs={12}>
              <div className="signatures">
                {contract && documentsViewData.saftView &&
                  <div className="signature" style={{ color: documentsViewData.saftView.labelColor }}>
                    <Link onClick={documentsViewData.saftView.actionHandler} underline="hover"
                          className="signature__link">{getIcon('file')} Contract
                    </Link><span className="signature__dot">•</span>{documentsViewData.saftView.label}
                  </div>
                }
                {documentsViewData.novationViews.map(view => {
                  const color: string = view.labelColor;
                  return <div className="signature" style={{ color }}>
                    <Link onClick={view.actionHandler} underline="hover"
                          className="signature__link">{getIcon('file')} Novation
                    </Link><span className="signature__dot">•</span>{view.label}
                  </div>;
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
        {documentsViewData.currentUserAction &&
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '0' }}>
              <Button variant="contained"
                      sx={{ width: { xs: '140px', md: 'auto' } }}
                      onClick={documentsViewData.currentUserAction.actionHandler}
              >{documentsViewData.currentUserAction.label}</Button>
            </Box>
          </Grid>
        }
      </Grid>
    </Panel>
  </>;
};
