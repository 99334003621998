import { FormControl, InputAdornment, MenuItem, Select } from '@mui/material';
import React from 'react';
import { NumberInput } from '@coreComponents/base/NumberInput/NumberInput';
import { RollingFundPeriodTypeEnum } from '@store/generated-models';

export const MinPeriodInput = (props: any) => {
  const { fieldName = 'minPeriod', errors, value, typeValue, onValueChange, onTypeChange, isWithLabel } = props;
  const label = isWithLabel ? 'Minimum Period' : '';

  return <NumberInput name={fieldName} label={label} variant="outlined" fullWidth
                      required error={errors[fieldName]?.length > 0}
                      InputProps={{
                        endAdornment: <InputAdornment
                          position="end">
                          <FormControl fullWidth required>
                            <Select fullWidth className="icon-select"
                                    name={`${fieldName}Type`}
                                    value={typeValue || ''}
                                    onChange={onTypeChange}
                                    variant="standard"
                            >
                              <MenuItem value={RollingFundPeriodTypeEnum.Month}>
                                Months
                              </MenuItem>
                              <MenuItem value={RollingFundPeriodTypeEnum.Quarter}>
                                Quarters
                              </MenuItem>
                              <MenuItem value={RollingFundPeriodTypeEnum.Semester}>
                                Semesters
                              </MenuItem>
                              <MenuItem value={RollingFundPeriodTypeEnum.Year}>
                                Years
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </InputAdornment>
                      }}
                      onChange={onValueChange}
                      value={value || 0}
                      precision={0}
  />;

};
