import { gql } from "@apollo/client";

export const GET_HS_TEMPLATES = gql`
  query GetHsTemplates($templateType: HsTemplateType) {
    getHsTemplates(templateType: $templateType) {
      templateId
      title
    }
  }
`;
