import { Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Modal } from '@coreComponents/base/Modal/Modal';

export const useChangeTermsModal = (request: any) => {
  const [isOpenChangeTerms, setIsOpenChangeTerms] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const navigateToEditRequest = useCallback(() => {
    navigate(`/private/fund-change-request/${request.rollingFundPRequestId}`);
  }, [request.rollingFundPRequestId]);

  const modal = <Modal
    open={isOpenChangeTerms}
    onDecline={() => {
      setIsOpenChangeTerms(false);
    }}
    onAccept={navigateToEditRequest}
  >
    <>
      <Typography sx={{ marginBottom: '16px' }}>
        {'Your request was already approved. Are you sure you want to change the request terms and resend your request for a new approval?'}
      </Typography>
    </>
  </Modal>;

  return {
    modal,
    setIsOpenChangeTerms
  };
};
