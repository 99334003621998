import React, { useContext } from 'react';
import { LoginResult, MutationVerify2faCodeArgs } from '@store/generated-models';
import { AuthContext } from '@coreProviders/AuthProvider';
import TextField from '@mui/material/TextField';
import { useMutation } from '@apollo/client';
import { Box, Button, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { VERIFY_2FA_CODE } from '@store/mutations/users';
import { AuthContentHeader } from '@businessComponents/Authentication/AuthContentHeader';
import { useForm } from '@coreHooks/useForm';

export const Confirm2fa: React.FC<any> = (props) => {
  const authContext = useContext(AuthContext);

  type TwoFactorForm = {
    code: string;
  }
  const initialValues: TwoFactorForm = {
    code: ''
  };
  const validationConfig = {
    code: {
      type: 'required',
      message: 'Code cannot be empty'
    }
  };

  const [verify2faCode] = useMutation<{ verify2faCode: LoginResult }, MutationVerify2faCodeArgs>(VERIFY_2FA_CODE);

  const onSubmit = () => {
    verify2faCode({
      variables: {
        code: data.code
      }
    })
      .then((res: any) => {
        const loginData = res.data.verify2faCode;
        authContext.login(loginData);
      })
      .catch((error: any) => {
        try {
          const errorCode = error.graphQLErrors[0].extensions.code;
          if (errorCode === 'auth.token_verification') {
            authContext.showMessage('Verification error occurred');
          } else {
            authContext.showMessage('Unknown error occurred');
          }
        } catch {
          authContext.showMessage('Unknown error occurred');
        }
      });
  };

  const {
    data,
    errors,
    handleTextChange,
    handleSubmit
  } = useForm<TwoFactorForm>({ validationConfig, initialValues, onSubmit });

  return (
    <>
      <AuthContentHeader title="Two-Factor Authentication"
                         subtitle="Please insert the code from the Authenticator below"
      />
      <form autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              style={{ margin: '12px 0 24px' }}
              variant="outlined"
              fullWidth
              label="Enter 2FA Code"
              autoComplete="off"
              name="code"
              value={data.code}
              error={errors.code?.length > 0}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size={'large'}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex' }}>
              <Button startIcon={<ArrowBackIcon/>} sx={{ margin: '0 auto', fontSize: '14px', fontWeight: 500 }}
                      onClick={() => window.location.reload()}>
                Back to Log In
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
