import React, {Context} from 'react';
import {createContext, useContext, useState, useLayoutEffect} from 'react';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';

interface NavigationContextProvider {
  pageName: string;
  setPageName: (pageName: string) => void;
}

const HistoryContext = createContext({});
export const NavigationContext: Context<NavigationContextProvider> = createContext({
  pageName: '',
  setPageName: (pageName: string) => {}
});

export const useHistory = () => useContext(HistoryContext);

export const BrowserRouter = (props: any) => {
  const {history, children} = props;
  const [{location}, setHistoryState] = useState({
    location: history.location
  });
  const [pageName, setPageName] = useState('');

  useLayoutEffect(() => history.listen((state: any) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    setHistoryState(state);
  }), [history]);

  return (
    <Router location={location} navigator={history}>
      <HistoryContext.Provider value={history}>
        <NavigationContext.Provider value={{pageName, setPageName}}>
          {children}
        </NavigationContext.Provider>
      </HistoryContext.Provider>
    </Router>
  );
};

export const history = createBrowserHistory();
