import { PercentCrop, PixelCrop } from 'react-image-crop';
import { OriginalData } from './CropImageModal';

export async function canvasPreview(
  originalData: OriginalData,
  imageBitmap: ImageBitmap,
  canvas: HTMLCanvasElement,
  crop: PixelCrop | PercentCrop,
  scale = 1,
  imageDimension: number
) {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(imageDimension * pixelRatio);
  canvas.height = Math.floor(imageDimension * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  ctx.save();

  ctx.scale(scale, scale);
  ctx.drawImage(
    imageBitmap,
    0,
    0,
    imageDimension,
    imageDimension
  );

  ctx.restore();
}
