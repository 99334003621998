import React, { forwardRef, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  Link,
  LinkProps
} from '@mui/material';
import { StepProps } from '../FundParticipationRequest';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { GET_FUND_BY_ID } from '@store/queries/funds';
import { RollingFund } from '@store/generated-models';
import './AgreeTerms.scss';

const StyledLink = styled(Link)<LinkProps>(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 600,
  textDecoration: 'none',
  color: theme.palette.primary.main
}));

export const AgreeTerms: StepProps = forwardRef((props: any, ref) => {
  let { id } = useParams();

  const { data, loading } = useQuery(GET_FUND_BY_ID, {
    variables: {
      rollingFundId: id
    },
    fetchPolicy: 'network-only'
  });
  const [fundData, setFundData] = useState<RollingFund>();

  useEffect(() => {
    if (!loading) {
      setFundData(data.getRollingFundById);
    }
  }, [data]);

  const [agreedItems, setAgreedItems] = useState({
    provisions: false,
    country: false,
    acceptance: false,
    terms: false
  });

  const handleChangeAgree = (event: any) => {
    setAgreedItems({
      ...agreedItems,
      [event.target.name]: event.target.checked
    });
  };

  useEffect(() => {
    props.setIsStepValid(Object.entries(agreedItems).every(item => item[1]));
  }, [agreedItems]);

  const termsAndConditionsLink = (
    <StyledLink href="https://defiedtrust.com/terms" target="_blank">
      Terms and conditions
    </StyledLink>
  );

  return (
    <Grid container spacing={{xs: 1, sm: 7}}>
      <Grid item sm={6}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <Checkbox name="provisions" onChange={handleChangeAgree} style={{ marginRight: '8px' }}/>
          <Typography variant="body1"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        color: '#000000',
                        marginBottom: '12px'
                      }}
          >
            I agree to the closing documents’ provisions. I understand and acknowledge that:
          </Typography>
        </Box>
        <Box className="terms" sx={{ display: 'flex' }}>
          <div style={{ fontSize: '14px', marginBottom: '12px' }} dangerouslySetInnerHTML={{ __html: fundData?.terms }}/>
        </Box>
      </Grid>
      <Grid item sm={6}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          }}
        >
          <div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '16px' }}>
              <Checkbox name="country" onChange={handleChangeAgree} style={{ marginRight: '8px' }}/>
              <div>
                <Typography sx={{ marginBottom: '16px' }}>
                  I agree that I don’t reside in any of the following countries:
                </Typography>
                <Typography>
                  Canada, Costa Rica, United States, North Korea, Iran, Iraq, Lebanon, Libya, Somalia, South Sudan,
                  Sudan, Syria, Venezuela, Yemen, and residents in Russia.
                </Typography>
              </div>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '16px' }}>
              <Checkbox name="acceptance" onChange={handleChangeAgree} style={{ marginRight: '8px' }}/>
              <Typography>
                I acknowledge that my acceptance to the fund is not guaranteed, and I intend to invest if accepted.
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
              <Checkbox name="terms" onChange={handleChangeAgree} style={{ marginRight: '8px' }}/>
              <Typography>
                I acknowledge that I have read and agreed to the {termsAndConditionsLink} and Privacy policy
              </Typography>
            </Box>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
});
