import { gql } from '@apollo/client';
import { LOGIN_RESULT } from '@store/common';

export const SIGN_IN = gql`
  mutation Login(
    $email: String,
    $password: String
  ) {
    login (
      email:$email,
      password:$password
    ) {
      ${LOGIN_RESULT}
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers($filterEx: [Filter], $orderBy: [OrderBy!]) {
    getUsers(filterEx: $filterEx, orderBy: $orderBy) {
      list {
        userId
        firstName
        lastName
        email
        created
        mode
        kycStatus
        kybStatus
        status
        roles {
          code
        }
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query UserById($userId: String) {
    userById(userId: $userId) {
      userId
      firstName
      lastName
      email
      mode
      kycStatus
      kybStatus
      status
    }
  }
`;

export const GET_ME = gql`
  query Me {
    me {
      userId
      email
      firstName
      lastName
      fullName
      emailConfirmed
      is2faEnabled
      mode
      kycStatus
      kybStatus
      status
      created
      updated
      deleted
      roles {
        code
      }
    }
  }
`;

export const GET_MY_WALLETS = gql`
  query Me {
    me {
      wallets {
        walletId
        link
        createdAt
        deleted
        processingStatusCode
      }
    }
  }
`;

export const GET_MENU_ITEMS = gql`
  query getMenuItems {
    menuItems {
      code
      name
      description
      badgeValue
    }
  }
`;


export const GET_MY_ASSETS = gql`
  query MyAssets {
    myAssets {
      cryptoAssetId
      symbol
      title
      logoUrl
      userData {
        isSubscribed
      }
    }
  }
`;

export const GET_MY_ASSET_BY_ID = gql`
  query myAssetById($cryptoAssetId: String) {
    myAssetById(cryptoAssetId: $cryptoAssetId) {
      cryptoAssetId
      symbol
      title
      logoUrl
      userData {
        isSubscribed
      }
    }
  }
`;

export const USER_UPDATES_SUBSCRIPTION = gql`
  subscription UserUpdates($subscriptionEventIds: [String]) {
    myAssetsUpdate(subscriptionEventIds: $subscriptionEventIds)
  }
`;

export const USER_NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription UserNotifications($subscriptionEventIds: [String]) {
    newNotification(subscriptionEventIds: $subscriptionEventIds)
  }
`;

