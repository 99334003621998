export const TEXT_MAIN = '#FFFFFF';
export const TEXT_SECONDARY = '#B1B5BA';
export const TEXT_LIGHT = '#FFFFFF';

export const BACKGROUND_DARK = '#DDE2E5';
export const BACKGROUND_MAIN = '#0F233D';
export const BACKGROUND_LIGHT = '#F5F5F5';

export const BORDER_LIGHT = '#DDE1E4';
export const ACCENT_PRIMARY_DARK = '#19355A';
export const ACCENT_PRIMARY_LIGHT = '#E9F5FF';
export const ACCENT_ERROR_DARK = '#D32F2F';
export const ACCENT_ERROR_LIGHT = '#FBE4E4';
export const ACCENT_WARNING_DARK = '#BF7825';
export const ACCENT_WARNING_LIGHT = '#FEF6CA';
export const ACCENT_SUCCESS_DARK = '#147963';
export const ACCENT_SUCCESS_LIGHT = '#D6F5EE';
