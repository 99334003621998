import React, { useContext, useEffect, useState } from 'react';
import { MutationConfirmEmailArgs } from '@store/generated-models';
import { AuthContext } from '@coreProviders/AuthProvider';
import { Button } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CONFIRM_EMAIL } from '@store/mutations/users';

const TextBlockWrap = styled.div`
  text-align: center;
  margin-bottom: 12px;
`;

export const ConfirmEmail: React.FC<any> = () => {
  const [state, setState] = useState('validating');
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { token } = useParams();

  const [confirmEmail] = useMutation<{ confirmEmail: boolean }, MutationConfirmEmailArgs>(CONFIRM_EMAIL);

  useEffect(() => {
    if (!!token) {
      confirmEmail({
        variables: {
          token: token
        }
      })
        .then((res) => {
          if (res.data.confirmEmail) {
            setState('confirmed');
          } else {
            setState('error');
          }
        })
        .catch(() => {
          setState('error');
        });
    }
  }, []);


  let dialogContent: JSX.Element = null;

  const EmailValidationInProgress = <div>Validating confirmation...</div>;
  const EmailValidationFailure = <>
    <TextBlockWrap>
      <p>Can't confirm email.</p>
      <p>Make sure you're using the latest received confirmation email.</p>
    </TextBlockWrap>
    <Button
      variant="contained"
      color="primary"
      fullWidth
      onClick={() => navigate('/login')}
    >
      Back to the homepage
    </Button>
  </>;
  const EmailValidationSuccess = <>
    <TextBlockWrap>
      <p>Email confirmed successfully</p>
      <p>You can log in now</p>
    </TextBlockWrap>
    <Button
      variant="contained"
      color="primary"
      fullWidth
      onClick={() => navigate('/login')}
    >
      Log in
    </Button>
  </>;

  if (token) {
    if (state === 'validating') {
      dialogContent = EmailValidationInProgress;
    } else if (state === 'error') {
      dialogContent = EmailValidationFailure;
    } else if (state === 'confirmed') {
      dialogContent = EmailValidationSuccess;
    }
  }

  return dialogContent;
};
