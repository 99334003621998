import { TextLink } from '@coreComponents/base/TextLink/TextLink';
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/material';
import { Text, TEXT_COLOR, TEXT_VARIANT } from '@coreComponents/base/Text/Text';

export const DontHaveAnAccount = <>
  <Text color={TEXT_COLOR.secondary} variant={TEXT_VARIANT['body-regular']}>
    {'Don’t have an account? '}
  </Text>
  <TextLink text="Sign up" to="/signup" classNames="text--body-medium"/>
</>;

export const AlreadyHaveAnAccount = <>
  <Text color={TEXT_COLOR.secondary} variant={TEXT_VARIANT['body-regular']}>
    {'Already have an account? '}
  </Text>
  <TextLink text="Sign in" to="/login" classNames="text--body-medium"/>
</>;
export const BackToSignIn = <Box sx={{ display: 'flex' }}>
  <TextLink startIcon={<ArrowBackIcon sx={{ marginRight: '8px' }}/>}
            text={'Back to Sign In'} to="/login"
            classNames="text--body-medium"
  />
</Box>;

