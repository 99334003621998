import { getIcon } from '@coreComponents/base/CustomIcon/CustomIcon';
import { Text, TEXT_COLOR, TEXT_VARIANT } from '@coreComponents/base/Text/Text';
import './StepHeader.scss';

export const StepHeader = (props: any) => {
  const { completed, variant = 'circle', index } = props;

  return <div className={`stepper__step stepper__step--${variant}`}>
    <Text color={TEXT_COLOR.light} variant={TEXT_VARIANT['small-regular']}>
      {completed ? getIcon('check') : index + 1}
    </Text>
  </div>;
};
