import {
  AssetsEnum,
  IncomeEnum,
  OccupationEnum,
  RollingFundPeriodTypeEnum,
  SourceOfFundsEnum,
  UserAccountStatus
} from '@store/generated-models';

export const EMPTY = '';

export const PERIOD_TYPES = {
  [RollingFundPeriodTypeEnum.Month]: 'Months',
  [RollingFundPeriodTypeEnum.Quarter]: 'Quarters',
  [RollingFundPeriodTypeEnum.Semester]: 'Semesters',
  [RollingFundPeriodTypeEnum.Year]: 'Years'
};

export const SOURCE_OF_FUNDS = {
  [SourceOfFundsEnum.Salary]: 'Salary',
  [SourceOfFundsEnum.Pension]: 'Pension',
  [SourceOfFundsEnum.Savings]: 'Savings',
  [SourceOfFundsEnum.SharesSale]: 'Sale of shares / dividends',
  [SourceOfFundsEnum.FinancialInstrumentsSale]: 'Sale of financial instruments',
  [SourceOfFundsEnum.RealEstateSale]: 'Sale of real estate',
  [SourceOfFundsEnum.Inheritance]: 'Inheritance',
  [SourceOfFundsEnum.Other]: 'Other'
};

export const OCCUPATION = {
  [OccupationEnum.Employed]: 'Employed',
  [OccupationEnum.SelfEmployed]: 'Self-employed',
  [OccupationEnum.Retired]: 'Retired',
  [OccupationEnum.Student]: 'Student',
  [OccupationEnum.Unemployed]: 'Unemployed'
};

export const INCOME = {
  [IncomeEnum.Low]: '0 to 30k',
  [IncomeEnum.Medium]: '30k to 100k',
  [IncomeEnum.High]: '> 100k'
};

export const ASSETS = {
  [AssetsEnum.Low]: '0 to 30k',
  [AssetsEnum.Medium]: '30k to 100k',
  [AssetsEnum.High]: '> 100k'
};

export const ACCOUNT_STATUS = {
  [UserAccountStatus.Active]: 'Active',
  [UserAccountStatus.Inactive]: 'Inactive'
};
