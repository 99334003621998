import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Toolbar as MuiToolbar } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { AccountInfo } from '@businessComponents/AccountInfo/AccountInfo';
import { NavigationContext } from '@coreComponents/system/BrowserRouter';
import LogoImage from '@assets/logo-v2.png';
import { BACKGROUND_MAIN, BORDER_LIGHT, TEXT_MAIN } from '@coreConstants/colors';
import './Toolbar.scss';
import { Text, TEXT_VARIANT } from '@coreComponents/base/Text/Text';

export const Toolbar = (props: any) => {
  const { handleDrawerOpen, open } = props;
  const screenContext = useContext(ScreenContext);
  const navigationContext = useContext(NavigationContext);

  const { isTablet, isMobile } = screenContext;
  const [pageName, setPageName] = useState('');

  useEffect(() => {
    setPageName(navigationContext.pageName);
  }, [navigationContext.pageName]);

  const toolbarStyle = isMobile ? {
    backgroundColor: BACKGROUND_MAIN, color: TEXT_MAIN,
    ...(open && { width: 'calc(100% - 200px)', alignSelf: 'end' }),
    ...(isTablet && { borderLeft: `1px solid ${BORDER_LIGHT}` })
  } : {
    width: 'calc(100% - 240px)',
    alignSelf: 'end'
  };

  return (
    <MuiToolbar className="toolbar" style={{ ...toolbarStyle }}>
      {
        isMobile && <IconButton
          color="inherit"
          onClick={handleDrawerOpen}
          sx={{
            color: TEXT_MAIN,
            marginRight: '16px',
            ...(open && { display: 'none' })
          }}
        >
          <MenuIcon/>
        </IconButton>
      }
      <div className="toolbar__title">
        {
          pageName &&
          <Text variant={isMobile ? TEXT_VARIANT.h2 : TEXT_VARIANT.h1}>
            {pageName}
          </Text>
        }
      </div>
    </MuiToolbar>
  );
};
