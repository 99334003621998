import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { Panel } from '@coreComponents/base/Panel/Panel';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { Text, TEXT_COLOR, TEXT_VARIANT } from '@coreComponents/base/Text/Text';
import { Button } from '@mui/material';
import './AccountBalance.scss';

export const AccountBalance = () => {
  const { enqueueSnackbar } = useSnackbar();
  const screenContext = useContext(ScreenContext);
  const { isPhone, isTablet, isMobile } = screenContext;


  return <Panel>
    <div className="balance">
      <div className="balance__title">
        <Text variant={TEXT_VARIANT.h2}>USDT</Text>
      </div>
      <div className="balance__subtitle">
        <Text color={TEXT_COLOR.secondary} variant={TEXT_VARIANT['small-regular']}>ACCOUNT BALANCE</Text>
      </div>
      <div className="balance__amount">
        <Text variant={TEXT_VARIANT.h0}>2,200 T</Text>
      </div>
      <div className="balance__buttons">
        <Button variant="contained">Deposit</Button>
        <Button variant="contained" color="error">Withdraw</Button>
      </div>
    </div>
  </Panel>;
};
