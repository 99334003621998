import React, { FC, useEffect, useState } from 'react';
import { Button, Chip, Grid, Skeleton, Typography } from '@mui/material';
import { Panel } from '@coreComponents/base/Panel/Panel';
import { dateFormat, numberFormat } from '@coreHelpers/utils';
import './Subscription.scss';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_BILLING_PORTAL_URL,
  GET_MY_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS
} from '@store/queries/subscription';
import { DOWNGRADE_TO_FREE_TIER, UPGRADE_TO_PAID_PLAN } from '@store/mutations/subscription';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { Modal } from '@coreComponents/base/Modal/Modal';
import { RecurringSubscription, UserRecurringSubscription } from '@store/generated-models';
import { ArrowForward } from '@mui/icons-material';

const PlanStatusChip = styled(Chip, {
  shouldForwardProp: (prop: string) => !['textColor', 'bgColor'].includes(prop)
})<any>(({ theme, textColor, bgColor }) => ({
  color: textColor,
  backgroundColor: bgColor
}));

const PlanBlock = (props: any) => {
  const {
    id, title, price, dueDate, listItems, isActive, refetchFunc
  } = props;
  const isPaid = price > 0.0;
  const { enqueueSnackbar } = useSnackbar();

  const [upgradeToPaidPlan, {
    data: dataUpgrade,
    loading: loadingUpgrade
  }] = useMutation(UPGRADE_TO_PAID_PLAN);

  const [downgradeToFreeTier, {
    loading: loadingDowngrade
  }] = useMutation(DOWNGRADE_TO_FREE_TIER);

  const [isOpenCancelPremiumModal, setIsOpenCancelPremiumModal] = useState(false);
  const cancelPremiumModal = () => <>
    <Modal
      open={isOpenCancelPremiumModal}
      title="Cancel premium"
      renderActions={() => <>
        <Button onClick={() => setIsOpenCancelPremiumModal(false)}>No</Button>
        <Button onClick={() => {
          setIsOpenCancelPremiumModal(false);
          downgradeToFreeTier({
            variables: {
              recurringSubscriptionIdFree: id
            }
          }).then(() => {
            enqueueSnackbar('Saved', { variant: 'success' });
            refetchFunc();
          }).catch((error: any) => {
            enqueueSnackbar(error.message, { variant: 'error' });
          });
        }}>Yes</Button>
      </>}
    >
      <>
        <Typography>
          You can only track up to 2 wallets and 5 assets in the free plan.
          Are you sure you want to downgrade your Premium subscription
        </Typography>
      </>
    </Modal>
  </>;

  const selectPlan = () => {
    if (isPaid) {
      upgradeToPaidPlan({
        variables: {
          recurringSubscriptionIdPaid: id
        }
      }).then((res) => {
        refetchFunc();
        if (res && res.data?.activateRecurringSubscription?.checkoutUrl) {
          window.open(res.data?.activateRecurringSubscription?.checkoutUrl, '_blank').focus();
        }
      }).catch((error: any) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
    } else {
      setIsOpenCancelPremiumModal(true);
    }
  };

  return (
    <Panel fullHeight>
      {!isPaid && cancelPremiumModal()}
      <div className="plan">
        <Grid container sx={{ marginBottom: '24px' }}>
          <Grid item xs={12}>
            <div className="plan__title">
              <Typography variant="h6">{title}</Typography>
              {isActive && !dueDate && <PlanStatusChip textColor="#2BB596" bgColor="#D6F5EE" label="Current Plan"/>}
              {isActive && dueDate &&
                <PlanStatusChip textColor="#E29F50" bgColor="#FEF6CA" label={`Active until ${dateFormat(dueDate)}`}/>}
            </div>
            <span>{isPaid ? '$' + numberFormat(price, { precision: 2 }) + ' / mo.' : 'Free plan'}</span>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sx={{ marginBottom: '32px' }}>
            <ul className="list">
              {listItems && listItems.map((item: string, index: number) => <li key={index}
                                                                               className="list__item">{item}</li>)}
            </ul>
          </Grid>
          {
            !isActive && <div className="plan__button">
              <Button onClick={selectPlan} variant="contained">
                Select plan
              </Button>
            </div>
          }
        </Grid>
      </div>
    </Panel>
  );
};

export const Subscription: FC<any> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [plans, setPlans] = useState([]);
  const [myPlans, setMyPlans] = useState([]);
  const { data, refetch, loading } = useQuery(GET_SUBSCRIPTIONS, {
    fetchPolicy: 'network-only'
  });

  const {
    data: myPlansData,
    loading: loadingMyPlans,
    refetch: refetchMyPlans
  } = useQuery(GET_MY_SUBSCRIPTIONS, {
    fetchPolicy: 'network-only'
  });
  useEffect(() => {
    if (myPlansData?.myRecurringSubscriptions) {
      setMyPlans(myPlansData.myRecurringSubscriptions);
    }
  }, [myPlansData]);

  const {
    data: plansData,
    loading: loadingPlans,
    refetch: refetchPlans
  } = useQuery(GET_SUBSCRIPTIONS, {
    fetchPolicy: 'network-only'
  });
  useEffect(() => {
    if (plansData?.getRecurringSubscriptions?.list) {
      setPlans(plansData.getRecurringSubscriptions.list.map((p: RecurringSubscription) => {
        const myPlan = myPlans.find((mp: UserRecurringSubscription) => mp.subscriptionId === p.id && mp.isActive);
        return { ...p, dueDate: myPlan?.dueDate, isActive: myPlan?.isActive };
      }));
    }
  }, [plansData, myPlans]);

  const testData: any = {
    currentPlan: 'Free',
    subscriptionDate: Date.now(),
    paymentMethod: 'Mastercard'
  };

  const {
    refetch: getBillingPortalUrl
  } = useQuery(GET_BILLING_PORTAL_URL, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: true
  });

  const [loadingBillingPortalUrl, setLoadingBillingPortalUrl] = useState(false);
  const navigateToCustomerPortal = () => {
    setLoadingBillingPortalUrl(true);
    getBillingPortalUrl().then((response: any) => {
      const url = response.data.getBillingPortalUrl;

      setLoadingBillingPortalUrl(false);
      if (url) {
        window.open(url, '_blank');
      }
    }).catch(error => {
      setLoadingBillingPortalUrl(false);
      enqueueSnackbar(error.message, { variant: 'error' });
    });
  };

  const PlanSkeleton = () => <Panel>
    <Grid container>
      <Grid item xs={8} sm={4}>
        <Skeleton variant="text"/>
      </Grid>
      <Grid item xs={4} sm={8}/>
      <Grid item xs={6} sm={2}>
        <Skeleton variant="text"/>
      </Grid>
      <Grid item xs={6} sm={10}/>
      <Grid item xs={12}>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
      </Grid>
    </Grid>
  </Panel>;

  return (
    <>
      <Grid container spacing={{ md: 4, xs: 2 }} style={{ maxWidth: '1280px' }}>
        {
          plans.map((plan: RecurringSubscription, index: number) => <Grid item key={index} xs={12} md={6}>
            <PlanBlock {...plan} refetchFunc={refetchMyPlans}/>
          </Grid>)
        }
        {
          (loadingPlans || loadingMyPlans) &&
          <React.Fragment>
            <Grid item xs={6}>
              <PlanSkeleton/>
            </Grid>
            <Grid item xs={6}>
              <PlanSkeleton/>
            </Grid>
          </React.Fragment>
        }
        <Grid item xs={12}>
          <Panel>
            <Grid container spacing={{ md: 4, xs: 2 }} sx={{ marginBottom: '24px' }}>
              <Grid item xs={12}>
                <Typography variant="h6">Subscription Details</Typography>
              </Grid>
            </Grid>
            <Typography variant="body1">
              Manage your subscriptions anytime in our Customer Portal.
              There, you can view your current plans, make changes, or cancel your subscriptions as needed.
            </Typography>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'right', marginTop: '32px' }}>
              <Button
                variant="contained"
                endIcon={<ArrowForward/>}
                disabled={loadingBillingPortalUrl}
                onClick={navigateToCustomerPortal}
              >Customer Portal</Button>
            </Grid>

            {/*<Grid container>
              <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                <Grid container columnSpacing={1}>
                  <Grid item xs={6} md={3}>
                    <Typography variant="viewModeLabel" color="text.secondary" component="span">
                      Current plan
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography variant="viewModeValue">
                      {testData.currentPlan}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                <Grid container columnSpacing={1}>
                  <Grid item xs={6} md={3}>
                    <Typography variant="viewModeLabel" color="text.secondary" component="span">
                      Subscription Start date
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography variant="viewModeValue">
                      {dateFormat(testData.subscriptionDate)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                <Grid container columnSpacing={1}>
                  <Grid item xs={6} md={3}>
                    <Typography variant="viewModeLabel" color="text.secondary" component="span">
                      Payment method
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography variant="viewModeValue">
                      {testData.paymentMethod}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>*/}
          </Panel>
        </Grid>
      </Grid>
    </>
  );
};
