import React, { FC } from 'react';
import { Chip, ChipProps } from "@mui/material";
import { styled } from '@mui/material/styles';
import { ParticipationRequestStatusEnum } from "@store/generated-models";

type StatusColor = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
type LabelString = 'Approved' | 'Pending' | 'Declined' | 'Withdrawn' | 'Novation';

const statusTextColor: Record<ParticipationRequestStatusEnum, string> = {
  approved: '#2BB596',
  pending: '#E29F50',
  declined: '#E2507A',
  withdrawn: '#E2507A',
  novation: '#25b0cb'
}

const statusBgColor: Record<ParticipationRequestStatusEnum, string> = {
  approved: '#D6F5EE',
  pending: '#FEF6CA',
  declined: '#FAE2E9',
  withdrawn: '#FAE2E9',
  novation: '#DDF6FF'
}

const statusColorMap: Record<ParticipationRequestStatusEnum, StatusColor> = {
  approved: 'success',
  pending: 'warning',
  declined: 'error',
  withdrawn: 'error',
  novation: 'warning'
}

const statusLabelMap: Record<ParticipationRequestStatusEnum, LabelString> = {
  approved: 'Approved',
  pending: 'Pending',
  declined: 'Declined',
  withdrawn: 'Withdrawn',
  novation: 'Novation'
}

type OwnProps = {
  status: ParticipationRequestStatusEnum;
} & ChipProps;

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'status'
})<OwnProps>(({ theme, status }) => ({
  color: statusTextColor[status],
  backgroundColor: statusBgColor[status]
}));

export const RequestStatusChip: FC<OwnProps> = (
  {
    status,
    color,
    label,
    ...restProps
  }) => {

  const statusColor: StatusColor = color ? color : statusColorMap[status]
  let statusLabel;
  if (typeof label === 'undefined') {
    statusLabel = statusLabelMap[status];
  } else {
    statusLabel = label;
  }

  return (
    <StyledChip status={status} color={statusColor} label={statusLabel} {...restProps} />
  )
}
