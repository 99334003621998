import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  Drawer,
  IconButton,
  List
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import LogoImage from '@assets/images/main_logo.png';
import BreadImage from '@assets/images/roller-t.png';
import { SidebarMenu } from './SidebarMenu';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { BACKGROUND_MAIN, BORDER_LIGHT, TEXT_MAIN } from '@coreConstants/colors';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '64px',
  backgroundColor: BACKGROUND_MAIN
}));


export const Sidebar = (props: any) => {
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);
  const { open, handleDrawerClose } = props;
  const drawerVariant = isPhone ? 'temporary' : 'persistent';

  let drawerWidth = '240px';
  if (isTablet) {
    drawerWidth = '200px';
  }
  if (isPhone) {
    drawerWidth = '100%';
  }

  let drawerHeader = <div className='menu__logo' style={{ position: 'relative' }}>
    <img src={LogoImage} style={{ width: '136px', margin: '24px 24px 24px 0' }}/>
    <img src={BreadImage} style={{ width: '60px', position: 'absolute', right: '30px', bottom: '8px' }}/>
  </div>;
  
  if (isTablet) {
    drawerHeader = (
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}
                    sx={{ marginLeft: 'auto', marginRight: '24px' }}
        >
          <ChevronLeftIcon sx={{ color: TEXT_MAIN }}/>
        </IconButton>
      </DrawerHeader>
    );
  }
  if (isPhone) {
    drawerHeader = (
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}
                    sx={{ marginRight: 'auto', marginLeft: '24px' }}
        >
          <CloseIcon sx={{ color: TEXT_MAIN }}/>
        </IconButton>
        <img src={LogoImage} style={{ width: '104px', marginRight: '20px' }}/>
      </DrawerHeader>
    );
  }

  return (
    <Drawer
      sx={{
        width: open ? drawerWidth : 0,
        flexShrink: 0,
        backgroundColor: BACKGROUND_MAIN,
        '& .MuiDrawer-paper': {
          width: open ? drawerWidth : 0,
          boxSizing: 'border-box',
          backgroundColor: BACKGROUND_MAIN,
          borderRight: `1px solid ${BORDER_LIGHT}`
        }
      }}
      variant={drawerVariant}
      anchor="left"
      open={open}
      transitionDuration={0}
    >
      {drawerHeader}
      <List style={{ display: 'flex', flex: 1, padding: 0 }}>
        <SidebarMenu handleDrawerClose={handleDrawerClose} isPhone={isPhone}/>
      </List>
    </Drawer>
  );
};
