import React, { useContext } from 'react';
import LogoImage from '@assets/images/main_logo_dark.png';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { concatClassNames } from '@coreHelpers/utils';
import './Auth.scss';

export const Auth: React.FC<any> = (props) => {
  const { authFooter } = props;
  const { isMobile } = useContext(ScreenContext);

  const classNames = concatClassNames({
    'auth--is-with-footer': typeof authFooter !== 'undefined',
    'auth--small': isMobile
  });

  return (
    <div className={`auth ${classNames}`}>
      <div className="auth__block">
        <div className="auth__logo">
          <img src={LogoImage}/>
        </div>
        <div className="auth__content">
          {props.children}
        </div>
        <div className="auth__footer">
          {props.authFooter}
        </div>
      </div>
    </div>
  );
};
