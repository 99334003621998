import { gql } from "@apollo/client";

export const CREATE_PROJECT = gql`
    mutation AddProject(
      $project: ProjectInput!,
      $avatar: Upload,
      $rounds: ProjectRoundMutationListInput,
      $socials: SocialDataMutationListInput,
      $crop: Crop,
      $spacing: Spacing
    ) {
      addProject(project: $project, avatar: $avatar, rounds: $rounds, socials: $socials, crop: $crop, spacing: $spacing) {
        title
        description
        whitepaperLink
        status
        tokenGenerationDate
        contributionWallet
        rounds {
          title
          tokenCount
          price
        }
        avatar
      }
  }
`;

export const UPDATE_PROJECT = gql`
    mutation UpdateProject(
      $project: ProjectMutationInput,
      $avatar: Upload,
      $rounds: ProjectRoundMutationListInput,
      $socials: SocialDataMutationListInput,
      $crop: Crop,
      $spacing: Spacing
    ) {
      updateProject(project: $project, avatar: $avatar, rounds: $rounds, socials: $socials, crop: $crop, spacing: $spacing) {
        title
        projectId
        description
        whitepaperLink
        status
        tokenGenerationDate
        contributionWallet
        rounds {
          title
          tokenCount
          price
        }
        avatar
      }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($projectId: ID!) {
    deleteProject(projectId: $projectId) {
      projectId
    }
  }
`;
