import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { MouseEvent } from 'react';

type ExpandMoreProps = {
  expand: boolean;
  onClick: (e: MouseEvent) => void;
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, onClick } = props;

  return (
    <>
      <div className="expand" onClick={onClick}>
        <Typography component="span" sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '22px' }}>Learn
          more</Typography>
        {expand ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
      </div>
    </>
  );
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));
