import { ReactComponent as SignOutSvg } from '@assets/icons/sign_out.svg';
import { ReactComponent as CheckboxCheckedSvg } from '@assets/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxUncheckedSvg } from '@assets/icons/checkbox-unchecked.svg';
import { ReactComponent as Cards } from '@assets/icons/cards.svg';
import { ReactComponent as List } from '@assets/icons/list.svg';
import { ReactComponent as Info } from '@assets/icons/info.svg';
import { ReactComponent as Users } from '@assets/icons/users.svg';
import { ReactComponent as UserGroup } from '@assets/icons/user-group.svg';
import { ReactComponent as Check } from '@assets/icons/check.svg';
import { ReactComponent as Planet } from '@assets/icons/planet.svg';
import { ReactComponent as InvestmentPortfolio } from '@assets/icons/investment-portfolio.svg';

import React from 'react';
import './CustomIcon.scss';

const iconMapping: Record<string, any> = {
  'sign_out': <SignOutSvg/>,
  'checkboxChecked': <CheckboxCheckedSvg/>,
  'checkboxUnchecked': <CheckboxUncheckedSvg/>,
  'cards': <Cards/>,
  'list': <List/>,
  'info': <Info/>,
  'users': <Users/>,
  'user-group': <UserGroup/>,
  'check': <Check/>,
  'planet': <Planet/>,
  'investment-portfolio': <InvestmentPortfolio/>
};

const Icon = (props: any) => {
  const { children, color, stroke } = props;

  return (
    <div className={`icon ${color ? 'icon--' + color : ''} ${stroke ? 'icon--stroke-' + stroke : ''}`}>
      {children}
    </div>
  );
};

export const getIcon = (iconName: string, color: string = '', stroke: string = '') => {
  if (typeof iconMapping[iconName] !== 'undefined') {
    return <Icon color={color} stroke={stroke}>
      {iconMapping[iconName]}
    </Icon>;
  } else {
    return iconMapping.link;
  }
};
