import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Panel } from '@coreComponents/base/Panel/Panel';
import { RichText } from '@coreComponents/base/RichText/RichText';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { GET_DISCLAIMER } from '@store/queries/funds';
import { UPDATE_DISCLAIMER } from '@store/mutations/funds';

export const Disclaimer: FC<any> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const screenContext = useContext(ScreenContext);
  const { isPhone, isTablet, isMobile } = screenContext;
  const { data: disclaimerData } = useQuery(GET_DISCLAIMER, {
    fetchPolicy: 'network-only'
  });
  const [disclaimer, setDisclaimer] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (disclaimerData) {
      setDisclaimer(disclaimerData.getRollingFundsDisclaimer);
    }
  }, [disclaimerData]);

  const [updateDisclaimer, { data, loading, error: updateError }] = useMutation(UPDATE_DISCLAIMER);

  const handleSave = () => {
    const isError = disclaimer.replaceAll(/<\/?p>|<br>/ig, '').trim() === '';

    setError(isError);

    if (!isError) {
      updateDisclaimer({
        variables: {
          disclaimer
        }
      }).then(() => enqueueSnackbar('Disclaimer is successfully saved', { variant: 'success' }));
    }
  };

  return (
    <Panel>
      <Typography sx={{ fontSize: '20px', fontWeight: 500, marginBottom: '12px' }}>
        Disclaimer
      </Typography>
      <Typography sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '32px' }}>
        In this section you can write a disclaimer that all users will see when applying to the rolling funds.
      </Typography>

      <div className="form">
        <div className="form__body" style={{ marginBottom: isPhone ? '12px' : '48px' }}>
          <RichText text={disclaimer} onChange={setDisclaimer} error={error}/>
        </div>
        <div className="form__buttons">
          <div className="button-group"/>
          <div className="button-group">
            <Button variant="contained" onClick={handleSave}>Save</Button>
          </div>
        </div>

      </div>
    </Panel>
  );
};
