import {
  FormControl, FormControlLabel,
  Grid, InputAdornment,
  InputLabel,
  MenuItem,
  Select, Switch,
  TextField,
  Typography
} from '@mui/material';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_FUND_PARTICIPATION_REQUEST,
  UPDATE_FUND_PARTICIPATION_REQUEST
} from '@store/mutations/participationRequest';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { GET_FUND_BY_ID } from '@store/queries/funds';
import {
  AssetsEnum,
  IncomeEnum,
  OccupationEnum,
  RollingFundPeriodTypeEnum,
  SourceOfFundsEnum
} from '@store/generated-models';
import { checkStringNonZero, dateFormat, numberFormat } from '@coreHelpers/utils';
import { PERIOD_TYPES } from '@businessConstants/enums';
import { AuthContext } from '@coreProviders/AuthProvider';
import { useForm } from '@coreHooks/useForm';
import { useKYBNotification } from '@businessHooks/useKYBNotification';
import { NumberInput } from '@coreComponents/base/NumberInput/NumberInput';
import { getIcon } from '@coreComponents/base/CustomIcon/CustomIcon';
import { MinPeriodInput } from '@businessComponents/MinPeriodInput/MinPeriodInput';

export const SubscriptionTerms = forwardRef((props: any, ref) => {
  const { setIsStepValid, setIsLegalPerson, fundId, request } = props;
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  useEffect(() => {
    if (setIsStepValid) {
      setIsStepValid(true);
    }
  }, []);

  const { id, requestId } = useParams();
  const [sendParticipationRequest, {
    data: createdRequestData,
    loading,
    error
  }] = useMutation(CREATE_FUND_PARTICIPATION_REQUEST);
  useEffect(() => {

  }, [createdRequestData]);

  const [updateParticipationRequest, {
    loading: updateLoading,
    error: updateError
  }] = useMutation(UPDATE_FUND_PARTICIPATION_REQUEST);
  if (loading || updateLoading) {
  }
  if (error || updateError) {
  }

  const [fund, setFund] = useState<any>({});
  const { data: fundData, loading: fundLoading } = useQuery(GET_FUND_BY_ID, {
    variables: {
      rollingFundId: id || fundId
    },
    fetchPolicy: 'network-only',
    skip: !id && !fundId
  });
  useEffect(() => {
    if (fundData) {
      setFund(fundData.getRollingFundById);
    }
  }, [fundData]);
  useEffect(() => {
    if (fund && !request) {
      setData({
        ...data,
        investmentAmount: fund.minAmount,
        period: fund.minPeriod,
        periodType: fund.minPeriodType
      });
    }
  }, [fund]);

  const periods: any = {
    [RollingFundPeriodTypeEnum.Month]: {
      coefficient: {
        [RollingFundPeriodTypeEnum.Month]: 1,
        [RollingFundPeriodTypeEnum.Quarter]: 3,
        [RollingFundPeriodTypeEnum.Semester]: 6,
        [RollingFundPeriodTypeEnum.Year]: 12
      }
    },
    [RollingFundPeriodTypeEnum.Quarter]: {
      coefficient: {
        [RollingFundPeriodTypeEnum.Month]: 1 / 3,
        [RollingFundPeriodTypeEnum.Quarter]: 1,
        [RollingFundPeriodTypeEnum.Semester]: 2,
        [RollingFundPeriodTypeEnum.Year]: 4
      }
    },
    [RollingFundPeriodTypeEnum.Semester]: {
      coefficient: {
        [RollingFundPeriodTypeEnum.Month]: 1 / 6,
        [RollingFundPeriodTypeEnum.Quarter]: 1 / 2,
        [RollingFundPeriodTypeEnum.Semester]: 1,
        [RollingFundPeriodTypeEnum.Year]: 2
      }
    },
    [RollingFundPeriodTypeEnum.Year]: {
      coefficient: {
        [RollingFundPeriodTypeEnum.Month]: 1 / 12,
        [RollingFundPeriodTypeEnum.Quarter]: 1 / 4,
        [RollingFundPeriodTypeEnum.Semester]: 1 / 2,
        [RollingFundPeriodTypeEnum.Year]: 1
      }
    }
  };

  const validationConfig = {
    investmentAmount: {
      custom: {
        isValid: (value: any) => {
          return value >= fund.minAmount;
        },
        message: `Minimum is $${numberFormat(fund.minAmount)}`
      }
    },
    period: {
      custom: {
        isValid: () => {
          return data.period >= fund.minPeriod * periods[data.periodType].coefficient[fund.minPeriodType];
        },
        message: `Minimum is $${numberFormat(fund.minPeriod)} ${PERIOD_TYPES[fund.minPeriodType as RollingFundPeriodTypeEnum]}`
      }
    },
    sourceOfFunds: {
      custom: {
        isValid: checkStringNonZero,
        message: 'The value must not be empty'
      }
    },
    sourceOfFundsOther: {
      custom: {
        // @ts-ignore
        isValid: (value: any) => {
          // @ts-ignore
          return data.sourceOfFunds !== SourceOfFundsEnum.Other || value !== '';
        },
        message: 'The value must not be empty'
      }
    },
    occupation: {
      custom: {
        isValid: checkStringNonZero,
        message: 'The value must not be empty'
      }
    },
    income: {
      custom: {
        isValid: checkStringNonZero,
        message: 'The value must not be empty'
      }
    },
    assets: {
      custom: {
        isValid: checkStringNonZero,
        message: 'The value must not be empty'
      }
    },
    motivation: {
      custom: {
        isValid: checkStringNonZero,
        message: 'The value must not be empty'
      }
    }
  };

  type RequestForm = {
    investmentAmount: number;
    investmentCurrency: string;
    period: number;
    periodType: RollingFundPeriodTypeEnum;
    sourceOfFunds: string;
    sourceOfFundsOther: string;
    occupation: string;
    income: string;
    assets: string;
    motivation: string;
    isLegalPerson: boolean;
  }

  const sourceOfFundsOptions = [
    { label: 'Salary', value: SourceOfFundsEnum.Salary },
    { label: 'Pension', value: SourceOfFundsEnum.Pension },
    { label: 'Savings', value: SourceOfFundsEnum.Savings },
    { label: 'Sale of shares / dividends', value: SourceOfFundsEnum.SharesSale },
    { label: 'Sale of financial instruments', value: SourceOfFundsEnum.FinancialInstrumentsSale },
    { label: 'Sale of real estate', value: SourceOfFundsEnum.RealEstateSale },
    { label: 'Inheritance', value: SourceOfFundsEnum.Inheritance },
    { label: 'Other', value: SourceOfFundsEnum.Other }
  ];

  const occupationOptions = [
    { label: 'Employed', value: OccupationEnum.Employed },
    { label: 'Self-employed', value: OccupationEnum.SelfEmployed },
    { label: 'Retired', value: OccupationEnum.Retired },
    { label: 'Student', value: OccupationEnum.Student },
    { label: 'Unemployed', value: OccupationEnum.Unemployed }
  ];

  const incomeOptions = [
    { label: '0 to 30k', value: IncomeEnum.Low },
    { label: '30k to 100k', value: IncomeEnum.Medium },
    { label: '> 100k', value: IncomeEnum.High }
  ];

  const assetsOptions = [
    { label: '0 to 30k', value: AssetsEnum.Low },
    { label: '30k to 100k', value: AssetsEnum.Medium },
    { label: '> 100k', value: AssetsEnum.High }
  ];

  const initialValues: RequestForm = {
    investmentAmount: 0,
    investmentCurrency: 'USDT',
    period: 0,
    periodType: RollingFundPeriodTypeEnum.Month,
    sourceOfFunds: '',
    sourceOfFundsOther: '',
    occupation: '',
    income: '',
    assets: '',
    motivation: '',
    isLegalPerson: false
  };

  const onSubmit = () => true;

  const {
    handleTextChange, handleValueChange, handleSubmit, errors, data, setData, handleCheckboxChange
  } = useForm<RequestForm>({ validationConfig, initialValues, onSubmit: onSubmit });

  const authContext = useContext(AuthContext);

  useEffect(() => {
    let savedRequestData: typeof data = { ...data };

    if (request) {
      for (const formField in savedRequestData) {
        if (!Array.isArray(request[formField])) {
          // @ts-ignore
          savedRequestData[formField] = request[formField];
        }
      }
      setData(savedRequestData);
    }
  }, []);

  function handleSendParticipationRequest() {
    try {
      if (id || requestId) { /*TODO: maybe this line is useless*/
        if (request) {
          const participationRequest = {
            participationRequestId: requestId,
            rollingFundId: id,
            investmentCurrency: data.investmentCurrency,
            investmentAmount: parseFloat(data.investmentAmount),
            period: data.period,
            periodType: data.periodType,
            isLegalPerson: data.isLegalPerson,
            sourceOfFunds: data.sourceOfFunds,
            sourceOfFundsOther: data.sourceOfFundsOther,
            occupation: data.occupation,
            income: data.income,
            assets: data.assets,
            motivation: data.motivation
          };
          return updateParticipationRequest({
            variables: {
              participationRequest
            }
          });
        } else {
          const participationRequest = {
            userId: authContext.user.userId,
            rollingFundId: id,
            investmentCurrency: data.investmentCurrency,
            investmentAmount: parseFloat(data.investmentAmount),
            period: data.period,
            periodType: data.periodType,
            isLegalPerson: data.isLegalPerson,
            sourceOfFunds: data.sourceOfFunds,
            sourceOfFundsOther: data.sourceOfFundsOther,
            occupation: data.occupation,
            income: data.income,
            assets: data.assets,
            motivation: data.motivation
          };
          return sendParticipationRequest({
            variables: {
              participationRequest
            }
          });
        }
      }
    } catch (e) {
    }
  }

  useImperativeHandle(ref, () => ({
    handleSendParticipationRequest() {
      const isReadyForSubmit = handleSubmit();
      if (isReadyForSubmit) {
        return handleSendParticipationRequest();
      } else {
        return Promise.reject();
      }
    }
  }));

  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    let newEndDate = new Date(fund.startDate);

    switch (data.periodType) {
      case RollingFundPeriodTypeEnum.Month:
        newEndDate.setMonth(newEndDate.getMonth() + data.period);
        break;
      case RollingFundPeriodTypeEnum.Quarter:
        newEndDate.setMonth(newEndDate.getMonth() + data.period * 3);
        break;
      case RollingFundPeriodTypeEnum.Semester:
        newEndDate.setMonth(newEndDate.getMonth() + data.period * 6);
        break;
      case RollingFundPeriodTypeEnum.Year:
        newEndDate.setFullYear(newEndDate.getFullYear() + data.period);
        break;
    }

    setEndDate(newEndDate);
  }, [fund.startDate, data.period, data.periodType]);

  useKYBNotification(data.isLegalPerson);

  return (
    <Grid container spacing={{ xs: 3, sm: 5 }}>
      <Grid item xs={12} sm={6} md={8}>
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={{ xs: 2, sm: 4 }}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Subscription Amount & Currency</Typography>
                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#BBBDC0', marginBottom: '8px' }}>
                  {`Minimum is $${numberFormat(fund.minAmount)}`}
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <NumberInput name="investmentAmount" variant="outlined" fullWidth
                                 required error={errors.investmentAmount && errors.investmentAmount.length > 0}
                                 InputProps={{
                                   endAdornment: <InputAdornment
                                     position="end">{getIcon('dollar')}</InputAdornment>
                                 }}
                                 onChange={handleValueChange}
                                 value={data.investmentAmount || 0}
                                 precision={0}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Select fullWidth
                              name="investmentCurrency"
                              value={data.investmentCurrency}
                              onChange={handleTextChange}
                      >
                        <MenuItem value="USDT">USDT</MenuItem>
                        <MenuItem value="USDC">USDC</MenuItem>
                        <MenuItem value="DAI">DAI</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Subscription Period</Typography>
                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#BBBDC0', marginBottom: '8px' }}>

                  Minimum is {fund.minPeriod} {PERIOD_TYPES[fund.minPeriodType as RollingFundPeriodTypeEnum]}
                </Typography>
                <MinPeriodInput errors={errors} fieldName="period"
                                value={data.period} typeValue={data.periodType}
                                onValueChange={handleValueChange} onTypeChange={handleTextChange}
                                isWithLabel={false}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Apply as legal person</Typography>
                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#BBBDC0', marginBottom: '8px' }}>
                  KYB verification required
                </Typography>
                <FormControlLabel
                  control={
                    <Switch name="isLegalPerson" onChange={(event) => {
                      handleCheckboxChange(event);

                      if (setIsLegalPerson) {
                        setIsLegalPerson(event.target.checked);
                      }
                    }} checked={!!data.isLegalPerson}/>
                  }
                  label="Yes"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Specify the source of funds</Typography>
                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#BBBDC0', marginBottom: '8px' }}>
                  Choose one option in each question
                </Typography>
                <FormControl fullWidth error={errors.sourceOfFunds && errors.sourceOfFunds.length > 0}>
                  <InputLabel>Source of funds</InputLabel>
                  <Select fullWidth
                          label="Source of funds"
                          name="sourceOfFunds"
                          value={data.sourceOfFunds}
                          onChange={handleTextChange}
                  >
                    {
                      sourceOfFundsOptions.map((item, index) => <MenuItem key={index}
                                                                          value={item.value}>{item.label}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Grid>
              {
                data.sourceOfFunds === SourceOfFundsEnum.Other && <Grid item xs={12}>
                  <TextField label="Other" name="sourceOfFundsOther" required variant="outlined" fullWidth
                             onChange={handleTextChange}
                             error={errors.sourceOfFundsOther && errors.sourceOfFundsOther.length > 0}
                             value={data.sourceOfFundsOther}/>
                </Grid>
              }
              <Grid item xs={12}>
                <FormControl fullWidth error={errors.occupation && errors.occupation.length > 0}>
                  <InputLabel>Occupation/Business</InputLabel>
                  <Select fullWidth
                          label="Occupation/Business"
                          name="occupation"
                          value={data.occupation}
                          onChange={handleTextChange}
                  >
                    {
                      occupationOptions.map((item, index) => <MenuItem key={index}
                                                                       value={item.value}>{item.label}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth error={errors.income && errors.income.length > 0}>
                  <InputLabel>Income per year</InputLabel>
                  <Select fullWidth
                          label="Income per year"
                          name="income"
                          value={data.income}
                          onChange={handleTextChange}
                  >
                    {
                      incomeOptions.map((item, index) => <MenuItem key={index}
                                                                   value={item.value}>{item.label}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth error={errors.assets && errors.assets.length > 0}>
                  <InputLabel>Assets value</InputLabel>
                  <Select fullWidth
                          label="Assets value"
                          name="assets"
                          value={data.assets}
                          onChange={handleTextChange}
                  >
                    {
                      assetsOptions.map((item, index) => <MenuItem key={index}
                                                                   value={item.value}>{item.label}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Application Message</Typography>
            <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#BBBDC0', marginBottom: '8px' }}>
              Briefly introduce yourself and share why you are interested in this fund
            </Typography>
            <TextField name="motivation" variant="outlined" multiline rows={5} fullWidth
                       placeholder="E.g. I’m a founder in the consumer/marketplaces space who made an exit three years ago. I heard about the fund through Jenni Kays."
                       error={errors.motivation && errors.motivation.length > 0}
                       value={data.motivation}
                       onChange={handleTextChange}
            />
          </Grid>
        </Grid>
      </Grid>

      {
        !isPhone && <Grid item xs={0}>
          <div style={{ height: '100%', borderRight: '1px solid #E1E2E3' }}></div>
        </Grid>
      }

      <Grid item xs={12} sm={5} md={3}>
        <Grid container spacing={3}>
          {
            isPhone && <Grid item xs={12}>
              <div style={{ padding: '0 24px', borderBottom: '1px solid #E1E2E3' }}></div>
            </Grid>
          }
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '14px', fontWeight: 500, marginBottom: '24px' }}>
              Subscription Preview
            </Typography>
            <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#BBBDC0' }}>
              Name of the fund
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '12px' }}>
              {fund.title}
            </Typography>
            <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#BBBDC0' }}>
              Subscription
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '12px' }}>
              <span style={{ paddingRight: '8px', marginRight: '8px', borderRight: '1px solid #E1E2E3' }}>
                {data.period} {PERIOD_TYPES[data.periodType as RollingFundPeriodTypeEnum]}: {numberFormat(data.investmentAmount)} {data.investmentCurrency}
              </span>
              <span>
                Total: {numberFormat(data.investmentAmount * data.period)} {data.investmentCurrency}
              </span>
            </Typography>
            <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#BBBDC0' }}>
              Management Fee
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '12px' }}>
              {fund.managementFee}%
            </Typography>
            <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#BBBDC0' }}>
              Subscription Start Date
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 400, marginBottom: '12px' }}>
              {dateFormat(fund.startDate)}
            </Typography>
            <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#BBBDC0' }}>
              Subscription End Date
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
              {dateFormat(endDate)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div style={{ padding: '0 24px', borderBottom: '1px solid #E1E2E3' }}></div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ fontSize: '12px', fontWeight: 400, color: '#BBBDC0' }}>
              Due when accepted
            </span>
              <span style={{ fontSize: '14px', fontWeight: 400, marginBottom: '12px', color: '#4A4DF0' }}>
              {numberFormat(data.investmentAmount)} {data.investmentCurrency}
            </span>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
