import React, { FC, useState, useEffect, useContext } from 'react';
import { Box, Grid, Tab, Tabs, useMediaQuery } from '@mui/material';
import { FundCard } from './FundCard';
import Masonry from 'react-masonry-css';
import { useQuery } from '@apollo/client';
import { ReactComponent as EmptyStateImage } from '@assets/images/empty_state_masonry.svg';
import { AuthContext } from '@coreProviders/AuthProvider';
import { GET_FUNDS } from '@store/queries/funds';
import { RollingFundApprovalStatusEnum, RollingFundStatusEnum } from '@store/generated-models';
import { KycRestrictionPanel } from '@businessComponents/KYCRestriction/KycRestrictionPanel';
import { TabPanel } from '@coreComponents/base/TabPanel/TabPanel';
import { EmptyState } from '@coreComponents/base/EmptyState/EmptyState';
import './FundCards.scss';


export const FundsCards: FC<any> = (props) => {
  const { toolbarItem } = props;
  const authContext = useContext(AuthContext);
  const { data, refetch, loading } = useQuery(GET_FUNDS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const [funds, setFunds] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (loading) {
    } else {
      const { getRollingFunds } = data;
      const { list } = getRollingFunds;
      setFunds(list);
    }
  }, [data]);

  const handleActiveTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    refetch();
  };

  const activeFunds: any[] = !loading
    ? funds.filter(item => item.status === RollingFundStatusEnum.Active
      && item.approvalStatus === RollingFundApprovalStatusEnum.Approved)
    : new Array(6).fill(null).map((_, i) => {
    });
  const inactiveFunds: any[] = !loading
    ? funds.filter(item => item.status === RollingFundStatusEnum.Inactive
      && item.approvalStatus === RollingFundApprovalStatusEnum.Approved)
    : new Array(6).fill(null).map((_, i) => {
    });

  const activeProjectsNum = activeFunds.length;
  const [breakpointColumnsObj, setBreakpointColumnsObj] = useState({});
  const [inactiveFundsBreakpoints, setInactiveFundsBreakpoints] = useState({});

  useEffect(() => {
    setBreakpointColumnsObj({
      default: activeProjectsNum >= 5 ? 5 : activeProjectsNum,
      2300: activeProjectsNum >= 4 ? 4 : activeProjectsNum,
      1900: activeProjectsNum >= 3 ? 3 : activeProjectsNum,
      1000: 2,
      644: 1
    });
  }, [activeProjectsNum]);

  const inactiveFundsNum = inactiveFunds.length;

  useEffect(() => {
    setInactiveFundsBreakpoints({
      default: inactiveFundsNum >= 5 ? 5 : inactiveFundsNum,
      2300: inactiveFundsNum >= 4 ? 4 : inactiveFundsNum,
      1900: inactiveFundsNum >= 3 ? 3 : inactiveFundsNum,
      1000: 2,
      644: 1
    });
  }, [inactiveFundsNum]);

  const [isTablet, setIsTabled] = useState(false);
  const tablet = useMediaQuery('(max-width:1420px)');
  useEffect(() => {
    setIsTabled(tablet);
  }, [tablet]);

  const isFullyVerified = authContext.isAdmin || authContext.isVerified
    && (authContext.user?.kybStatus === 'approved' || !authContext.isFundOwner);

  return (
    <>
      {!isFullyVerified && <KycRestrictionPanel
        description="To enable participation in the projects and funds, we need to verify your identity."/>}
      {isFullyVerified && <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flex: 1 }}>
            <Tabs
              value={activeTab}
              onChange={handleActiveTabChange}
              aria-label="secondary tabs example"
            >
              <Tab value={0} label="Active funds" sx={{ textTransform: 'none' }}/>
              <Tab value={1} label="Inactive funds" sx={{ textTransform: 'none' }}/>
            </Tabs>
            <div style={{ marginLeft: 'auto' }}>
              {toolbarItem}
            </div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={activeTab} index={0}>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {
                activeFunds.map((fund, index) => {
                  return (
                    <FundCard key={fund?.rollingFundId || index} fund={fund} isLoading={loading}/>
                  );
                })
              }
            </Masonry>
            {
              !loading && activeFunds.length === 0 && <div style={{ width: '100vw', maxWidth: '1280px' }}>
                {
                  <EmptyState Image={EmptyStateImage} imgMargin="32px"
                              title="Empty Page" titleMargin="16px"
                              description="There is no content on this page yet" descriptionColor="black"
                  />
                }
              </div>
            }
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <Masonry
              breakpointCols={inactiveFundsBreakpoints}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {
                inactiveFunds.map((fund, index) => {
                  return (
                    <FundCard key={fund?.rollingFundId || index} fund={fund} isLoading={loading}/>
                  );
                })
              }
            </Masonry>
            {
              !loading && inactiveFunds.length === 0 && <div style={{ width: '100vw', maxWidth: '1280px' }}>
                {
                  <EmptyState Image={EmptyStateImage} imgMargin="32px"
                              title="Empty Page" titleMargin="16px"
                              description="There is no content on this page yet" descriptionColor="black"
                  />
                }
              </div>
            }
          </TabPanel>
        </Grid>
      </Grid>}
    </>
  );
};
