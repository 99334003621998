import React from 'react';
import { ProtectedPage } from '@coreComponents/system/ProtectedPage';
import { Route } from 'react-router-dom';
import { PrivateSection } from '@coreComponents/base/PrivateSection/PrivateSection';
import { Auth } from '@businessComponents/Authentication/Auth';
import { SignIn } from '@businessComponents/Authentication/SignIn';
import { SignUp } from '@businessComponents/Authentication/SignUp';
import { ForgotPassword } from '@businessComponents/Authentication/ForgotPassword';
import { SetPassword } from '@businessComponents/Authentication/SetPassword';
import { ConfirmEmail } from '@businessComponents/Authentication/ConfirmEmail';
import { ConfirmDevice } from '@businessComponents/Authentication/ConfirmDevice';
import { AlreadyHaveAnAccount, BackToSignIn, DontHaveAnAccount } from '@businessComponents/Authentication/AuthFooter';

/*TODO: change token prop to useParam() hook params.token*/

export const appRoutes = [
  <Route path="/" key="auth">
    <Route index element={<ProtectedPage pageComponent={<PrivateSection/>}/>}/>
    <Route path="login" element={<Auth authFooter={DontHaveAnAccount}><SignIn/></Auth>}/>,
    <Route path="signup" element={<Auth authFooter={AlreadyHaveAnAccount}><SignUp withAccountTypeSelection/></Auth>}/>,
    <Route path="forgot-password" element={<Auth authFooter={BackToSignIn}><ForgotPassword/></Auth>}/>,
    <Route path="new-password/:token" element={<Auth authFooter={BackToSignIn}><SetPassword/></Auth>}/>,
    <Route path="confirm-email/:token" element={<Auth><ConfirmEmail/></Auth>}/>,
    <Route path="confirm-device/:token" element={<Auth><ConfirmDevice/></Auth>}/>,
  </Route>,
  <Route path="private/*" key="private" element={<ProtectedPage pageComponent={<PrivateSection/>}/>}/>
];
