import React, { useState } from 'react';
import { Button, Radio, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { AccountType } from '@store/generated-models';
import { getIcon } from '@coreComponents/base/CustomIcon/CustomIcon';
import './AccountType.scss';

export const AccountTypeSelection = (props: any) => {
  const { setActiveStep, accountType, setAccountType } = props;

  return <>
    <Typography component="span" sx={{ fontWeight: 600, fontSize: '20px', marginBottom: '32px' }}>
      Please tell us who you are
    </Typography>
    <div className="accounts">
      <div className={`account ${accountType === AccountType.User ? 'account--active' : ''}`}
           onClick={() => setAccountType(AccountType.User)}
      >
        <div className="account__icon">
          {getIcon('user-group', 'background-dark')}
        </div>
        <div className="account__description">
          <div className="account__type">
            <Typography component="span" sx={{ fontWeight: 500, fontSize: '14px', marginBottom: '2px' }}>
              I'm an Investor
            </Typography>
            <Radio checked={accountType === AccountType.User} sx={{ padding: 0 }}/>
          </div>
          <Typography component="span" sx={{ fontWeight: 400, fontSize: '12px' }}>
            I want to invest in projects or roll funds
          </Typography>
        </div>
      </div>
      <div className={`account ${accountType === AccountType.FundOwner ? 'account--active' : ''}`}
           onClick={() => setAccountType(AccountType.FundOwner)}
      >
        <div className="account__icon">
          {getIcon('investment-portfolio', 'background-dark')}
        </div>
        <div className="account__description">
          <div className="account__type">
            <Typography component="span" sx={{ fontWeight: 500, fontSize: '14px', marginBottom: '2px' }}>
              I'm a Fund Manager
            </Typography>
            <Radio checked={accountType === AccountType.FundOwner} sx={{ padding: 0 }}/>
          </div>
          <Typography component="span" sx={{ fontWeight: 400, fontSize: '12px' }}>
            I want to list a fund on the platform
          </Typography>
        </div>
      </div>
    </div>

    <Button
      variant="contained"
      endIcon={<ArrowForward/>}
      onClick={(e: any) => {
        setActiveStep(1);
      }}
      fullWidth
    >
      Continue
    </Button>
  </>;
};
