export const LOGIN_RESULT = `
	authToken
	authTokenAction
	authTokenActionParam
	user {
		userId
      email
      firstName
      lastName
      fullName
      emailConfirmed
      is2faEnabled
      kycStatus
      kybStatus
      mode
      status
      created
      updated
      deleted
      roles {
        code
      }
	}
`;
