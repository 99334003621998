import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { Panel } from '@coreComponents/base/Panel/Panel';
import { ScreenContext } from '@coreProviders/ScreenProvider';
import { Text, TEXT_COLOR, TEXT_VARIANT } from '@coreComponents/base/Text/Text';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './BrowseFunds.scss';

export const BrowseFunds = () => {
  const { enqueueSnackbar } = useSnackbar();
  const screenContext = useContext(ScreenContext);
  const navigate = useNavigate();
  const { isPhone, isTablet, isMobile } = screenContext;

  const goToFunds = () => {
    navigate('/private/funds');
  };

  return <Panel>
    <div className="funds-explore">
      <div className="funds-explore__title">
        <Text variant={TEXT_VARIANT.h2}>Rolling Funds</Text>
      </div>
      <div className="funds-explore__subtitle">
        <Text color={TEXT_COLOR.secondary} variant={TEXT_VARIANT['small-regular']}>
          Explore and apply to rolling funds that are accessible on Roller
        </Text>
      </div>
      <div className="funds-explore__buttons">
        <Button variant="contained" color="secondary" onClick={goToFunds}>
          <Text color={TEXT_COLOR.light}>Browse</Text>
        </Button>
      </div>
    </div>
  </Panel>;
};
